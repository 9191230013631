import { GlossaryItemRowSkeleton } from './L10nGlossaryItemRow'

export const L10nGlossaryLocaleSkeleton = () => {
  return (
    <div className="relative">
      <div className="absolute bottom-0 left-0 z-10 size-full bg-gradient-to-b from-transparent to-fg-primary" />
      <ul>
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
        <GlossaryItemRowSkeleton />
      </ul>
    </div>
  )
}
