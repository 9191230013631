import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Bell: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05023 3.05025C8.36299 1.7375 10.1435 1 12 1C13.8565 1 15.637 1.7375 16.9497 3.05025C18.2625 4.36301 19 6.14349 19 8C19 10.9127 19.732 12.8439 20.4994 14.0771L20.5111 14.096C20.8685 14.6703 21.151 15.1243 21.3414 15.4547C21.4368 15.6202 21.5237 15.7797 21.5881 15.9215C21.6202 15.9922 21.6562 16.079 21.6843 16.1733C21.7076 16.2515 21.752 16.4187 21.7353 16.6223C21.7241 16.7591 21.6962 16.9928 21.5621 17.2343C21.4279 17.4758 21.2442 17.623 21.134 17.7047C20.8837 17.8904 20.5963 17.9329 20.5003 17.947L20.4959 17.9477C20.3485 17.9695 20.1812 17.9804 20.0125 17.9869C19.6772 18 19.2131 18 18.6358 18H5.36413C4.78691 18 4.32276 18 3.98751 17.9869C3.81875 17.9804 3.65148 17.9695 3.50407 17.9477L3.49963 17.947C3.4037 17.9329 3.11628 17.8904 2.86599 17.7047C2.75575 17.623 2.57202 17.4758 2.43787 17.2343C2.30372 16.9928 2.27582 16.7591 2.26463 16.6223C2.24799 16.4187 2.29239 16.2515 2.31569 16.1733C2.34379 16.079 2.37973 15.9922 2.41186 15.9215C2.47622 15.7797 2.56315 15.6202 2.65857 15.4547C2.84899 15.1243 3.13146 14.6703 3.48876 14.0961L3.5006 14.0771C4.26798 12.8439 4.99998 10.9127 4.99998 8C4.99998 6.14348 5.73748 4.36301 7.05023 3.05025ZM12 3C10.6739 3 9.40213 3.52678 8.46445 4.46447C7.52677 5.40215 6.99998 6.67392 6.99998 8C6.99998 11.2677 6.17293 13.5681 5.19869 15.1337C4.98585 15.4758 4.80821 15.7614 4.66306 15.9988C4.86964 16 5.10996 16 5.38884 16H18.6111C18.89 16 19.1303 16 19.3369 15.9988C19.1918 15.7614 19.0141 15.4758 18.8013 15.1337C17.827 13.5681 17 11.2677 17 8C17 6.67392 16.4732 5.40215 15.5355 4.46447C14.5978 3.52678 13.3261 3 12 3ZM8.60442 20.3383C8.96988 19.9242 9.60182 19.8848 10.0159 20.2503C10.5453 20.7176 11.2385 21 12 21C12.7615 21 13.4546 20.7176 13.9841 20.2503C14.3981 19.8848 15.0301 19.9242 15.3955 20.3383C15.761 20.7523 15.7216 21.3843 15.3075 21.7497C14.4267 22.5272 13.2674 23 12 23C10.7326 23 9.57324 22.5272 8.69242 21.7497C8.27835 21.3843 8.23895 20.7523 8.60442 20.3383Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
