/**
 * Prepare a string to be used as a SLUG.
 * @param name
 * @param toLowerCase
 */
export function prepareSLUG(name: string, toLowerCase: boolean = true): string {
  let res: string[] = []
  const allowSymbols: Set<string> = new Set<string>('abcdefghijklmnopqrstuvwxyz0123456789_-.'.split(''))

  if (toLowerCase) {
    name = name.trim().toLowerCase()
  } else {
    name = name.trim()
  }

  for (let i = 0; i < name.length; i++) {
    let c: string = name[i].toLowerCase()
    if (!allowSymbols.has(c)) {
      if (c == ' ') {
        res.push('-')
      }
    } else {
      res.push(name[i])
    }
  }

  return res.join('')
}
