import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputSearch, useSearchFilter } from '@dashboard/ui'
import { PageHeader, Pagination, usePagination } from '@/ui'
import { getGranularity, getPeriodParamsFromUrlRange } from '../dashboard/util'
import { usePaymentsQuery } from './api/usePaymentsQuery'
import { TransactionsFilters, TransactionsTableView } from './components'
import { TransactionsFilter } from './types'
import { DataGranularity, PaymentStatus } from '@/api/dashboard'
import { useTransactionsStatQuery } from '@/layouts/transaction/api/useTransactionsStatQuery'
import { TransactionStatChart } from '@/layouts/transaction/widgets/TransactionStatChart'
import { useTransactionsTotalStatByDates } from '@/layouts/transaction/api/useTransactionsTotalStatQuery'

const TransactionsTable = () => {
  const { t } = useTranslation()
  const [error] = useState<string>('')
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { page, query, onPageChange, needShowPagination } = usePagination(20)

  const filter = useSearchFilter<TransactionsFilter>()
  const dateFilter = getPeriodParamsFromUrlRange(filter.values.start_at, filter.values.end_at)
  const generalFilter = {
    search_string: filter.values.search_string as string,
    countries: filter.values.countries as string,
    status: filter.values.status as PaymentStatus,
  }
  const { data: payments = [], isLoading } = usePaymentsQuery(companyId, gameId, {
    ...query,
    ...generalFilter,
    ...dateFilter,
  })

  const granularity =
    dateFilter.start_at && dateFilter.end_at
      ? getGranularity({
          from: new Date(dateFilter.start_at * 1000),
          to: new Date(dateFilter.end_at * 1000),
        })
      : DataGranularity.Day

  const { data: paymentsByDate = [], isLoading: isChartLoading } = useTransactionsStatQuery(companyId, gameId, {
    ...generalFilter,
    ...dateFilter,
    granularity: granularity,
  })

  const { data: totalPaymentData, isLoading: isTotalLoading } = useTransactionsTotalStatByDates(companyId, gameId, {
    ...generalFilter,
    ...dateFilter,
  })

  const hasFilter = Object.entries(filter.values).some(([_, value]) => !!value)

  return (
    <div className="flex h-full flex-col" role="transaction-page">
      <PageHeader>{t('dashboard.transactions')}</PageHeader>

      {(payments.length > 0 || hasFilter || page > 1) && (
        <div className="mb-4 flex gap-3">
          <InputSearch placeholder={t('search')} {...filter.registerInput('search_string')} />
          <TransactionsFilters filter={filter} />
        </div>
      )}

      <TransactionStatChart
        granularity={granularity}
        className="mb-2 min-h-[124px] w-full"
        status={filter.values.status as string}
        isLoading={isChartLoading || isTotalLoading}
        total={totalPaymentData}
        data={paymentsByDate}
      />

      <TransactionsTableView payments={payments} isLoading={isLoading} error={!!error} search={hasFilter} />
      {needShowPagination(isLoading, payments) && (
        <Pagination hasNext={payments.length >= query.limit} page={page} onPageChange={p => onPageChange(p)} />
      )}
    </div>
  )
}

export default TransactionsTable
