import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Edit03, Menu, MenuItem, MenuItemDivider, PopperMenuProps, Trash, useModal } from '@dashboard/ui'
import { TranslationGlossaryRead } from '@/api/dashboard'
import { L10nGlossaryItemModalConfirmDelete } from './L10nGlossaryItemModalConfirmDelete'
import { L10nGlossaryItemModalEdit } from './L10nGlossaryItemModalEdit'

interface L10nGlossaryItemMenuProps extends PopperMenuProps {
  item: TranslationGlossaryRead
}

export const L10nGlossaryItemMenu = forwardRef<HTMLDivElement, L10nGlossaryItemMenuProps>(({ item, ...rest }, ref) => {
  const { t } = useTranslation()

  const openEditModal = useModal(props => <L10nGlossaryItemModalEdit {...props} item={item} />)
  const openDeleteModal = useModal(props => <L10nGlossaryItemModalConfirmDelete {...props} item={item} />)

  const onClickEdit = () => {
    openEditModal()
    rest?.onClose()
  }

  const onClickDelete = () => {
    openDeleteModal()
    rest?.onClose()
  }

  return (
    <Menu ref={ref} {...rest}>
      <MenuItem icon={Edit03} onClick={onClickEdit}>
        {t('edit')}
      </MenuItem>
      <MenuItemDivider />
      <MenuItem icon={Trash} variant="danger" onClick={onClickDelete}>
        {t('delete')}
      </MenuItem>
    </Menu>
  )
})
