import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { dashboardClient } from '@/api'
import { DailyRewardUpdate } from '@/api/dashboard'

export const useDailyRewardsUpdate = (programId: string) => {
  const queryClient = useQueryClient()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  return useMutation({
    mutationFn: (update: DailyRewardUpdate) =>
      dashboardClient.v1.updateDailyReward(companyId, gameId, programId, update).then(r => r.data),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        predicate: ({ queryKey }) =>
          queryKey.includes('daily-rewards') && queryKey.includes(companyId) && queryKey.includes(gameId),
      })
    },
  })
}
