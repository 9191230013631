import { HTMLAttributes, ReactNode, forwardRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDown, ChevronUp, cn, getMotionProps } from '@dashboard/ui'

interface CollapseViewProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode
  children?: ReactNode
}

const entryMotionProps = getMotionProps({
  initial: { paddingTop: 0, height: 0, opacity: 0 },
  animate: { paddingTop: 8, height: 'auto', opacity: 1 },
  exit: { paddingTop: 0, height: 0, opacity: 0 },
})

export const CollapseView = forwardRef<HTMLInputElement, CollapseViewProps>(function CheckBoxContainer(
  { children, label, ...rest },
  ref,
) {
  const [opened, setOpened] = useState(false)
  return (
    <div className={rest.className} ref={ref}>
      <div
        className={cn(
          'flex h-[42px] cursor-pointer select-none items-center justify-between border-t border-border-secondary text-caption-md ',
          opened ? 'text-text-secondary' : 'text-text-tertiary',
        )}
        onClick={() => setOpened(!opened)}
      >
        {label}

        {opened ? <ChevronUp /> : <ChevronDown />}
      </div>
      {children && (
        <AnimatePresence>
          {opened && (
            <motion.div {...entryMotionProps} className="py-2">
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  )
})
