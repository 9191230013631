import { useCallback, useState } from 'react'
import useKeyPress from 'react-use/lib/useKeyPress'

/**
 * Select multiple items base on array of objects
 * @param initial
 */
export const useBulkSelectGeneric = (initial: string[] = [], allItems: string[] = []) => {
  const [isShiftPress] = useKeyPress('Shift')
  const [selected, setSelected] = useState<string[]>(initial)

  const onSelect = useCallback(
    (item: string) => {
      if (isShiftPress && selected.length > 0) {
        return onMultiSelect(allItems, item)
      }

      if (!selected.find(it => item == it)) {
        setSelected(value => [...value, item])
      } else {
        setSelected(value => value.filter(v => v !== item))
      }
    },
    [selected, setSelected, allItems, isShiftPress],
  )

  const onSelectItems = useCallback(
    (items: string[], value: boolean) => {
      let newSelected = [...selected]
      for (const item of items) {
        if (value) {
          if (!newSelected.find(it => item == it)) {
            newSelected.push(item)
          }
        } else {
          newSelected = newSelected.filter(v => v !== item)
        }
      }

      setSelected(newSelected)
    },
    [selected, setSelected],
  )

  const onReset = useCallback(
    (items: string[]) => {
      setSelected(items)
    },
    [setSelected],
  )

  const onMultiSelect = (allItems: string[], item: string) => {
    if (selected.length == 0) {
      onSelect(item)
      return
    }

    const isSelected = selected.find(it => it == item)
    const lastSelected = selected[selected.length - 1]
    const indexOfLast = allItems.findIndex(it => it == lastSelected)
    const indexOfCurrent = allItems.findIndex(it => it == item)

    const itemsForSelect = allItems.slice(
      Math.min(indexOfLast, indexOfCurrent),
      Math.max(indexOfLast, indexOfCurrent) + 1,
    )

    onSelectItems(itemsForSelect, !isSelected)
  }

  return { selected, onSelect, onReset, onSelectItems, onMultiSelect, setSelected }
}
