import { useNavigate } from 'react-router-dom'
import { CashbackRewardCreate } from '@/api/dashboard'
import {
  SKU_CASHBACK_REWARDS_PATH,
  SkuCashbackRewardsForm,
  skuCashbackRewardCreateMutation,
} from 'src/layouts/sku-cashback–rewards'
import { Drawer, useDrawerPageState } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'

export const SkuCashbackRewardsCreatePage = () => {
  const navigate = useNavigate()
  const { generatePath } = useGenerateCompanyPath()
  const { mutateAsync } = skuCashbackRewardCreateMutation()

  const { open, onClose: onFormClose } = useDrawerPageState(() => {
    navigate(generatePath(SKU_CASHBACK_REWARDS_PATH))
  })

  const onFormSubmit = async (data: CashbackRewardCreate) => {
    await mutateAsync({
      data: {
        ...data,
        from_usd: data.from_usd * 100,
        reward_points: data.reward_points,
      },
    })
    onFormClose()
  }

  return (
    <Drawer open={open}>
      <SkuCashbackRewardsForm onSubmit={onFormSubmit} onClose={onFormClose} />
    </Drawer>
  )
}
