import { Expression, WebsiteTemplateRead } from '@/api/dashboard'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalPresetConfirm,
  ModalProps,
  ModalTitle,
  formatHookFormErrors,
  useModal,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { FieldGroup } from '@/ui'
import { usePydenticForm } from '@/libs/hooks/usePydenticForm'
import { Controller } from 'react-hook-form'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { ErrorMessage } from '@hookform/error-message'
import { validateConditionTokens } from '@/layouts/campaigns/validation'
import { getTokenGroups } from '@/layouts/campaigns/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'
import { dashboardClient } from '@/api'

interface EditWebsiteTemplateModalProps extends ModalProps {
  template: WebsiteTemplateRead
  onUpdated: (template: WebsiteTemplateRead) => void
  onRemoved: () => void
}

export const EditWebsiteTemplateModal = (props: EditWebsiteTemplateModalProps) => {
  const { template, onUpdated, onRemoved, ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = usePydenticForm<WebsiteTemplateRead>({
    values: {
      ...template,
    },
  })

  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const onSubmit = async (data: WebsiteTemplateRead) => {
    if (data.requirements_expression?.tokens?.length) {
      let { error } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(gameSettings),
      )

      if (error) {
        setError('requirements_expression', { message: error })
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
      clearErrors('requirements_expression')
    }

    const colorConfig: Record<string, string> = {}
    const propConfig = props.template.config as Record<string, string>

    for (const key in props.template.config) {
      if (propConfig[key] && typeof propConfig[key] === 'string' && key.endsWith('_color')) {
        colorConfig[key] = propConfig[key]
      }
    }

    if (props.template.id) {
      await dashboardClient.v1.updateWebsiteTemplate(companyId, gameId, props.template.id, {
        name: data.name,
        config: colorConfig,
        requirements_expression: data.requirements_expression,
      })
      onUpdated(props.template)
    } else {
      const { data: template } = await dashboardClient.v1.createWebsiteTemplate(companyId, gameId, {
        name: data.name,
        config: colorConfig,
        requirements_expression: data.requirements_expression,
      })
      onUpdated(template)
    }
    props.onClose?.()
  }

  const onDeleteConfirm = async (item: WebsiteTemplateRead) => {
    await dashboardClient.v1.deleteWebsiteTemplate(companyId, gameId, item.id)
    onRemoved()
    props.onClose?.()
  }

  const openDeleteModal = useModal<{ item: WebsiteTemplateRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: props.item.name }),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: () => {
          onDeleteConfirm(props.item)
          props.onClose()
        },
        color: 'primary',
        label: t('remove'),
      }}
    />
  ))

  return (
    <Modal {...rest} size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalTitle>{t('website-template.rules')}</ModalTitle>

          <FieldGroup label={t('website-template.name')}>
            <Input
              autoFocus
              {...register('name', { required: t('validation.required') })}
              {...formatHookFormErrors(errors, 'name')}
            />
          </FieldGroup>

          <FieldGroup label={t('website-template.segmentation-rules')}>
            <Controller
              control={control}
              name="requirements_expression"
              render={({ field }) => (
                <ExpressionEditor errorTokens={[]} value={field.value} onChange={field.onChange} />
              )}
            />
            <ErrorMessage
              name="requirements_expression"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </ModalContent>

        <ModalFooter>
          {props.template.id && (
            <Button
              color="danger"
              variant="outline"
              onClick={() => openDeleteModal({ item: props.template })}
              className="mr-auto"
              type="button"
            >
              {t('Remove')}
            </Button>
          )}

          <Button color="secondary" variant="outline" onClick={() => props.onClose?.()} type="button">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isSubmitting}>
            {t('Save2')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
