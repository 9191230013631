import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { StorePromotionUpdate } from '@/api/dashboard'

interface StorePromotionMutate {
  id: string
  update: StorePromotionUpdate
}

export const useStorePromotionUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: StorePromotionMutate) =>
      dashboardClient.v1.updateStorePromotion(companyId, gameId, id, update),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['store-promotions', gameId] })
      queryClient.refetchQueries({ queryKey: ['store-promotions', variables.id] })
    },
  })
}
