import '../ToolTip/ToolTip.css'
import { useContext } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material'
import { formatNumber } from '../../../../util'

import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { Legend } from '../Legend'
import { getSerName } from '../../util'
import { FilterType } from '@/api/dashboard'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { TooltipAlertCircle } from '@/ui'

const HubVisitChart = () => {
  const theme = useTheme()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { t } = useTranslation()
  const loading = !context.data.hub_visit_data
  const hubVisitData = context.data.hub_visit_data || []
  const totalUsers = context.data.total_user_count
  const serName = getSerName(context)

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let item = params[0]
        let value = formatNumber(item.value)
        let prevItem = params[1]
        let prevValue = prevItem ? formatNumber(prevItem.value) : ''

        let point = hubVisitData[params[0].dataIndex]

        let prevDay = t(
          'intl.DateTime',
          getDateCategoryParams(dayjs(point.prev_category).toDate(), context.granularity),
        )

        let prevHtml = prevItem
          ? `<div class="chart-tooltip--subtitle">
          <div class="flex opacity-30 justify-between items-center" style="width: 12px">
            <div style="min-width: 6px; height: 4px;" class="bg-border-brand rounded-md"></div>
            <div style="min-width: 5px; height: 4px;" class="bg-border-brand rounded-md"></div>
          </div>
          <span>${prevDay}:</span>
          <span>${prevValue}</span>
        </div>`
          : ''

        return `<div class="chart-tooltip" style="height: auto;">
                  <div class="chart-tooltip--title">${i18next.t('dashboard.hub-visit')}</div>
                  <div class="chart-tooltip--separator"></div>
                  <div class="chart-tooltip--subtitle">
                      <div style="width: 12px; height: 4px;" class="bg-border-brand rounded-md"></div>  
                      <span>${item.axisValue}:</span>
                      <span>${value}</span>
                  </div> 
                  ${prevHtml}
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      top: 5,
      left: 20,
      right: 20,
      bottom: 20,
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: hubVisitData.map(it => t('intl.DateTime', getDateCategoryParams(it.category, context.granularity))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: serName,
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        color: '#0EA5E9',
        lineStyle: {
          color: '#0EA5E9',
          width: 3,
        },
        smooth: 0.05,
        data: hubVisitData.map(it => it.value),
      },
      context.type != FilterType.Today && {
        name: i18next.t('dashboard.revenue.prev'),
        type: 'line',
        showSymbol: false,
        smooth: 0.1,
        color: 'rgba(14, 165, 233, 0.2)',
        data: hubVisitData.map(it => it.prev_value),
        lineStyle: {
          color: '#0EA5E9',
          type: 'dashed',
          opacity: 0.3,
        },
      },
    ],
  }

  return (
    <DashboardCard
      title={i18next.t('dashboard.hub-visit')}
      style={{ height: '290px', gap: '8px', width: '100%' }}
      data-testid="dashboard/chart/hub-visit"
      action={
        <div className="text-caption-md">
          <span className="text-text-tertiary">{t('dashboard.total-users')}</span>
          <span className="ml-1.5 inline-flex items-center gap-1 text-text-secondary">
            {totalUsers ? formatNumber(totalUsers?.value) : <AnimatedDots />}
            <TooltipAlertCircle message={i18next.t('dashboard.total-users.tooltip')} />
          </span>
        </div>
      }
    >
      {loading ? (
        <LoaderContainer style={{ height: '221px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <ReactECharts
            option={options}
            style={{
              height: '221px',
              width: '100%',
            }}
          />
          <Legend
            items={[
              {
                name: serName,
                classColor: 'bg-fg-brand-primary',
                type: 'line',
              },
            ]}
          />
        </>
      )}
    </DashboardCard>
  )
}

export default HubVisitChart
