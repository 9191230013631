import '../ToolTip/ToolTip.css'
import { HTMLAttributes, useContext, useMemo } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import { KeyValue } from '../../../../types'
import { useParams } from 'react-router-dom'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import getCampaignName from '../../../campaigns/pages/CampaignTable/getCampaignName'
import { useGameItemsQuery } from '../../../../api/useGameItems'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import ZeroState from '../ZeroState/ZeroState'
import { getItemName } from '../../../../api/getItemName'
import { formatNumber } from '../../../../util'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '@/layouts/campaigns/api'

interface SankeyTopSkuProps extends HTMLAttributes<HTMLDivElement> {}

interface Connection {
  source: string
  target: string
  value: number
  viewValue: number
  lineStyle: {
    color: {
      type: string
      colorStops: { offset: number; color: string }[]
      global: boolean
    }
  }
}

interface SankeyBar {
  name: string
  type: number
  value: number
  itemStyle: {
    color: string
    borderWidth: number
    borderColor: string
  }
  label?: {
    position: string
  }
}

const EVENT_COLORS = ['#94A3B8', '#FB923C', '#F472B6', '#38BDF8']
const MESSAGE_COLORS = ['#A78BFA', '#818CF8', '#34D399', '#F87171', '#A3E635']

const createConnection = (source: string, target: string, value: number, clr1: string, clr2: string): Connection => {
  return {
    source: source,
    target: target,
    value: value,
    viewValue: value,
    lineStyle: {
      color: {
        type: 'linear',
        colorStops: [
          {
            offset: 0,
            color: clr1,
          },
          {
            offset: 1,
            color: clr2,
          },
        ],
        global: false,
      },
    },
  }
}

const SankeyTopSku = (props: SankeyTopSkuProps) => {
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: campaigns = [] } = useQuery(campaignsQuery(companyId, gameId))
  const data = context.data.sankey_top_sku
  const loading = !context.data.sankey_top_sku

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    ids: data?.message_to_sku?.map(it => it.to_name).join(','),
  })

  const getBarName = (id: string, type: number, tooltip?: boolean) => {
    switch (type) {
      case 1:
        let campaign = campaigns.find(c => c.id === id)
        return campaign ? getCampaignName(campaign.type) : id
      case 2:
        return i18next.t(`dashboard.sankey.${id}`)
      case 3:
        let item = items.find(x => x.id === id)
        if (!item) {
          return id
        }

        let itemName = getItemName(item)

        return tooltip ? itemName + ' ' + item.sku : itemName
    }

    return id
  }

  const options = useMemo(() => {
    if (!data?.message_to_sku.length) {
      return null
    }

    let connections: Connection[] = []
    let sankeyItemsMap = new Map<string, SankeyBar>()

    let eventIdx = 0
    let messageIdx = 0

    data?.event_to_message.forEach(it => {
      const campaign = campaigns.find(x => x.id === it.from_name)
      if (!campaign) {
        return
      }

      let source = sankeyItemsMap.get(it.from_name)
      let target = sankeyItemsMap.get(it.to_name)

      if (!source) {
        source = {
          name: it.from_name,
          type: 1,
          value: it.value,
          itemStyle: {
            color: EVENT_COLORS[eventIdx % EVENT_COLORS.length],
            borderWidth: 2,
            borderColor: '#fff',
          },
        }
        eventIdx++
        sankeyItemsMap.set(it.from_name, source)
      } else {
        source.value += it.value
      }

      if (!target) {
        target = {
          name: it.to_name,
          type: 2,
          value: it.value,
          itemStyle: {
            color: MESSAGE_COLORS[messageIdx % EVENT_COLORS.length],
            borderWidth: 2,
            borderColor: '#fff',
          },
          label: {
            position: data?.message_to_sku.length ? 'right' : 'left',
          },
        }
        messageIdx++
        sankeyItemsMap.set(it.to_name, target)
      } else {
        target.value += it.value
      }

      connections.push(
        createConnection(source.name, target.name, it.value, source.itemStyle.color, target.itemStyle.color),
      )
    })

    let secondConnections: Connection[] = []
    let sums = new Map<string, number>()

    data?.message_to_sku.forEach(it => {
      let source = sankeyItemsMap.get(it.from_name)
      let target = sankeyItemsMap.get(it.to_name)
      if (!source) {
        return
      }

      if (!target) {
        const item = items.find(x => x.id === it.to_name)
        if (!item) {
          return
        }
        target = {
          name: it.to_name,
          type: 3,
          value: it.value,
          itemStyle: {
            color: '#94A3B8',
            borderWidth: 2,
            borderColor: '#fff',
          },
          label: {
            position: 'left',
          },
        }
        sankeyItemsMap.set(it.to_name, target)
      } else {
        target.value += it.value
      }

      let sum = sums.get(source.name) || 0
      sum += it.value
      sums.set(source.name, sum)

      secondConnections.push(
        createConnection(source.name, target.name, it.value, source.itemStyle.color, target.itemStyle.color),
      )
    })

    let arrayOfBars = Array.from(sankeyItemsMap.values())

    for (let c of secondConnections) {
      let linkSourceSum = sums.get(c.source)
      if (!linkSourceSum) {
        continue
      }

      let source = sankeyItemsMap.get(c.source)

      if (!source) {
        continue
      }

      c.value = (c.value * source.value) / linkSourceSum
    }

    return data
      ? {
          tooltip: {
            backgroundColor: '#000000CC',
            borderColor: 'transparent',
            trigger: 'item',
            triggerOn: 'mousemove',
            formatter: function (params: { dataType: string; data: KeyValue; name: string }) {
              if (params.dataType == 'edge') {
                return `<div class="chart-tooltip" style="height: 24px">
                <div class="chart-tooltip--title">
                  Count: ${formatNumber(params.data.viewValue as number)}
                 </div>
              </div>`
              }
              if (params.dataType == 'node') {
                let name = getBarName(params.name, params.data['type'] as number, true)
                return `<div class="chart-tooltip" style="height: 24px">
                <div class="chart-tooltip--title">
                  ${name}: ${params.data.value}
                 </div>
              </div>`
              }
            },
          },
          series: {
            left: 0,
            right: 0,
            nodeWidth: 12,
            nodeGap: 10,
            itemStyle: {
              borderJoin: 'round',
            },
            type: 'sankey',
            label: {
              color: '#000',
              borderWidth: 0,
              formatter: function (params: { name: string; value: number; data: { type: number } }) {
                return getBarName(params.name, params.data.type)
              },
            },
            layout: 'none',
            layoutIterations: 32,
            emphasis: {
              focus: 'adjacency',
            },
            data: arrayOfBars,
            links: [...connections, ...secondConnections],
            lineStyle: {
              color: 'gradient',
              curveness: 0.5,
            },
          },
        }
      : null
  }, [data, campaigns, items])

  return (
    <DashboardCard
      title={i18next.t('dashboard.top-sku')}
      tooltip={i18next.t('dashboard.top-sku.tooltip')}
      style={{ height: '460px', width: '926px', ...props.style }}
      data-testid="dashboard/chart/sankey"
    >
      {loading ? (
        <LoaderContainer style={{ height: '700px' }}>
          <Loader />
        </LoaderContainer>
      ) : (options?.series.data.length as number) > 0 ? (
        <ErrorBoundary>
          <ReactECharts
            option={options}
            style={{
              height: '381px',
              width: '100%',
            }}
          />
        </ErrorBoundary>
      ) : (
        <ZeroState />
      )}
    </DashboardCard>
  )
}

export default SankeyTopSku
