import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@dashboard/ui'
import {
  SKU_CASHBACK_REWARDS_CREATE_PATH,
  SkuCashbackRewardsRow,
  SkuCashbackSkeleton,
  SkuCashbackZeroState,
  skuCashbackRewardsQueryOpts,
} from 'src/layouts/sku-cashback–rewards'
import { Page, PageHeader, TableCell, TableRow } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'

export const SkuCashbackRewardsPage = () => {
  const { t } = useTranslation('sku-cashback')
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: rewards = [], isLoading } = useQuery(skuCashbackRewardsQueryOpts(companyId, gameId))

  if (isLoading) {
    return (
      <Page>
        <PageHeader>{t('title')}</PageHeader>
        <SkuCashbackSkeleton />
      </Page>
    )
  }

  if (rewards.length === 0) {
    return (
      <Page>
        <PageHeader>{t('title')}</PageHeader>
        <SkuCashbackZeroState />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          <Link to={generatePath(SKU_CASHBACK_REWARDS_CREATE_PATH)}>
            <Button>{t('add')}</Button>
          </Link>
        }
      >
        {t('title')}
      </PageHeader>

      <TableRow variant="header">
        <TableCell width="100%">{t('table.name')}</TableCell>
        <TableCell width="60%">{t('table.linked-item')}</TableCell>
        <TableCell width="60%">{t('table.exchange-rate')}</TableCell>
        <TableCell width="50%">{t('table.category')}</TableCell>
        <TableCell width="25%">{t('table.status')}</TableCell>
        <TableCell width={52} />
      </TableRow>

      {rewards.map(reward => (
        <SkuCashbackRewardsRow reward={reward} />
      ))}
    </Page>
  )
}
