import { useParams } from 'react-router-dom'
import { Table, TableCell, TableRow, TableRowEditButton, TableRowSkeleton, TableZeroState } from '@/ui'

import { StorePromotionRead } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { Trash } from '@/icons'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { usePlayerStoreSettings } from '@/layouts/player/api/usePlayerStoreSettings'
import { useCurrentUser } from '@/api/useCurrentUser'
import { ModalPresetConfirm, cn, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useStoresQuery } from '@/layouts/store/api'

export default function UserStoreSettingsTable(props: { userId: string; className?: string }) {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: items = [], isLoading, refetch } = usePlayerStoreSettings(companyId, gameId, props.userId)

  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const getStoresName = (storeIds: string[] | undefined) => {
    if (!storeIds) {
      return ''
    }
    return storeIds.map(storeId => stores.find(store => store.id === storeId)?.name).join(', ')
  }

  const openDeleteModal = useModal<{ item: StorePromotionRead }>(rest => (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: getStoresName(rest.item.store_ids) }),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: () => {
          onDeleteGameItemClick(rest.item)
          rest.onClose()
        },
        color: 'primary',
        label: t('remove'),
      }}
    />
  ))

  const onDeleteGameItemClick = async (item: StorePromotionRead) => {
    await dashboardClient.v1.deleteStorePromotion(item.id, companyId, gameId)
    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="20%">{t('player.store-settings.store')}</TableCell>
        <TableCell width="25%">{t('player.store-settings.created_at')}</TableCell>
        <TableCell width="25%">{t('player.store-settings.expires_at')}</TableCell>
        <TableCell width="40%">{t('player.store-settings.benefits')}</TableCell>
        <TableCell width="40%">{t('player.store-settings.limit')}</TableCell>
        {canEdit && <TableCell width="12%" />}
      </TableRow>
    )
  }

  const renderBenefits = (it: StorePromotionRead) => {
    return (
      <>
        {it.discount_percent && <div>{t('store.item.discount1') + ' ' + it.discount_percent + '%'}</div>}
        {it.bonus_percent && <div>{t('store.item.bonus1') + ' ' + it.bonus_percent + '%'}</div>}
        {it.reward_points_percent && (
          <div>{t('player.store-settings.reward_points_percent') + ' ' + it.reward_points_percent + '%'}</div>
        )}
      </>
    )
  }

  const renderLimits = (it: StorePromotionRead) => {
    return (
      <>
        {it.max_purchase_count && (
          <div>{t('player.store-settings.max_purchase_count') + ': ' + it.max_purchase_count}</div>
        )}
        <div>{t('player.store-settings.current_purchase_count') + ': ' + it.current_purchase_count}</div>
      </>
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={3} columnCount={4} />
  }

  if (!items.length) {
    return (
      <div className="h-[147px] flex-1">
        <TableZeroState
          title={t('player.store-settings.zero.title')}
          message={t('player.store-settings.zero.message')}
        />
      </div>
    )
  }

  return (
    <div className={cn('w-full', props.className)}>
      <Table>
        {renderDefaultHeader()}
        {items.map(it => (
          <TableRow key={it.id}>
            <TableCell width="20%" className="flex items-center gap-3">
              {getStoresName(it.store_ids)}
            </TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.created_at} />
            </TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.end_at} />
            </TableCell>
            <TableCell width="40%">{renderBenefits(it)}</TableCell>
            <TableCell width="40%">{renderLimits(it)}</TableCell>
            {canEdit && (
              <TableCell width="12%">
                <div className="text-right">
                  <TableRowEditButton
                    onChange={v => {
                      switch (v) {
                        case 'remove':
                          openDeleteModal({ item: it })
                          break
                      }
                    }}
                    options={[
                      {
                        icon: <Trash className="text-text-error-primary" />,
                        children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                        value: 'remove',
                      },
                    ]}
                  />
                </div>
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
    </div>
  )
}
