import { Theme } from '@mui/material/styles'
import { baselightTheme } from './theme/DefaultColors'

export const DATE_FORMAT = 'MM/DD/YYYY'

export const MAIN_MARGIN = '20px'

export const MAX_WIDTH = '1400px'

export const SURFACE_SURFACE_FRAME_FILL = '#fff'

export const SURFACE_SURFACE_BG_STROKE = baselightTheme.palette.grey['250']

export const DRAWER_WIDTH = '257px'

export const HUB_EDITOR_LEFT_SIDE_WIDTH = '276px'

export function getAppBarHeight(theme: Theme): string {
  return theme.spacing(7)
}

export function getHubEditorHeaderHeight(theme: Theme): string {
  return theme.spacing(7)
}

export function getLastGameId(): string | null {
  return localStorage.getItem('lastGameId')
}

export function setLastGameId(gameId: string | null) {
  if (gameId) {
    localStorage.setItem('lastGameId', gameId)
  } else {
    localStorage.removeItem('lastGameId')
  }
}

export function getLastCompanyId(): string | null {
  return localStorage.getItem('lastCompanyId')
}

export function setCompanyGameId(value: string | null) {
  if (value) {
    localStorage.setItem('lastCompanyId', value)
  } else {
    localStorage.removeItem('lastCompanyId')
  }
}

export const PAGE_SIZE = 10

export const AI_DONE = '[done]'

export const AI_START_JSON_MESSAGE = '[json]'

export const AI_END_JSON_MESSAGE = '[/json]'

export const DEFAULT_IMAGE_PLACEHOLDER_ITEM = `${
  import.meta.env.VITE_STATIC_IMAGES_URL
}/images/articles-placeholder.png`

export const MAX_ENUM_LENGTH = 100
export const MIN_SLUG_LENGTH = 3
export const MAX_SLUG_LENGTH = 100
export const MAX_NAME_LENGTH = 255
export const MAX_DESCRIPTION_LENGTH = 512
export const MAX_QUANTITY = 1_000_000_000_000_000
