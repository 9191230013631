import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGenerateCompanyPath } from '@/libs'
import { Button } from '@dashboard/ui'
import { SKU_CASHBACK_REWARDS_CREATE_PATH } from '@/layouts/sku-cashback–rewards'
import { TableZeroState } from '@/ui'
import { Plus } from '@/icons'

export const SkuCashbackZeroState = () => {
  const { t } = useTranslation('sku-cashback')
  const { generatePath } = useGenerateCompanyPath()

  return (
    <TableZeroState
      title={t('zero.title')}
      message={t('zero.message')}
      buttons={
        <Link to={generatePath(SKU_CASHBACK_REWARDS_CREATE_PATH)}>
          <Button>
            <Plus size={16} />
            <span>{t('add')}</span>
          </Button>
        </Link>
      }
    />
  )
}
