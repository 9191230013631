import { ReactNode, createElement, forwardRef, useCallback, useEffect, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { UseFloatingMenuState, useFloatingMenu } from '../../hooks';
import { getFloatingMotionProps } from '../../libs';
import { ControlHookFormError } from '../../types';
import { Menu, MenuItem, MenuItemBaseProps, MenuSearch } from '../Menu';
import { SelectBaseButton, SelectBaseButtonProps } from './SelectBaseButton';
import { SelectBaseValue } from './SelectBaseValue';
import { SelectSearch, useSelectSearch } from './useSelectSearch';
import { FormErrorMessage } from '../FormErrorMessage';

export type SelectValue = string | number | null | undefined;

export interface SelectItem extends MenuItemBaseProps {
  value: SelectValue;
}

export interface SelectProps extends Pick<SelectBaseButtonProps, 'placeholder' | 'disabled'>, ControlHookFormError {
  /**
   * Search function to filter items.
   */
  search?: SelectSearch;
  /**
   * Items to be displayed in the select.
   */
  items: SelectItem[];
  /**
   * Value of the selected item.
   */
  value: SelectValue;
  /**
   * Renderer value function (component), it is recommended to look at `SelectBaseValue` and make a similar function.
   * @param item
   */
  renderValueFn?: ({ item }: { item: SelectItem }) => ReactNode;
  /**
   * Renderer footer function (component) for the menu.
   */
  renderMenuFooter?: (state: UseFloatingMenuState) => ReactNode;

  /**
   * Renderer header function (component) for the menu.
   */
  header?: ReactNode;

  /**
   * Callback function that is called when the value changes.
   * @param value
   */
  onChange: (value: SelectValue) => void;
}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    { search, value, items, renderValueFn = SelectBaseValue, renderMenuFooter, onChange, error, header, ...rest },
    ref,
  ) => {
    const {
      state,
      floating: { context, placement },
      getReferenceProps,
      getFloatingProps,
    } = useFloatingMenu(ref);

    const { filteredItems, searchInput, reset } = useSelectSearch(search, items);

    const selectedItem = useMemo(() => items.find(item => item.value === value) || null, [items, value]);

    const initialFocus = useMemo(() => {
      const selectedItemIndex = Math.max(
        items.findIndex(item => item.value === value),
        0,
      );
      return search && value ? selectedItemIndex + 1 : selectedItemIndex;
    }, [search, items, value]);

    const onClickItem = useCallback(
      (item: SelectItem) => {
        onChange(item.value);
        state.setIsOpen(false);
        reset();
      },
      [onChange, reset, state],
    );

    useEffect(() => {
      reset();
    }, [state.isOpen, reset]);

    return (
      <>
        <SelectBaseButton {...rest} {...getReferenceProps()}>
          {selectedItem?.value !== undefined ? createElement(renderValueFn, { item: selectedItem }) : null}
        </SelectBaseButton>

        <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>

        <AnimatePresence>
          {state.isOpen && (
            <FloatingFocusManager context={context} initialFocus={initialFocus}>
              <FloatingPortal>
                <Menu
                  {...getFloatingProps()}
                  className="absolute z-[3000] w-max"
                  motionProps={{
                    ...getFloatingMotionProps(placement),
                    className: 'max-w-none',
                  }}
                  footer={renderMenuFooter && <div>{renderMenuFooter(state)}</div>}
                >
                  {header}
                  {search && <MenuSearch {...searchInput} />}

                  <div id="list-results" role="listbox" className="w-full">
                    {filteredItems.map(({ value, ...item }) => (
                      <MenuItem
                        {...item}
                        key={`select-menu-item-${value}`}
                        aria-label={item.children?.toString()}
                        data-testvalue={item.children?.toString()}
                        onClick={() => onClickItem({ ...item, value })}
                      >
                        {item.children}
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </FloatingPortal>
            </FloatingFocusManager>
          )}
        </AnimatePresence>
      </>
    );
  },
);
