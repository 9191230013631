import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 */
export const useWebsiteTemplates = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['website-templates', gameId],
    queryFn: () => dashboardClient.v1.getWebsiteTemplates(companyId, gameId),
    select: response => response.data,
  })
}
