import i18next from 'i18next'
import { SelectSkuForm } from './SelectSkuForm'
import SelectVideoPanel from './SelectVideoPanel'
import SelectNewsPanel from './SelectNewsPanel'
import SelectLeaderBoardPanel from './SelectLeaderBoardPanel'
import TextBlockEditor from './TextBlockEditor'
import { Side } from '../editor-types'
import { Button, FieldGroup, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import SelectPagePanel from './SelectPagePanel'
import { useFeatureFlags } from '@/libs/hooks/useFeatureFlags'
import IFrameBlockEditor from '@/hub-editor/dialogs/IFrameBlockEditor'
import { LimitedStoreBannerForm } from '@/hub-editor/dialogs/LimitedStoreBannerForm'
import { Input, Select } from '@dashboard/ui'
import ImageBannerEditor from './ImageBannerEditor'
import RichContentBlockEditor from './RichContentBlockEditor'
import CarouselBlockEditor from './CarouselBlockEditor'

interface AddBlockDialogProps extends ModalProps {
  onSave: (block: Block) => void
  block?: Block | undefined
  side: Side
}

const EditBlockModal = (props: AddBlockDialogProps) => {
  const { ...rest } = props

  const methods = useForm<Block>({
    values: {
      block: 'FeaturedItemBlock',
      id: '',
      ...props.block,
    },
  })

  const { featureFlags } = useFeatureFlags()

  const { handleSubmit, control, watch, register } = methods

  const editors = {
    FeaturedItemBlock: <SelectSkuForm />,
    YoutubeBlock: <SelectVideoPanel />,
    FeaturedNewsBlock: <SelectNewsPanel />,
    FeaturedPageBlock: <SelectPagePanel />,
    LeaderboardCardBlock: <SelectLeaderBoardPanel />,
    LeaderboardFullBlock: <SelectLeaderBoardPanel />,
    LimitedStoreBannerBlock: <LimitedStoreBannerForm />,
    TextBlock: <TextBlockEditor />,
    IFrameBlock: <IFrameBlockEditor />,
    ImageBannerBlock: <ImageBannerEditor />,
    RichContentBlock: <RichContentBlockEditor />,
    CarouselBlock: <CarouselBlockEditor />,
  }

  let items = [
    { label: 'Video', value: 'YoutubeBlock' },
    { label: 'Featured Item', value: 'FeaturedItemBlock' },
    { label: 'Featured News', value: 'FeaturedNewsBlock' },
    { label: 'Featured Page', value: 'FeaturedPageBlock' },
    { label: 'Redeem Coupon', value: 'RedeemCouponBlock' },
    { label: 'Card Leaderboard list', value: 'LeaderboardCardBlock' },
    { label: 'Text', value: 'TextBlock' },
    { label: 'IFrame', value: 'IFrameBlock' },
    { label: 'Rich Content', value: 'RichContentBlock' },
    { label: 'Carousel', value: 'CarouselBlock' },
  ]

  if (!props.side || props.side === 'up' || props.side === 'down') {
    items.push(
      { label: 'Achievement List', value: 'AchievementListBlock' },
      { label: 'Store', value: 'StoreBlock' },
      { label: 'Limited Store Banner', value: 'LimitedStoreBannerBlock' },
      { label: 'Category', value: 'FeaturedCategoryBlock' },
      { label: 'Full Leaderboard list', value: 'LeaderboardFullBlock' },
      { label: 'Leaderboard Collection', value: 'LeaderboardCollectionBlock' },
      { label: 'Latest News', value: 'LatestNewsBlock' },
      { label: 'Image banner', value: 'ImageBannerBlock' },
    )

    if (featureFlags.daily_rewards_enabled) {
      items.push({ label: 'Daily Rewards', value: 'DailyRewardsBlock' })
    }

    if (featureFlags.loyalty_program_enabled) {
      items.push({ label: 'Loyalty Program', value: 'LoyaltyProgramBlock' })
    }
  }

  items = items.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

  const saveClick = (data: Block) => {
    props.onSave(data)
    props.onClose?.()
  }

  const blockType = watch('block')

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.block ? 'hub-editor.edit-block' : 'hub-editor.add-block')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('hub-editor.block-type')}>
          <Controller
            name="block"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                disabled={!!props.block}
                items={items.map(it => ({
                  children: it.label,
                  value: it.value,
                }))}
              />
            )}
          />
        </FieldGroup>

        {blockType == 'FeaturedItemBlock' && (
          <FieldGroup label={i18next.t('hub-editor.block-name')}>
            <Input {...register('name')} autoFocus={true} />
          </FieldGroup>
        )}

        <FormProvider {...methods}>
          {
            editors[
              blockType as
                | 'FeaturedItemBlock'
                | 'YoutubeBlock'
                | 'FeaturedNewsBlock'
                | 'FeaturedPageBlock'
                | 'LeaderboardCardBlock'
                | 'LeaderboardFullBlock'
                | 'TextBlock'
            ]
          }
        </FormProvider>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={() => props.onClose?.()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.block ? 'apply' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditBlockModal
