import { forwardRef, useCallback, useEffect, useState } from 'react';
import { DateRange, OnSelectHandler } from 'react-day-picker';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../libs';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { PopperMenuProps } from '../Popper';
import { Menu, MenuItem } from '../Menu';
import { InputDateRangePreset, InputDateRangeProps } from './InputDateRange';

interface InputDateRangeMenuProps
  extends PopperMenuProps,
    Pick<InputDateRangeProps, 'presets' | 'value' | 'onChange' | 'clearable'> {}

export const InputDateRangeMenu = forwardRef<HTMLDivElement, InputDateRangeMenuProps>(
  ({ presets = [], value, clearable, onChange, ...rest }, ref) => {
    const { t } = useTranslation('ui');

    const [internalValue, setInternalValue] = useState<DateRange | null>(value);

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    const onChangeCalendar: OnSelectHandler<DateRange | undefined> = useCallback(value => {
      setInternalValue(value || null);
    }, []);

    const onClickPreset = useCallback(
      (preset: InputDateRangePreset) => () => {
        onChange(preset.range);
        rest.onClose?.();
      },
      [onChange, rest],
    );

    const onClearClick = useCallback(() => {
      onChange(null);
      rest.onClose?.();
    }, [onChange, rest]);

    const onClickSetDate = useCallback(() => {
      onChange(internalValue);
      rest.onClose?.();
    }, [internalValue, onChange, rest]);

    return (
      <Menu
        {...rest}
        ref={ref}
        motionProps={{ ...rest.motionProps, className: cn(rest.motionProps?.className, 'max-w-none') }}
        maxHeight="none"
      >
        <div className={cn('-m-3 grid', presets.length && 'grid-cols-[9rem_1fr]')}>
          {!!presets.length && (
            <div className="h-full border-r border-border-secondary">
              <OverlayScrollbarsComponent
                className="p-3"
                options={{ scrollbars: { autoHide: 'scroll' } }}
                style={{ maxHeight: 332 }}
                defer={true}
              >
                {presets.map(preset => (
                  <MenuItem key={`preset-${preset.label}`} onClick={onClickPreset(preset)}>
                    {preset.label}
                  </MenuItem>
                ))}
              </OverlayScrollbarsComponent>
            </div>
          )}

          <div className="flex flex-col p-3">
            <div className="mb-3">
              <Calendar
                mode="range"
                numberOfMonths={2}
                required={false}
                selected={internalValue || undefined}
                onSelect={onChangeCalendar}
              />
            </div>

            <div className="-mx-3 mt-auto flex items-center justify-end gap-3 border-t border-border-secondary p-3 pb-0">
              <Button variant="outline" color="secondary" size="sm" onClick={clearable ? onClearClick : rest.onClose}>
                {t(clearable ? 'input-date-range.clear' : 'input-date-range.cancel')}
              </Button>
              <Button size="sm" onClick={onClickSetDate}>
                {t('input-date-range.set')}
              </Button>
            </div>
          </div>
        </div>
      </Menu>
    );
  },
);

InputDateRangeMenu.displayName = 'InputDateMenu';
