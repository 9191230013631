import { FormProvider, useForm } from 'react-hook-form'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle, useModal } from '@dashboard/ui'
import { TranslationGlossaryRead } from '@/api/dashboard'
import { l10nGlossaryEditMutation } from '../api'
import { GlossaryItemFormPartData, L10nGlossaryItemFormPart } from './L10nGlossaryItemFormPart'
import { L10nGlossaryItemModalConfirmDelete } from './L10nGlossaryItemModalConfirmDelete'

interface L10nGlossaryItemModalEditProps extends ModalProps {
  item: TranslationGlossaryRead
}

export const L10nGlossaryItemModalEdit = ({ item, ...rest }: L10nGlossaryItemModalEditProps) => {
  const methods = useForm<GlossaryItemFormPartData>({
    defaultValues: item,
  })

  const { mutateAsync } = l10nGlossaryEditMutation()

  const openDeleteModal = useModal(props => <L10nGlossaryItemModalConfirmDelete {...props} item={item} />)

  const onSubmit = methods.handleSubmit(async data => {
    await mutateAsync({ data, itemId: item.id })
    rest?.onClose()
  })

  return (
    <Modal {...rest}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalTitle onClose={rest.onClose}>Edit term</ModalTitle>
            <L10nGlossaryItemFormPart />
          </ModalContent>
          <ModalFooter
            extra={
              <Button variant="tertiary" color="danger" onClick={openDeleteModal}>
                Delete
              </Button>
            }
          >
            <Button variant="outline" color="secondary" onClick={rest.onClose}>
              Cancel
            </Button>
            <Button loading={methods.formState.isSubmitting} type="submit">
              Save changes
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
