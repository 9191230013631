import { useCallback } from 'react'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'
import { TranslationGlossaryRead } from '@/api/dashboard'
import { l10nGlossaryDeleteMutation } from '../api'

interface L10nGlossaryItemModalConfirmDeleteProps extends ModalProps {
  item: TranslationGlossaryRead
}

export const L10nGlossaryItemModalConfirmDelete = ({ item, ...rest }: L10nGlossaryItemModalConfirmDeleteProps) => {
  const { mutateAsync } = l10nGlossaryDeleteMutation()

  const onClickConfirm = useCallback(async () => {
    await mutateAsync({ itemId: item.id })
    rest?.onClose(undefined, { closeAll: true })
  }, [item.id, mutateAsync, rest])

  return (
    <ModalPresetConfirm
      {...rest}
      title={{ children: 'Confirm the action', subtitle: `Are you sure you want to remove "${item.term}"?` }}
      cancel={{ label: 'Cancel' }}
      submit={{ label: 'Delete', color: 'danger', callback: onClickConfirm }}
    />
  )
}
