import { LexicalEditor } from 'lexical'
import { useModal } from '@dashboard/ui'
import { Image01, YouTube } from '@/icons'
import { ImagePluginInsertModal } from '../ImagePlugin/ImagePluginInsertModal'
import { YouTubeInsertModal } from '../YouTubePlugin'
import { ToolbarButton } from './ToolbarButton'

interface ToolbarImageButtonProps {
  editor: LexicalEditor
}

export const ToolbarImageButton = ({ editor }: ToolbarImageButtonProps) => {
  const openImagePluginModal = useModal(props => <ImagePluginInsertModal {...props} editor={editor} />)

  const openYouTubeModal = useModal(props => <YouTubeInsertModal {...props} editor={editor} />)

  return (
    <>
      <ToolbarButton onClick={() => openImagePluginModal()}>
        <Image01 size={16} />
      </ToolbarButton>
      <ToolbarButton type="button" onClick={() => openYouTubeModal()}>
        <YouTube size={16} />
      </ToolbarButton>
    </>
  )
}
