import { ReactNode } from 'react'
import { cn } from '@dashboard/ui'
import { useBanner, useStickyAttr } from '@/libs'

interface PageToolbarProps {
  children: ReactNode
}

export const PageToolbar = ({ children }: PageToolbarProps) => {
  const { ref } = useStickyAttr({ offsetY: 128 })
  const { getStickyTop } = useBanner()

  return (
    <div
      ref={ref}
      className={cn(
        'sticky left-0 z-20 flex items-center gap-3 border-b border-border-secondary p-3',
        '[&[is-sticky="true"]]:bg-fg-primary',
        '[&[is-sticky="true"]]:rounded-none',
        '[&[is-sticky="true"]]:shadow-xs',
        getStickyTop(),
      )}
    >
      {children}
    </div>
  )
}
