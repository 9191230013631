import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@dashboard/ui'

export const SlashCircle: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 0.666626C3.9499 0.666626 0.666656 3.94987 0.666656 7.99996C0.666656 12.05 3.9499 15.3333 7.99999 15.3333C12.0501 15.3333 15.3333 12.05 15.3333 7.99996C15.3333 3.94987 12.0501 0.666626 7.99999 0.666626ZM4.25487 3.31203C5.28131 2.49094 6.58331 1.99996 7.99999 1.99996C11.3137 1.99996 14 4.68625 14 7.99996C14 9.41663 13.509 10.7186 12.6879 11.7451L4.25487 3.31203ZM3.31206 4.25484C2.49097 5.28128 1.99999 6.58328 1.99999 7.99996C1.99999 11.3137 4.68628 14 7.99999 14C9.41667 14 10.7187 13.509 11.7451 12.6879L3.31206 4.25484Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
