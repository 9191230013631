import { ChangeEvent, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { pickBy, throttle } from 'es-toolkit';

export type SearchFilterQueryValue = string | number | null | undefined;

type RegisterSingleSelect<T> = (field: T) => {
  multiple: false;
  value: SearchFilterQueryValue;
  onChange: (value: SearchFilterQueryValue) => void;
};

type RegisterMultipleSelect<T> = (
  field: T,
  multiple: true,
) => {
  multiple: true;
  value: SearchFilterQueryValue[];
  onChange: (value: SearchFilterQueryValue[]) => void;
};

export interface SearchFilterGeneric<T extends string = string> {
  values: Partial<Record<T, SearchFilterQueryValue>>;
  onChange: (filter: SearchFilterGeneric<T>['values']) => void;
  registerInput: (field: T) => {
    ref: (ref: HTMLInputElement) => void;
    defaultValue: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  registerSelect: RegisterSingleSelect<T> & RegisterMultipleSelect<T>;
  searchParams: URLSearchParams;
}

const shouldPick = (value: SearchFilterQueryValue) => !!value;

/**
 * Hook to manage search filters.
 */
export const useSearchFilter = <T extends string>(): SearchFilterGeneric<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const registeredRefs = useMemo(() => new Map<T, HTMLInputElement>(), []);

  const values = useMemo(
    () => pickBy(Object.fromEntries(searchParams.entries()) as SearchFilterGeneric<T>['values'], shouldPick),
    [searchParams],
  );

  const onChange: SearchFilterGeneric<T>['onChange'] = useCallback(
    filter => {
      const filteredValues = pickBy(filter, shouldPick);

      for (const [field, ref] of registeredRefs.entries()) {
        if (ref && filteredValues[field] !== ref.value) {
          ref.value = filteredValues[field]?.toString() || '';
        }
      }

      // Always remove the page from the search params.
      // @ts-ignore
      delete filteredValues.page;

      setSearchParams(filteredValues as unknown as URLSearchParams);
    },
    [registeredRefs, setSearchParams],
  );

  const throttledOnChange = throttle(onChange, 350, { edges: ['trailing'] });

  const registerInput: SearchFilterGeneric<T>['registerInput'] = useCallback(
    field => {
      return {
        ref: (ref: HTMLInputElement) => {
          registeredRefs.set(field, ref);
        },
        defaultValue: values[field]?.toString() || '',
        onChange: e => {
          throttledOnChange({ ...values, [field]: e.target.value });
        },
      };
    },
    [registeredRefs, values, throttledOnChange],
  );

  // @ts-ignore
  const registerSelect: SearchFilterGeneric<T>['registerSelect'] = useCallback(
    (field, multiple) => {
      return {
        multiple: multiple || false,
        value: multiple ? values[field]?.toString().split(',') || [] : values[field]?.toString() || '',
        onChange: v => {
          if (multiple) {
            onChange({ ...values, [field]: v.join(',') });
          } else {
            onChange({ ...values, [field]: v });
          }
        },
      };
    },
    [values, onChange],
  );

  return {
    values,
    registerInput,
    registerSelect,
    onChange,
    searchParams,
  };
};
