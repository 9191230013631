import { Skeleton } from '@dashboard/ui'

interface L10nGlossaryItemRowHeaderProps {
  locale: string
  targetLocale?: string
}

export const L10nGlossaryItemRowHeader = ({ locale, targetLocale }: L10nGlossaryItemRowHeaderProps) => (
  <div className="grid h-11 grid-cols-[1fr_1fr_1.75rem] content-center items-center border-b-2 border-border-secondary px-4 text-caption-sm font-semibold text-text-secondary">
    <div>{locale}</div>
    <div>{targetLocale || <Skeleton className="h-4 w-1/4" />}</div>
  </div>
)
