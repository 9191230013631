interface UseEyeDropperReturn {
  open: () => Promise<{ sRGBHex: string }>;
  supported: boolean;
}

/**
 * Hook to use the EyeDropper API.
 */
export const useEyeDropper = (): UseEyeDropperReturn => {
  if ('EyeDropper' in window) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
    const eyeDropper = new EyeDropper();
    return {
      open: () => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        return eyeDropper.open() as Promise<{ sRGBHex: string }>;
      },
      supported: true,
    };
  } else {
    return {
      open: () => {
        return Promise.reject(new Error('EyeDropper not supported'));
      },
      supported: false,
    };
  }
};
