import { ReactNode, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, getMotionProps } from '@/libs'
import { modalStore } from './modalStore'
import { Alert, Snackbar } from '@mui/material'

export interface ModalProviderProps {
  children: ReactNode
}

const overlayMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const entryMotionProps = getMotionProps({
  initial: { y: -4, scale: 0.98, opacity: 0 },
  animate: { y: 0, scale: 1, opacity: 1 },
  exit: { y: -4, scale: 0.98, opacity: 0 },
})

export const rushMotionProps = getMotionProps({
  initial: { y: 24, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 24, opacity: 0 },
})

export const o1MotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const o2MotionProps = getMotionProps({
  initial: { top: 0, left: 0, right: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, opacity: 0 },
  animate: { top: 12, left: 16, right: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, opacity: 1 },
  exit: { top: 0, left: 0, right: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, opacity: 0 },
})

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const location = useLocation()
  const { modals, reset, toasts, removeCurrentToast } = modalStore()
  const current = useMemo(() => modals.at(-1), [modals])
  const currentToast = useMemo(() => toasts.at(-1), [toasts])

  useEffect(() => {
    if (current?.params.mode === 'rush') {
      const outlet = document.querySelector('[data-id="main-outlet-root"]')
      setTimeout(() => {
        outlet?.setAttribute('data-modal-rush', 'false')
      }, 50)
    }

    reset()
  }, [location])

  return (
    <>
      {children}
      <AnimatePresence mode="wait">
        {current && (
          <div
            key={`modal-${current.id}`}
            className="fixed left-0 top-0 z-50 flex size-full items-center justify-center"
          >
            {current.params.mode === 'default' && (
              <motion.div
                {...overlayMotionProps}
                className="absolute left-0 top-0 z-[-1] size-full bg-[rgba(0,0,0,0.8)]"
              />
            )}
            {current.params.mode === 'rush' && (
              <>
                <motion.div {...o1MotionProps} className="absolute left-0 top-0 z-[-2] size-full bg-[#c1c8d1]" />
                <motion.div {...o2MotionProps} className="absolute z-[-2] h-full bg-[#dfe3e7]" />
              </>
            )}

            <motion.div
              {...(current.params.mode === 'default' && entryMotionProps)}
              {...(current.params.mode === 'rush' && rushMotionProps)}
              className={cn(
                'flex size-full items-center justify-center overflow-y-auto',
                current.params.mode === 'default' && 'p-6',
                current.params.mode === 'rush' && 'pt-6',
              )}
            >
              {current.element}
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {currentToast && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => removeCurrentToast()}
          style={{
            zIndex: 10000,
          }}
        >
          <Alert onClose={() => removeCurrentToast()} severity={currentToast.severity} variant="filled">
            {currentToast.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
