import { useTranslation } from 'react-i18next'
import { Button, useModal } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Plus } from '@/icons'
import { TableZeroState } from '@/ui'
import { L10nLocaleModalAdd } from '@/layouts/localization'
import { LOCALIZATION_GLOSSARY_LOCALE_PATH, LOCALIZATION_TRANSLATIONS_LOCALE_PATH } from '@/libs'

interface L10nZeroStateProps {
  type: 'translations' | 'glossary'
}

export const L10nZeroState = ({ type }: L10nZeroStateProps) => {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()

  const openLocalModalAdd = useModal(props => (
    <L10nLocaleModalAdd
      {...props}
      redirectTo={type === 'translations' ? LOCALIZATION_TRANSLATIONS_LOCALE_PATH : LOCALIZATION_GLOSSARY_LOCALE_PATH}
    />
  ))

  return (
    <TableZeroState
      className="w-full"
      title={t('localization.zero.title')}
      message={t('localization.zero.text')}
      buttons={
        canEdit && (
          <Button size="sm" onClick={() => openLocalModalAdd()}>
            <Plus size={16} />
            <span>{t('localization.zero.add')}</span>
          </Button>
        )
      }
    />
  )
}
