import { ReactNode, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, getMotionProps } from '../../../libs'
import { useBanner } from '@/libs/hooks/useBanner'

interface DrawerProps {
  children: ReactNode
  open: boolean
  width?: number
}

export const overlayProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const entryProps = getMotionProps({
  initial: { x: 64, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: 64, opacity: 0 },
})

export const Drawer = ({ children, open, width = 864 }: DrawerProps) => {
  const { getStickyTop, getStickyHeight } = useBanner()

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : ''
    return () => {
      document.body.style.overflow = ''
    }
  }, [open])

  return (
    <AnimatePresence>
      {open && (
        <motion.div className={cn('fixed right-0 top-0 z-50 size-full overflow-hidden')}>
          <motion.div {...overlayProps} className="fixed right-0 top-0 size-full bg-[rgba(0,0,0,0.5)]" />
          <motion.div
            {...entryProps}
            className={cn('fixed right-0  z-10 flex flex-col bg-white', getStickyTop(), getStickyHeight())}
            style={{ width }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
