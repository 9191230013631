import { useTranslation } from 'react-i18next'
import { Button, Popper, Spinner } from '@dashboard/ui'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { SidebarLink } from '@/ui'
import { DotsHorizontal } from '@/icons'
import {
  LOCALIZATION_GLOSSARY_LOCALE_PATH,
  LOCALIZATION_TRANSLATIONS_LOCALE_PATH,
  getPercentFrom,
  useGenerateCompanyPath,
} from '@/libs'
import { LOCALE_FLAGS } from '@/util'
import { LocaleMenu } from './LocaleMenu'
import { useCurrentUser } from '@/api/useCurrentUser'

interface LocaleSidebarLinkProps {
  type: 'translations' | 'glossary'
  locale: LocaleRead
  loading?: boolean
}

interface LocaleFlagProps extends Pick<LocaleSidebarLinkProps, 'type'> {
  locale: LocaleRead
}

const LocaleFlag = ({ type, locale }: LocaleFlagProps) => {
  return (
    <div className="relative">
      {type === 'translations' && locale.published_at && (
        <>
          {locale.status === LocaleStatus.Clean && (
            <div className="absolute -right-1.5 -top-1.5 size-3 rounded-full border-2 border-fg-primary bg-fg-lime-primary shadow-xs" />
          )}
          {locale.status === LocaleStatus.Synchronization && (
            <div className="absolute -right-1.5 -top-1.5 size-3 animate-pulse rounded-full border-2 border-fg-primary bg-fg-lime-primary shadow-xs" />
          )}
          {locale.status === LocaleStatus.Fuzzy && (
            <div className="absolute -right-1.5 -top-1.5 size-3 rounded-full border-2 border-fg-primary bg-fg-warning-primary-error shadow-xs" />
          )}
        </>
      )}
      <img
        className="w-5 rounded-sm shadow-sm"
        src={`${LOCALE_FLAGS}/${locale.locale.toUpperCase()}.svg`}
        alt={locale.locale}
      />
    </div>
  )
}

export const LocaleSidebarLink = ({ type, locale, loading }: LocaleSidebarLinkProps) => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { canEdit } = useCurrentUser()

  const href = type === 'translations' ? LOCALIZATION_TRANSLATIONS_LOCALE_PATH : LOCALIZATION_GLOSSARY_LOCALE_PATH

  const progress = locale.stats.total ? getPercentFrom(locale.stats.done || 0, locale.stats.total || 0) : 0

  const renderExtraMenu = () => {
    if (type !== 'translations') {
      return null
    }

    return (
      <Popper popper={props => <LocaleMenu {...props} locale={locale} />} params={{ placement: 'bottom-end' }}>
        <Button
          className="px-0 shadow-none"
          variant="outline"
          color="secondary"
          size="xs"
          onClick={e => e.stopPropagation()}
        >
          <DotsHorizontal size={14} />
        </Button>
      </Popper>
    )
  }

  return (
    <SidebarLink
      data-testid={`l10n/sidebar/link-${locale.locale}`}
      to={generatePath(href, { localeId: locale.id }, true)}
      icon={<LocaleFlag type={type} locale={locale} />}
      extra={canEdit && !loading && renderExtraMenu()}
    >
      <div className="flex w-full items-center gap-3">
        <div className="flex w-full items-center gap-1 truncate">
          <span className="truncate">{t(`locales.${locale.locale}`)}</span>
          {type === 'translations' && (
            <>
              {!loading && progress > 0 && (
                <>
                  <span className="text-text-quarterary-hover">·</span>
                  <span className="tabular-nums text-text-quarterary-hover">{progress}%</span>
                </>
              )}
            </>
          )}
        </div>
        {loading && (
          <div className="ml-auto">
            <Spinner className="text-border-brand" size={22} />
          </div>
        )}
      </div>
    </SidebarLink>
  )
}
