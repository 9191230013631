import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { cva } from 'class-variance-authority'
import { cn } from '@dashboard/ui'

interface TableRowProps {
  children: ReactNode
  variant?: 'default' | 'header' | 'clickable'
  to?: string
  id?: string
  className?: string
  onClick?: () => void
}

const variants = cva('flex items-center border-b border-border-secondary text-left transition-colors', {
  variants: {
    variant: {
      default: "font-medium text-text-secondary last:border-none [&[href*='/']]:hover:bg-fg-secondary",
      clickable: 'cursor-pointer hover:bg-fg-secondary',
      header: 'sticky left-0 top-0 z-[1] h-11 bg-fg-primary text-xs font-semibold text-text-primary',
    },
    defaultVariants: {
      variant: 'default',
    },
  },
})

export const TableRow = ({ children, variant = 'default', to, className, id, onClick }: TableRowProps) => {
  if (to) {
    return (
      <Link className={cn(variants({ variant }), className)} to={to} tabIndex={-1} id={id} onClick={onClick}>
        {children}
      </Link>
    )
  }

  return (
    <div className={cn(variants({ variant }), className)} tabIndex={-1} id={id} onClick={onClick}>
      {children}
    </div>
  )
}
