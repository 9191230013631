import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@dashboard/ui'

export const Check: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        d="M14.1663 4.25L6.37467 12.0417L2.83301 8.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
