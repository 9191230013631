import { useEffect } from 'react'
import {
  AghanimGameSchemasGame,
  Expression,
  GameSettingsRead,
  GameStore,
  LoginType,
  SocialMedia,
} from '../../api/dashboard'
import { dashboardClient, getErrorText } from '../../api'
import { useParams } from 'react-router-dom'
import DescBlock from './components/DescBlock'
import Block from './components/Block'
import { Textarea } from '../../components/ui/Textarea'
import {
  FieldGroup,
  ImageUploader,
  Input,
  InputExtraSection,
  ModalConfirm,
  Page,
  PageHeader,
  SelectMultiple,
  Toggle,
  useModal,
} from '@/ui'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import ApiKeySettings from './widgets/ApiKeySettings'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { useGameSettingsQuery } from '../../api/useGameSettingsQuery'
import { useGameSettingsCreateMutate } from '../../api/useGameSettingsCreateMutate'
import { useGameSettingsUpdateMutate } from '../../api/useGameSettingsUpdateMutate'
import { SocialMediaList } from './widgets/SocialMediaList'
import { StoreSocialMode } from './tyles'
import DangerousButton from '../../components/DangerousButton'
import { setCompanyGameId, setLastGameId } from '../../Settings'
import { updateGameInCache } from '../../api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { InfoCircle } from '@/icons'
import { Trans, useTranslation } from 'react-i18next'
import { deepLinkRegexp, getMotionProps } from '../../libs'
import { AnimatePresence, motion } from 'framer-motion'
import { useBanner } from '@/libs/hooks/useBanner'
import TransactionBranding from '@/layouts/game-settings/widgets/TransactionBranding'
import { LexicalController } from '@/components/lexical'
import { Button, FormErrorMessage } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { validateConditionTokens } from '@/layouts/campaigns/validation'
import { getTokenGroups } from '@/layouts/campaigns/util'

const rewardMotionProps = getMotionProps({
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0, transition: { delay: 0.05 } },
})

export const GameSettingsPage = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { bannerMode } = useBanner()
  const queryClient = useQueryClient()
  const { canEdit, isSuperAdmin } = useCurrentUser()
  const {
    setValue,
    control,
    reset,
    formState: { errors, isDirty, dirtyFields: dirtyGameFields },
    register,
    handleSubmit,
    watch,
  } = useForm<AghanimGameSchemasGame>({})

  const { data: settings, isLoading } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useGameSettingsCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useGameSettingsUpdateMutate(companyId, gameId)

  const methods = useForm<Omit<GameSettingsRead, 'id'>>({
    mode: 'onChange',
    values: { ...settings },
  })

  const {
    control: controlWebSetting,
    watch: watchSettings,
    register: registerSetting,
    handleSubmit: handleSubmitSettings,
    reset: resetSettings,
    setError: setErrorSettings,
    formState: { isDirty: isDirtySettings, errors: errorsSettings, dirtyFields: dirtySettingsFields },
  } = methods

  const showToast = useToast()

  const loadGame = async () => {
    let { data } = await dashboardClient.v1.getGame(companyId, gameId)
    reset(data)
  }

  useEffect(() => {
    loadGame()
  }, [gameId])

  const onDiscardClick = () => {
    reset()
    resetSettings()
  }

  const saveGeneral = async (data: AghanimGameSchemasGame) => {
    try {
      let { data: newGame } = await dashboardClient.v1.updateGame(companyId, gameId, {
        ...data,
      })

      reset(newGame)

      updateGameInCache(queryClient, newGame)
    } catch (e) {
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
    }
  }

  const saveSetting = async (data: Omit<GameSettingsRead, 'id'>) => {
    if (data.back_to_game_url == '') {
      data.back_to_game_url = null as unknown as undefined
    }

    if (!data.enable_reward_points) {
      data.reward_points_settings = {
        reward_points_rate: null as unknown as undefined,
      }
    }

    if (data.forced_authorization_expression?.tokens?.length) {
      let { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.forced_authorization_expression?.tokens || []),
        getCampaignContext(settings),
      )
      if (error) {
        setErrorSettings('forced_authorization_expression', { message: error })
        return
      }

      if (errTokens?.length) {
        return
      }
    } else {
      data.forced_authorization_expression = null as unknown as Expression
    }

    try {
      if (!settings) {
        await createMutateAsync({
          data: data,
        })
      } else {
        await updateMutateAsync({
          settingsId: settings.id,
          data: data,
        })
      }
      resetSettings(data)
    } catch (e) {
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
      return e
    }
  }

  const onConfirmArchive = async () => {
    await dashboardClient.v1.deleteGame(companyId, gameId)
    setLastGameId(null)
    setCompanyGameId(null)
    window.location.href = '/'
  }

  const openConfirmArchive = useModal(props => (
    <ModalConfirm
      message={t('game-settings.archive-game.title')}
      subMessage={t('game-settings.archive.confirm')}
      confirmButtonText={t('game-settings.archive')}
      cancelButtonText={t('no')}
      color="danger"
      onConfirm={onConfirmArchive}
      showClose={true}
      {...props}
    />
  ))

  const socialMedias = watch('social_medias')
  const gameStores = watch('game_stores')
  const id = watch('id')
  const loginMethods = watch('login_methods')
  const enable_reward_points = watchSettings('enable_reward_points')
  const reward_points_rate = watchSettings('reward_points_settings.reward_points_rate')

  const renderLoginSettings = () => {
    return (
      <DescBlock title={t('game-settings.login')} description={t('game-settings.login.description')}>
        <Block>
          <FieldGroup label={t('game-settings.login.methods-label')}>
            <Controller
              name="login_methods"
              control={control}
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <SelectMultiple
                  disabled={!isSuperAdmin}
                  options={Object.values(LoginType).map(s => ({
                    value: s,
                    children: t('game-settings.login.type.' + s),
                  }))}
                  onChange={v => {
                    field.onChange(v)
                  }}
                  value={field.value || []}
                />
              )}
            />
            <ErrorMessage
              name="login_methods"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          {loginMethods?.includes(LoginType.GameLink) && (
            <>
              <FieldGroup label={t('game-settings.login.game_auth_link')}>
                <Input
                  {...register('login_settings.game_auth_link', { required: t('validation.required') })}
                  disabled={!canEdit}
                />
                <ErrorMessage
                  name="game_auth_link"
                  errors={errors}
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FieldGroup>
              <FieldGroup label={t('game-settings.login.game_link_button_text')}>
                <Input {...registerSetting('game_link_button_text')} disabled={!canEdit} />
                <ErrorMessage
                  name="game_link_button_text"
                  errors={errors}
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FieldGroup>
              <FieldGroup label={t('game-settings.login.game_link_icon')}>
                <Controller
                  control={control}
                  name="login_settings.game_auth_button_icon"
                  render={({ field }) => (
                    <ImageUploader
                      disabled={!canEdit}
                      accept={{
                        'image/jpeg': ['.jpeg', '.jpg'],
                        'image/png': [],
                        'image/webp': [],
                      }}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  name="login_settings.game_auth_button_icon"
                  errors={errors}
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FieldGroup>
            </>
          )}
          <FieldGroup label={t('game-settings.login.user_id.text-label')}>
            <Textarea
              disabled={!canEdit}
              placeholder={t('game-settings.login.user_id.text-placeholder')}
              {...register('login_settings.user_id_instruction_text')}
            />
          </FieldGroup>

          <FieldGroup label={t('game-settings.login.user_id.image-label')}>
            <Controller
              control={control}
              name="login_settings.user_id_instruction_image"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="login_settings.user_id_instruction_image"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('game-settings.login.user_id.welcome-image')}>
            <Controller
              control={control}
              name="login_settings.welcome_image"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="login_settings.welcome_image"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('game-settings.login.user_id.background-image')}>
            <Controller
              control={control}
              name="login_settings.background_image"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="login_settings.background_image"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
          <div className="flex h-[42px] w-full">
            <FieldGroup
              label={t('game-settings.login.show-instruction-title')}
              caption={t('game-settings.login.show-instruction-caption')}
            >
              {' '}
            </FieldGroup>
            <div className="ml-auto">
              <Controller
                control={control}
                name="login_settings.show_instruction_instead_login_form"
                disabled={!canEdit}
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                )}
              />
            </div>
          </div>
        </Block>
      </DescBlock>
    )
  }

  const renderAuthConditions = () => {
    return (
      <DescBlock
        title={t('game-settings.forced_authorization_expression')}
        description={t('game-settings.forced_authorization_expression.caption')}
      >
        <Controller
          control={controlWebSetting}
          name="forced_authorization_expression"
          render={({ field }) => (
            <ExpressionEditor
              className="mt-2 rounded-2xl"
              id={'forced_authorization_expression'}
              errorTokens={[]}
              value={field.value}
              onChange={v => {
                field.onChange(v)
              }}
            />
          )}
        />
        <ErrorMessage
          name="forced_authorization_expression"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </DescBlock>
    )
  }

  const renderWhaleConditions = () => {
    return (
      <DescBlock title={t('game-settings.whale_expression')} description={t('game-settings.whale_expression.caption')}>
        <Controller
          control={controlWebSetting}
          name="whale_expression"
          render={({ field }) => (
            <ExpressionEditor
              className="mt-2 rounded-2xl"
              id={'whale_expression'}
              errorTokens={[]}
              value={field.value}
              onChange={v => {
                field.onChange(v)
              }}
            />
          )}
        />
        <ErrorMessage
          name="whale_expression"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </DescBlock>
    )
  }

  const renderAgeSettings = () => {
    return (
      <DescBlock
        title={t('game-settings.login.age-consent.title')}
        description={t('game-settings.login.age-consent.desc')}
      >
        <Block>
          <FieldGroup label={t('game-settings.login.age-consent.text-label')}>
            <Input
              min={0}
              disabled={!canEdit}
              placeholder={t('game-settings.login.age-consent.text-placeholder')}
              type="number"
              key="login_settings.age_of_consent"
              {...register('login_settings.age_of_consent', {
                setValueAs: value => {
                  if (value == null) {
                    return null
                  }
                  return parseInt(value)
                },
              })}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderRewardPointSettings = () => {
    return (
      <DescBlock title={t('game-settings.reward_points.title')} description={t('game-settings.reward_points.desc')}>
        <Block>
          <div className="flex flex-col gap-2">
            <div className="flex h-[42px] w-full">
              <FieldGroup
                label={t('game-settings.reward_points.title')}
                caption={t('game-settings.reward_points.desc2')}
              >
                {' '}
              </FieldGroup>
              <div className="ml-auto">
                <Controller
                  control={controlWebSetting}
                  name="enable_reward_points"
                  render={({ field }) => (
                    <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                  )}
                />
              </div>
            </div>

            <AnimatePresence initial={false}>
              {enable_reward_points && (
                <motion.div {...rewardMotionProps}>
                  <div className="my-[20px] border-b border-border-secondary" />
                  <FieldGroup label={t('game-settings.reward_points.perc-rate')}>
                    <Input
                      placeholder={t('game-settings.reward_points.place-holder')}
                      type="number"
                      extraLeft={<InputExtraSection side="left">%</InputExtraSection>}
                      {...registerSetting('reward_points_settings.reward_points_rate', {
                        required: t('validation.required'),
                        min: {
                          value: 0.01,
                          message: t('validation.min', {
                            field: t('game-settings.reward_points_rate'),
                            value: 0.01,
                          }),
                        },
                      })}
                      step={0.01}
                    />
                    <div className="mt-1.5 flex items-start gap-1 text-text-secondary">
                      <InfoCircle size={14} />
                      <div className="text-caption-md font-normal">
                        <Trans
                          i18nKey="game-settings.reward_points.input-desc"
                          values={{
                            value: reward_points_rate ? reward_points_rate : 0,
                          }}
                          components={{ b: <span className="font-medium text-text-secondary-hover" /> }}
                        />
                        <div className="text-caption-md font-normal text-text-secondary">
                          {t('game-settings.reward_points.input-desc2')}
                        </div>
                      </div>
                    </div>

                    <ErrorMessage
                      name="reward_points_settings.reward_points_rate"
                      errors={errorsSettings}
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    />
                  </FieldGroup>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Block>
      </DescBlock>
    )
  }

  const renderMaintenanceSettings = () => {
    const enabled = watchSettings('maintenance_enabled')
    return (
      <DescBlock title={t('game-settings.maintenance.title')} description={t('game-settings.maintenance.desc')}>
        <Block>
          <div className="flex flex-col gap-2">
            <div className="flex h-[42px] w-full">
              <FieldGroup
                label={t('game-settings.maintenance.enable')}
                caption={t('game-settings.maintenance.enable-desc')}
              >
                {' '}
              </FieldGroup>
              <div className="ml-auto">
                <Controller
                  control={controlWebSetting}
                  name="maintenance_enabled"
                  render={({ field }) => (
                    <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                  )}
                />
              </div>
            </div>
          </div>
          <FieldGroup label={t('game-settings.maintenance.text')} className="my-10">
            <LexicalController
              control={controlWebSetting}
              layout="compact"
              name="maintenance_text"
              disabled={!enabled}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderGeneral = () => {
    return (
      <DescBlock title={t('game-settings.game-info')} description={t('game-settings.game-info.desc')}>
        <Block>
          <FieldGroup label={t('game-settings.name')}>
            <Input
              {...register('name', { required: t('validation.required') })}
              disabled={!canEdit || bannerMode == 'test'}
            />
            <ErrorMessage
              name="name"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('game-settings.description')}>
            <Textarea
              {...register('description', { required: t('validation.required') })}
              disabled={!canEdit || bannerMode == 'test'}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('game-settings.url')}>
            <Input
              disabled={!canEdit}
              {...register('url', {
                required: t('validation.required'),
                pattern: {
                  value: /^(https?:\/\/)/,
                  message: t('validation.invalid_url'),
                },
              })}
            />
            <ErrorMessage
              name="url"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.logo_url')}>
            <Controller
              control={control}
              name="logo_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="logo_url"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderCheckoutSettings = () => {
    return (
      <DescBlock
        title={t('game-settings.checkout-settings.title')}
        description={t('game-settings.checkout-settings.desc')}
      >
        <Block>
          <FieldGroup label={t('game-settings.checkout-settings.purchase-successful')}>
            <Textarea {...registerSetting('purchase_successful_text')} maxLength={255} disabled={!canEdit} />
          </FieldGroup>

          <FieldGroup label={t('game-settings.checkout-settings.receipt-appreciation')}>
            <Textarea {...registerSetting('receipt_appreciation_text')} maxLength={255} disabled={!canEdit} />
          </FieldGroup>

          <FieldGroup label={t('game-settings.checkout-settings.back-to-game-url')}>
            <Input
              disabled={!canEdit}
              {...registerSetting('back_to_game_url', {
                pattern: {
                  value: deepLinkRegexp,
                  message: t('validation.invalid_url'),
                },
              })}
              maxLength={255}
            />
            <ErrorMessage
              name="back_to_game_url"
              errors={errorsSettings}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderArchiveGame = () => {
    if (!isSuperAdmin) {
      return null
    }
    return (
      <DescBlock title={t('game-settings.danger-zone')}>
        <DangerousButton
          onClick={() => openConfirmArchive()}
          btnText={t('game-settings.archive-game')}
          title={t('game-settings.archive-game.title')}
          subTitle={t('game-settings.archive-game.subtitle')}
          color={'error'}
        />
      </DescBlock>
    )
  }

  const handleSaveClick = async () => {
    if (isDirty) {
      await handleSubmit(saveGeneral)()
    }
    if (isDirtySettings) {
      await handleSubmitSettings(saveSetting)()
    }

    if (Object.keys(errors).length === 0 && Object.keys(errorsSettings).length === 0) {
      showToast({ message: t('saved'), severity: ToastSeverity.success })
    }
  }

  const isDirtyForm = () => {
    if (isLoading || !id) {
      return false
    }

    if (Object.keys(dirtySettingsFields).length || Object.keys(dirtyGameFields).length) {
      return true
    }
  }

  return (
    <Page>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          canEdit && (
            <>
              {isDirtyForm() && (
                <Button variant="outline" color="secondary" size="sm" onClick={onDiscardClick}>
                  {t('Discard')}
                </Button>
              )}
              <Button size="sm" disabled={!isDirtyForm()} onClick={handleSaveClick}>
                {t('Save')}
              </Button>
            </>
          )
        }
      >
        {t('sidebar.game-settings')}
      </PageHeader>

      <div className="flex w-full justify-center">
        <div style={{ maxWidth: '1164px' }} className="mt-5 flex w-full flex-col gap-12">
          {renderGeneral()}

          <DescBlock title={t('game-settings.social-media-list')} description={t('game-settings.social-media-desc')}>
            <SocialMediaList
              mode={StoreSocialMode.social}
              items={socialMedias || []}
              onDelete={
                canEdit
                  ? delItem => {
                      setValue(
                        'social_medias',
                        socialMedias?.filter(it => it !== delItem),
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  : undefined
              }
              onAdd={
                canEdit
                  ? (item: SocialMedia | GameStore) => {
                      if (item.name && item.url) {
                        setValue('social_medias', [...socialMedias, item as SocialMedia], {
                          shouldDirty: true,
                        })
                      }
                    }
                  : undefined
              }
            />
          </DescBlock>

          <DescBlock title={t('game-settings.game-stores-list')} description={t('game-settings.game-stores-desc')}>
            <SocialMediaList
              mode={StoreSocialMode.store}
              items={gameStores || []}
              onDelete={
                canEdit
                  ? delItem => {
                      setValue(
                        'game_stores',
                        gameStores?.filter(it => it !== delItem),
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  : undefined
              }
              onAdd={
                canEdit
                  ? (item: SocialMedia | GameStore) => {
                      if (item.name && item.url) {
                        setValue('game_stores', [...gameStores, item as GameStore], {
                          shouldDirty: true,
                        })
                      }
                    }
                  : undefined
              }
            />
          </DescBlock>

          <ApiKeySettings />
          {renderCheckoutSettings()}
          <FormProvider {...methods}>
            <TransactionBranding />
          </FormProvider>
          {renderLoginSettings()}
          {isSuperAdmin && renderAuthConditions()}
          {isSuperAdmin && renderWhaleConditions()}
          {isSuperAdmin && renderRewardPointSettings()}
          {renderAgeSettings()}
          {renderMaintenanceSettings()}
          {renderArchiveGame()}

          <div className="w-[200px]" />
        </div>
      </div>
    </Page>
  )
}
