import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Item } from '@/api/dashboard'
import { useItemBulkDeleteMutate } from '../api'
import { Button, Modal, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'

interface BulkDeleteModalProps extends ModalProps {
  items: Item[]
  onResetItems?: (items: string[]) => void
}

export const BulkDeleteModal = ({ items, onResetItems, ...rest }: BulkDeleteModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync, isPending } = useItemBulkDeleteMutate(companyId, gameId)

  const onSubmit = async () => {
    await mutateAsync({ data: items.map(i => ({ id: i.id })) })
    rest.onClose?.()
    onResetItems?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalTitle>
        {items.length > 1
          ? t('sku.bulk-delete.modal.title-multiple', { count: items.length })
          : t('sku.bulk-delete.modal.title-single', { itemName: items.at(0)?.name })}
      </ModalTitle>
      <div>
        <p className="text-paragraph-md text-text-secondary">{t('sku.bulk-delete.modal.desc')}</p>
      </div>
      <ModalFooter>
        <Button type="button" size="sm" onClick={rest.onClose} color="secondary" variant="outline">
          {t('sku.bulk-delete.modal.cancel')}
        </Button>
        <Button type="submit" color="danger" size="sm" loading={isPending} onClick={onSubmit}>
          {t('sku.bulk-delete.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
