import { forwardRef, useCallback, useMemo } from 'react';
import { MENU_ITEMS_OPTIMIZED_THRESHOLD, Menu, MenuGroup, MenuItem, MenuItemDivider } from '../Menu';
import { PopperMenuProps } from '../Popper';
import { SelectFilterBaseProps, SelectFilterSingleProps } from './SelectFilter';
import { SelectFilterGroup, SelectFilterItem } from './types';

export interface SelectFilterSingleMenuProps
  extends PopperMenuProps,
    Pick<SelectFilterBaseProps, 'items' | 'groups' | 'title'>,
    Pick<SelectFilterSingleProps, 'multiple' | 'value' | 'onChange'> {}

interface GroupWithItems extends SelectFilterGroup {
  items: SelectFilterItem[];
}

export const SelectFilterSingleMenu = forwardRef<HTMLDivElement, SelectFilterSingleMenuProps>(
  ({ items, groups, title, onChange, onClose, ...rest }, ref) => {
    const needOptimization = useMemo(() => items.length > MENU_ITEMS_OPTIMIZED_THRESHOLD, [items.length]);

    const groupedItems: GroupWithItems[] = groups
      ? groups.map(group => ({
          ...group,
          items: items.filter(item => group.values.includes(item.value)),
        }))
      : [];

    const ungroupedItems = items.filter(item => !groups?.some(group => group.values.includes(item.value)));

    const onClickMenuItem = useCallback(
      (itemValue: SelectFilterItem['value']) => {
        onChange(itemValue);
        onClose();
      },
      [onChange, onClose],
    );

    const renderGroup = (group: GroupWithItems) => {
      if (!group.items.length) {
        return null;
      }

      return (
        <MenuGroup key={group.label?.toString()} label={group.label} variant="default">
          {group.items.map(({ children, value: itemValue, ...item }) => (
            <MenuItem {...item} key={`menu-item-${itemValue}`} onClick={() => onClickMenuItem(itemValue)}>
              {children}
            </MenuItem>
          ))}
        </MenuGroup>
      );
    };

    return (
      <Menu ref={ref} {...rest}>
        {title && (
          <div className="mb-2.5 flex items-center justify-between">
            <div className="text-caption-md font-semibold">{title}</div>
          </div>
        )}

        <div id="list-results" role="listbox" className="w-full">
          {groupedItems.map(renderGroup)}
          {groupedItems.length && ungroupedItems.length ? <MenuItemDivider /> : null}
          {ungroupedItems.map(({ children, value: itemValue, ...item }) => (
            <MenuItem
              {...item}
              key={`menu-item-${itemValue}`}
              optimized={needOptimization}
              onClick={() => onClickMenuItem(itemValue)}
            >
              {children}
            </MenuItem>
          ))}
        </div>
      </Menu>
    );
  },
);
