import { ConditionNode, Node, Token } from '@/api/dashboard'
import ConditionEditor from '@/layouts/campaigns/expr/ConditionEditor'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { validateConditionTokens } from '@/layouts/campaigns/validation'
import { BlockError } from '@/layouts/campaigns/types'
import { useContext, useEffect, useState } from 'react'
import { getTokenGroups } from '@/layouts/campaigns/util'
import { CampaignContext, ICampaignContext } from '@/layouts/campaigns/Context'
import { FormErrorMessage } from '@dashboard/ui'

interface ExpressionFormProps {
  node: ConditionNode
  setNode: (node: ConditionNode) => void
  onClose: () => void
  error: BlockError | null
}

export const ExpressionForm = (props: ExpressionFormProps) => {
  const context = useContext(CampaignContext) as ICampaignContext
  const [node, setNode] = useState(props.node)
  const [errorTokens, setErrorTokens] = useState<Token[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onSaveClick = () => {
    const tokenGroups = getTokenGroups(node.expr.tokens || []) as Token[][]
    let { error, errorTokens: errTokens } = validateConditionTokens(tokenGroups, context)

    setErrorTokens(errTokens || [])

    setErrorMessage(error)
    if (error) {
      return
    }
    props.setNode({
      ...props.node,
      expr: {
        ...props.node.expr,
        tokens: tokenGroups.flat(),
      },
    })
    props.onClose()
  }

  useEffect(() => {
    if (props.error?.blockId == props.node.id) {
      setErrorMessage(props.error.errorText)
    } else {
      setErrorMessage('')
    }
  }, [props.error])

  return (
    <div>
      <SettingsHeader node={node as Node} onSave={onSaveClick} onClose={props.onClose} />
      <div className="p-6">
        <ConditionEditor
          errTokens={errorTokens}
          node={node}
          setNode={n => {
            setNode(n)
            setErrorMessage('')
            setErrorTokens([])
          }}
        />
        {errorMessage && <FormErrorMessage> {errorMessage} </FormErrorMessage>}
      </div>
    </div>
  )
}
