import { useEffect, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import { HUB_THEMES_EDITOR_PATH } from '../libs/routerPaths'
import { ComingSoonLogo } from '@/components/ComingSoonLogo'
import { SpinnerApple } from '@/components/ui/SpinnerApple'
import { getDateTimeFormatParams } from '@/libs/dates'
import { PageHeader, ToastSeverity, useToast } from '@/ui'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { dashboardClient } from '@/api'
import { Button } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Features } from '@/libs/features'
import { useTranslation } from 'react-i18next'
import { DashboardAccessLevel } from '@/api/DashboardAccessLevel'

const HubThemePage = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string } as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { canEdit, getFeatureAccessType, isSuperAdmin } = useCurrentUser()
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const sordetSites = websites.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
  const webSite = sordetSites[0]
  const showToast = useToast()
  const [websitePreviewLink, setWebsitePreviewLink] = useState<string>()

  const [isLoadingDesktop, setIsLoadingDesktop] = useState(true)
  const [isLoadingMobile, setIsLoadingMobile] = useState(true)

  useEffect(() => {
    if (webSite?.url) {
      const url = new URL(webSite.url)
      url.searchParams.set('share_token', webSite.share_token)
      url.searchParams.set('embedded', 'true')
      setWebsitePreviewLink(url.href)
    }
  }, [webSite?.url])

  let images = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/dashboard`

  const renderBottomSettings = () => {
    return (
      <div className="flex w-full items-center justify-start p-5">
        <div className="h-full">
          <div className="text-hero-h3 font-bold text-text-primary">{webSite?.title}</div>
          <div className="mt-3 text-caption-md font-normal text-text-secondary">
            {webSite ? (
              <div className="flex items-center gap-2">
                {t('hub-settings.last-saved')}:
                <span>{t('intl.DateTime', getDateTimeFormatParams(webSite.modified_at || webSite.created_at))}</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {webSite && canEdit && getFeatureAccessType(Features.hub_builder) == DashboardAccessLevel.ALLOWED && (
          <div className="ml-auto">
            <Link to={generatePath(HUB_THEMES_EDITOR_PATH, { companyId, gameId, wbId: webSite.id })}>
              <Button variant="outline" size="md">
                {t('hub-settings.customize-design')}
              </Button>
            </Link>
          </div>
        )}
      </div>
    )
  }

  const renderStartEditor = () => {
    return (
      <div className="flex flex-col items-center overflow-hidden rounded-xl border border-border-secondary bg-fg-primary">
        <div
          style={{
            justifyContent: 'center',
            backgroundColor: '#000',
            borderRadius: 0,
            backgroundImage: `url("${images}/hub_builder_back.png")`,
            minHeight: '488px',
            paddingLeft: '45px',
            paddingRight: '45px',
            backgroundSize: 'contain',
            width: '100%',
            minWidth: '1037px',
            display: 'flex',
            gap: '15px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: '710px',
              height: '451px',
              backgroundImage: `url("${images}/hub_builder_macbook_pro_16.png")`,
              padding: '12px 12px 0 12px',
              marginTop: 'auto',
              backgroundSize: 'cover',
              backgroundColor: '#090808',
              overflow: 'hidden',
              borderTopLeftRadius: '18px',
              borderTopRightRadius: '18px',
            }}
          >
            {isLoadingDesktop && (
              <div className="flex size-full items-center justify-center text-text-quarterary">
                <SpinnerApple />
              </div>
            )}
            {websitePreviewLink && (
              <iframe
                onLoad={() => {
                  setIsLoadingDesktop(false)
                }}
                frameBorder={0}
                scrolling="no"
                src={websitePreviewLink}
                style={{
                  borderTopLeftRadius: '18px',
                  borderTopRightRadius: '18px',
                  height: '892px',
                  width: '1424px',
                  overflowY: 'hidden',
                  transform: 'scale(0.48)',
                  transformOrigin: '0 0',
                  pointerEvents: 'none',
                  display: isLoadingDesktop ? 'none' : 'block',
                }}
              />
            )}
          </div>
          <div
            style={{
              width: '230px',
              height: '398px',
              padding: '9px 7px 0px',
              marginTop: 'auto',
              backgroundImage: `url("${images}/hub_builder_mobile.png")`,
              backgroundSize: 'cover',
              backgroundColor: '#090808',
              borderTopLeftRadius: '34px',
              borderTopRightRadius: '34px',
              overflow: 'hidden',
            }}
          >
            {isLoadingMobile && (
              <div className="flex size-full items-center justify-center text-text-quarterary">
                <SpinnerApple />
              </div>
            )}

            {websitePreviewLink && (
              <iframe
                onLoad={() => {
                  setIsLoadingMobile(false)
                }}
                frameBorder={0}
                src={websitePreviewLink}
                scrolling="no"
                style={{
                  borderTopLeftRadius: '50px',
                  borderTopRightRadius: '50px',
                  width: '384px',
                  height: '708px',
                  overflowY: 'hidden',
                  transform: 'scale(0.55)',
                  transformOrigin: '5px 0',
                  pointerEvents: 'none',
                  display: isLoadingMobile ? 'none' : 'block',
                }}
              />
            )}
          </div>
        </div>

        {renderBottomSettings()}
      </div>
    )
  }

  const onClearCacheClick = async () => {
    await dashboardClient.v1.clearWebsiteCache(companyId, gameId, webSite.id)
    showToast({ message: t('hub-settings.clear-cache.done'), severity: ToastSeverity.success })
  }

  const renderActions = () => {
    if (!webSite?.url) {
      return
    }
    const url = new URL(webSite.url)
    const viewHubink = url.href
    return (
      <>
        {isSuperAdmin && (
          <Button variant="outline" color="secondary" size="sm" onClick={onClearCacheClick}>
            {t('hub-settings.clear-cache')}
          </Button>
        )}
        <Button color="primary" size="sm" onClick={() => window.open(viewHubink)}>
          {t('hub-settings.view-your-hub')}
        </Button>
      </>
    )
  }

  return (
    <div>
      <PageHeader extra={renderActions()}>{t('hub-themes.title')}</PageHeader>

      {renderStartEditor()}

      <div className="my-5 rounded-2xl1 border border-border-secondary shadow-xs">
        <div className="border-b border-border-secondary p-6">
          <h3 className="text-title-t4">{t('sidebar.theme-library')}</h3>
        </div>
        <div className="p-6">
          <div className="flex items-center justify-center bg-fg-primary-hover px-6 py-12">
            <div className="flex items-start gap-6">
              <div className="size-16">
                <ComingSoonLogo />
              </div>
              <div>
                <div className="mb-1.5 text-title-t6">{t('coming-soon.title')}</div>
                <div className="max-w-96 text-paragraph-sm text-text-tertiary">{t('coming-soon.desc')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-2xl1 border border-border-secondary shadow-xs">
        <div className="border-b border-border-secondary p-6">
          <h3 className="text-title-t4">{t('sidebar.popular-themes')}</h3>
        </div>
        <div className="p-6">
          <div className="flex items-center justify-center bg-fg-primary-hover px-6 py-12">
            <div className="flex items-start gap-6">
              <div className="size-16">
                <ComingSoonLogo />
              </div>
              <div>
                <div className="mb-1.5 text-title-t6">{t('coming-soon.title')}</div>
                <div className="max-w-96 text-paragraph-sm text-text-tertiary">{t('coming-soon.desc')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HubThemePage
