import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { TableZeroState } from '@/ui'

interface L10nGlossaryLocaleEmptyStateProps {
  onClear: () => void
}

export const L10nGlossaryLocaleEmptyState = ({ onClear }: L10nGlossaryLocaleEmptyStateProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex h-full flex-col items-center justify-center rounded-b-md bg-fg-primary-hover">
      <TableZeroState
        title={t('localization.locale.zero.title')}
        message={t('localization.locale.zero.text')}
        buttons={
          <Button type="button" onClick={onClear}>
            {t('localization.locale.zero.clear-filters')}
          </Button>
        }
      />
    </div>
  )
}
