import { MouseEvent } from 'react';
import { XCircle } from '../../icons';
import { cn } from '../../libs';
import { Button } from '../Button';

interface InputButtonClearProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const InputButtonClear = ({ onClick }: InputButtonClearProps) => (
  <Button
    className={cn(
      'px-0 text-text-quarterary transition-all',
      'invisible',
      'opacity-0',
      'group-hover/input:visible',
      'group-hover/input:opacity-100',
      'group-has-[input:focus]/input:visible',
      'group-has-[input:focus]/input:opacity-100',
      'hover:text-text-quarterary-hover',
      'active:text-text-brand-primary',
    )}
    variant="link"
    color="secondary"
    size="xs"
    onClick={onClick}
  >
    <XCircle size={16} />
  </Button>
);
