interface GetPagePreviewUrlParams {
  parent: string | undefined
  child: string | undefined
}

/**
 * Get the preview URL for a page.
 * @param base
 * @param slug
 * @param params
 */
export const getPagePreviewUrl = (base: string, slug: string, params: GetPagePreviewUrlParams) => {
  return [base, params.parent, params.child, slug]
    .filter((p, index) => ([1, 2].includes(index) ? p !== 'uncategorized' : true))
    .filter(Boolean)
    .join('/')
}
