import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useGenerateCompanyPath } from '@/libs'
import { localesQueryOptions } from '../api'

/**
 *
 * @param toPath
 */
export const useNavigateToFirstLocale = (toPath: string) => {
  const navigate = useNavigate()
  const { companyId, gameId, localeId, generatePath } = useGenerateCompanyPath<{ localeId: string }>()
  const { data: locales = [] } = useQuery(localesQueryOptions(companyId, gameId))

  useEffect(() => {
    if (!localeId && locales.length) {
      navigate(generatePath(toPath, { localeId: locales.at(0)?.id || '' }), { replace: true })
    }
  }, [navigate, localeId, generatePath, locales])
}
