import { AttrType, EventActionBody, StoreItemRead } from '@/api/dashboard'
import { Table, TableCell, TableRow } from '@/ui'
import { KeyValue } from '@/types'
import { findAttrById } from '../../expr/findAttr'
import { formatMoney } from '@/util'
import { Attribute, GameHubVisitStoreAttributes, SelectorValueType } from '@/layouts/campaigns/expr/types'
import { DEFAULT_LOAD_LIMIT, useGameItems } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { useSegmentsQuery } from '@/layouts/segment/api/useSegmentsQuery'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

export default function HistoryAttrView(props: { body: EventActionBody }) {
  const obj = {
    ...props.body.calc_attributes,
    ...props.body.campaign_attributes,
  } as KeyValue

  const { items } = useGameItems()

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const rows = Object.keys(obj)
    .filter(key => obj[key] !== null && key != 'datetime')
    .map(key => {
      return { id: key, value: obj[key] }
    })

  if (!rows.length) {
    return <></>
  }

  const renderGameItem = (id: string) => {
    let item = items.find(it => it.id == id)
    if (!item) {
      return null
    }
    return (
      <div className="flex gap-1">
        <SkuImageName item={item} />
      </div>
    )
  }

  const renderGameItems = (ids: string[]) => {
    return (
      <div className="flex flex-wrap gap-2">
        {ids.map(id => {
          let item = items.find(it => it.id == id)
          if (!item) {
            return null
          }
          return (
            <div className="flex gap-1">
              <SkuImageName item={item} />
            </div>
          )
        })}
      </div>
    )
  }

  const renderSegmentItems = (slugs: string[]) => {
    return (
      <div className="flex flex-wrap gap-2">
        {slugs.map(slug => {
          let segment = segments.find(it => it.slug == slug)
          if (!segment) {
            return null
          }
          return (
            <div className="flex gap-1">
              <SegmentBadge segment={segment} />
            </div>
          )
        })}
      </div>
    )
  }

  const renderValue = (value: string | string[], attr: Attribute | undefined) => {
    if (
      (attr?.selectorValueType == SelectorValueType.sku_no_price || attr?.selectorValueType == SelectorValueType.sku) &&
      value
    ) {
      if (Array.isArray(value)) {
        return renderGameItems(value as string[])
      }
      return renderGameItem(value)
    }

    if (attr?.selectorValueType == SelectorValueType.segment && value) {
      return renderSegmentItems(value as string[])
    }

    if (attr?.id == GameHubVisitStoreAttributes[0].id && Array.isArray(value)) {
      return renderGameItems((value as unknown as StoreItemRead[]).map(it => it.item_id))
    }

    if (value && Array.isArray(value)) {
      return value.join(', ')
    }

    return value || ''
  }

  return (
    <div className="mb-3 w-full overflow-hidden bg-fg-gray-secondary">
      <Table className="m-1 rounded-md border bg-fg-primary">
        {rows.map(row => {
          let attr = findAttrById(row.id)
          if (!attr) {
            return null
          }
          let value = row.value
          switch (attr?.type) {
            case AttrType.Date:
              value = (
                <div className="flex gap-1">
                  <DateTimeValue value={row.value as number} timeClassName="text-text-secondary" />
                </div>
              )
              break
            case AttrType.Boolean:
              value = row.value ? 'true' : 'false'
              break
            case AttrType.Number:
              if (attr.isMoney) {
                value = formatMoney(row.value as number) as string
              }
              break
          }
          return (
            <TableRow key={row.id}>
              <TableCell>{attr?.name || row.id}</TableCell>
              <TableCell className="ml-[35px]">{renderValue(value as string, attr)}</TableCell>
            </TableRow>
          )
        })}
      </Table>
    </div>
  )
}
