import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { URL_NEW } from '@/types'

/**
 *
 * @param companyId
 * @param gameId
 * @param id
 */
export const useStorePromotionQuery = (companyId: string, gameId: string, id: string) => {
  return useQuery({
    enabled: id != URL_NEW,
    queryKey: ['store-promotion', gameId, id],
    select: response => response.data,
    queryFn: () => dashboardClient.v1.getStorePromotion(companyId, gameId, id),
  })
}
