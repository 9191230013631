import {
  BenefitBonusQuantityData,
  BenefitCouponDetails,
  BenefitDiscountData,
  BenefitSourceType,
  BenefitType,
  Currency,
  CustomerCompanyRead,
  DashboardPaymentRead,
  ItemRead,
  OrderRead,
  OrderStatus,
  PaymentStatus,
} from '@/api/dashboard'
import { Link, generatePath, useParams } from 'react-router-dom'
import { SKU_ITEMS_DETAILS_PATH } from '../../../libs/routerPaths'
import { getItemName } from '../../../api/getItemName'
import { formatMoney } from '../../../util'
import PayCardView from '../PayCardView'
import { Badge, TooltipAlertCircle } from '@/ui'
import UserCountry from '../../player/components/UserCountry'
import { PropTable, Property } from '../components/PropTable'
import { KeyValue } from '../../../types'
import { RewardPointsPrice } from '../../game-items/components/RewardPointsPrice'
import { useGameSettingsQuery } from '../../../api/useGameSettingsQuery'
import { getDateTimeFormatParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Skeleton } from '@dashboard/ui'

interface Tax {
  amount: number
  display_amount: string
  included: boolean
  name: string
  prc: number
  visible: boolean
}

export const GeneralTransactionDetails = (props: {
  payments: DashboardPaymentRead[]
  sourcePayments: DashboardPaymentRead[]
  order: OrderRead | null
  item: ItemRead | null
  refundCostumer: CustomerCompanyRead | null
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    paymentNumber: string
  }
  const { t } = useTranslation()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)

  const DEFAULT_EMPTY_VALUE = '–/–'

  const { isStaff } = useCurrentUser()

  const order = props.order
  const p = props.payments[0]
  const isLoading = !p || !order || !props.item || !props.sourcePayments.length

  const getRefundCustomerName = () => {
    return props.refundCostumer?.customer?.email || t('transactions-table.refund.staff')
  }

  const getCountryBillingAddress = () => {
    for (let p of props.sourcePayments) {
      if (p.user_billing_address) {
        try {
          let address = JSON.parse(p.user_billing_address)
          for (let key in address) {
            if (key == 'Country' && address[key]) {
              return address[key]
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  const getBonusValue = () => {
    if (isLoading) {
      return <Skeleton />
    }

    let offer = order?.offers[0]
    if ((offer?.data as unknown as KeyValue)?.bonus_percent) {
      return (offer.data as unknown as KeyValue).bonus_percent + '%'
    }

    if (order.global_bonus) {
      return order.global_bonus.bonus_percent + '%'
    }

    const bonus = order.benefits.find(b => b.type == BenefitType.BonusQuantity)

    if (bonus) {
      return (bonus.data as BenefitBonusQuantityData).bonus_percent + '%'
    }

    return DEFAULT_EMPTY_VALUE
  }

  const getDiscountValue = () => {
    if (isLoading) {
      return <Skeleton />
    }

    if (p.currency == Currency.RP) {
      const d = p.user_local_price! - p.total!
      if (d > 0) {
        return <RewardPointsPrice price={-d} />
      }

      return DEFAULT_EMPTY_VALUE
    }

    let discount = DEFAULT_EMPTY_VALUE

    let transactionTotal = p.total_order_currency_billing
    if (transactionTotal == undefined) {
      transactionTotal = p.total
    }

    if (order && p.user_local_price && p.user_local_price != transactionTotal) {
      let d = p.user_local_price - transactionTotal!
      if (d > 0) {
        discount = '-' + formatMoney(d, order.currency, order.price_minor_unit)
      }
    }

    return discount
  }

  const getCouponInfo = () => {
    if (isLoading) {
      return <Skeleton />
    }

    let couponInfo = DEFAULT_EMPTY_VALUE
    const coupons = order?.benefits.filter(d => d.source.type == BenefitSourceType.Coupon)
    if (coupons) {
      couponInfo = coupons
        .map(it => {
          const code = (it.source.details as BenefitCouponDetails).code
          switch (it.type) {
            case BenefitType.BonusQuantity:
              let bonus = (it.data as BenefitBonusQuantityData).bonus_percent
              return `${code} +${bonus}%`
            case BenefitType.Discount:
              let discount = (it.data as BenefitDiscountData).discount_percent
              return `${code} -${discount}% Off`
          }
          return ''
        })
        .filter(Boolean)
        .join(',')
    }

    return couponInfo || DEFAULT_EMPTY_VALUE
  }

  const getUserBillingAddress = () => {
    if (isLoading) {
      return []
    }

    let user_billing_addressJson: KeyValue = {}

    props.sourcePayments.forEach(it => {
      if (it.user_billing_address) {
        try {
          let address = JSON.parse(it.user_billing_address)
          for (let key in address) {
            if (key == 'Country') {
              continue
            }
            if (address[key]) {
              user_billing_addressJson[key] = address[key]
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    })

    let arr = [] as Property[]
    for (let key in user_billing_addressJson) {
      if (user_billing_addressJson[key]) {
        arr.push({
          label: key + ':',
          value: user_billing_addressJson[key] as string,
        })
      }
    }
    return arr
  }

  const getBillingEmail = () => {
    if (isLoading) {
      return <Skeleton />
    }

    let billing_email = ''
    let p_billing_email = props.payments.find(it => it.billing_email)
    if (p_billing_email?.billing_email) {
      billing_email = p_billing_email.billing_email
    }

    return billing_email
  }

  const getRewardPoints = () => {
    if (isLoading) {
      return <Skeleton />
    }

    return order?.paylink_user_id &&
      order?.eligible_for_reward_points &&
      order?.status != OrderStatus.Canceled &&
      order?.status != OrderStatus.Reattempted ? (
      <RewardPointsPrice price={order?.eligible_for_reward_points} />
    ) : (
      DEFAULT_EMPTY_VALUE
    )
  }

  const getOrderStatus = () => {
    if (isLoading) {
      return <Skeleton />
    }

    const orderStatus =
      order?.status == OrderStatus.Paid ? (
        <Badge variant="green-tertiary" className="ml-3 capitalize">
          {OrderStatus.Paid}
        </Badge>
      ) : (
        <Badge variant="gray-secondary" className="ml-3 capitalize">
          {order?.status}
        </Badge>
      )

    return isStaff && order?.status ? (
      <>
        {p.order_id} {orderStatus}
      </>
    ) : (
      p.order_id
    )
  }

  let tableProps = [
    {
      label: t('transaction-details.item'),
      value: !isLoading ? (
        <Link to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: props.item!.id })}>
          {getItemName(props.item)}
        </Link>
      ) : (
        <Skeleton />
      ),
    },
    {
      label: t('transaction-details.receipt-number'),
      value: !isLoading ? p.payment_number : <Skeleton className="w-[100px]" />,
    },
    isStaff && {
      label: t('transaction-details.payment_id'),
      value: !isLoading ? p.payment_id : <Skeleton />,
      items: [
        {
          label: t('transaction-details.pay_id'),
          value: !isLoading ? p.ps_tx_id : <Skeleton />,
        },
      ],
    },
    {
      label: t('transaction-details.order_id'),
      value: getOrderStatus(),
    },
    {
      label: t('transaction-details.date'),
      value: !isLoading ? t('intl.DateTime', getDateTimeFormatParams(p.created_at!)) : <Skeleton />,
    },
    {
      label: t('transaction-details.payment-method'),
      value: !isLoading ? (
        <div className="inline-block">
          <PayCardView payment={p} />
        </div>
      ) : (
        <Skeleton />
      ),
    },
    { label: t('transaction-details.coupon-code'), value: getCouponInfo() },
    { label: t('transaction-details.discount'), value: getDiscountValue() },
    { label: t('transaction-details.bonus'), value: getBonusValue() },
    !isLoading &&
      p.currency != Currency.RP &&
      settings?.enable_reward_points && {
        label: t('transaction-details.eligible_for_reward_points'),
        value: getRewardPoints(),
      },
    {
      label: t('transaction-details.tax.billing-email'),
      value: getBillingEmail(),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          {t('transaction-details.order-country')}
          <TooltipAlertCircle message={t('transaction-details.order-country.tooltip')} />
        </div>
      ),
      value: !isLoading ? <UserCountry country={order.country} /> : <Skeleton />,
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <span>{t('transaction-details.payment-country')}</span>
          <TooltipAlertCircle message={t('transaction-details.payment-country.tooltip')} />
        </div>
      ),
      value: !isLoading ? <UserCountry country={p.country} /> : <Skeleton />,
    },
  ] as Property[]

  if (p?.status === PaymentStatus.Refunded) {
    tableProps.push(
      {
        label: t('transaction-details.refund-customer'),
        value: getRefundCustomerName(),
      },
      {
        label: t('transaction-details.refund-reason'),
        value: p?.refund_reason,
      },
    )
  }

  if (p && p.status === PaymentStatus.Failed && p.fail_reason_code) {
    tableProps.push({
      label: t('transaction-details.failed-reason'),
      value: t(`fail.code.${p.fail_reason_code}`, { ns: 'transaction-error-codes' }),
    })
    tableProps.push({
      label: t('transaction-details.failed-code'),
      value: p.fail_reason_code,
    })
  }

  let taxes = (p?.taxes || []) as Tax[]

  let taxProps = [
    {
      label: (
        <div className="flex items-center gap-1">
          <span>{t('transaction-details.tax.location')}</span>
          <TooltipAlertCircle message={t('transaction-details.tax.location.tooltip')} />
        </div>
      ),
      value: p ? <UserCountry country={getCountryBillingAddress()} /> : <Skeleton />,
    },
    taxes.length && {
      label: `${t('transaction-details.tax.amount')}${
        taxes[0].included ? ` ${t('transaction-details.tax.included')}` : ''
      }`,
      value: taxes[0] ? formatMoney(taxes[0].amount, p.currency, p.currency_minor_unit) : '',
    },
    {
      label: <div className="flex h-full items-start"> {t('transaction-details.tax.billing-address')} </div>,
      value: '',
      items: getUserBillingAddress(),
    },
    {
      label: t('transaction-details.tax.ip-address'),
      value: !isLoading ? order.ip_address || DEFAULT_EMPTY_VALUE : <Skeleton />,
    },
  ] as Property[]

  return (
    <div className="ph-no-capture">
      <div className="h-full rounded-xl border-2 border-border-secondary">
        <PropTable rows={tableProps} />
        <div
          className="bg-fg-secondary p-3 font-medium uppercase text-text-quarterary-hover"
          style={{
            fontSize: '10px',
            lineHeight: '10px',
          }}
        >
          {t('transaction-details.tax-details')}
        </div>
        <PropTable rows={taxProps} />
      </div>
    </div>
  )
}
