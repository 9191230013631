import { Badge } from '@/ui'
import { useTranslation } from 'react-i18next'
import { WebhookRedacted } from '@/api/dashboard'

export const WebhookStatusBadge = ({ webhook }: { webhook: WebhookRedacted }) => {
  const { t } = useTranslation()
  if (webhook.enabled) {
    return <Badge variant="green-tertiary">{t('webhook.enabled')}</Badge>
  }

  return <Badge variant="gray-tertiary">{t('webhook.disabled')}</Badge>
}
