import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { InfoCircle, useModal } from '@dashboard/ui'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  ToastSeverity,
  usePagination,
  useToast,
} from '@/ui'
import { formatMoney } from '@/util'
import { Payout } from '@/api/dashboard'
import { useCurrentUser } from '@/api/useCurrentUser'
import { dashboardClient } from '@/api'
import { PayoutsChart } from '../PayoutsChart'
import { getDateFormatParams, getFormatYearMonthParams } from '@/libs/dates'
import { PayoutStatus } from '@/layouts/payouts/types'
import { BalanceCard } from '@/layouts/dashboard'
import { usePayoutsQuery } from '../api'
import { InstantPayoutModal } from '../components'

export const PayoutsPage = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: payouts = [], isLoading, isError } = usePayoutsQuery(companyId, gameId)
  const { page, query, onPageChange, needShowPagination } = usePagination(20)

  const openInstantPayoutModal = useModal(props => <InstantPayoutModal {...props} />)

  const getFakeDisallowInstantPayout = () => {
    const lastInstantPayout = window.localStorage.getItem(`LAST_FAKE_INSTANT_PAYOUT_${companyId}_${gameId}`)
    if (lastInstantPayout) {
      const date = new Date(lastInstantPayout)
      return dayjs(date).isSame(dayjs(new Date()), 'day')
    }
    return false
  }

  const getStatus = (p: Payout) => {
    switch (p.payout_status) {
      case PayoutStatus.pending:
      case PayoutStatus.draft:
      case PayoutStatus.approved:
        return <Badge variant="gray-primary">{t('payouts.table.status.pending')}</Badge>
      case PayoutStatus.paid:
        return <Badge variant="green-primary">{t('payouts.table.status.completed')}</Badge>
    }
  }

  const getPeriod = (p: Payout) => {
    const d = new Date()

    const start = dayjs(new Date(p.start_date / 1000)).add(d.getTimezoneOffset(), 'minute')
    const end = dayjs(new Date(p.ending_date / 1000)).add(d.getTimezoneOffset(), 'minute')

    const startAt = start.toDate().getTime() / 1000
    const endAt = end.toDate().getTime() / 1000

    if (start.month() == end.month()) {
      return `${start.format('D')} - ${end.format('D')} ${t('intl.DateTime', getFormatYearMonthParams(endAt))}`
    }

    if (start.year() == end.year()) {
      return `${t('intl.DateTime', getDateFormatParams(startAt, 'long'))} - ${t('intl.DateTime', getDateFormatParams(endAt, 'long'))}`
    }

    return `${t('intl.DateTime', getDateFormatParams(startAt))} - ${t('intl.DateTime', getDateFormatParams(endAt))}`
  }

  const getPayoutDate = (p: Payout) => {
    const d = new Date()
    const end = dayjs(new Date(p.ending_date / 1000)).add(d.getTimezoneOffset(), 'minute')
    const endAt = end.toDate().getTime() / 1000
    return t('intl.DateTime', getDateFormatParams(endAt, 'long'))
  }

  const onClickInstantPayout = async () => {
    try {
      await dashboardClient.v1.applyInstantPayouts(companyId, gameId)
      window.localStorage.setItem(`LAST_FAKE_INSTANT_PAYOUT_${companyId}_${gameId}`, new Date().toISOString())
      openInstantPayoutModal()
    } catch (e) {
      toast({ message: t('SomethingWentWrong'), severity: ToastSeverity.error })
    }
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{t('sidebar.payouts')}</PageHeader>

      <div className="mb-5 flex gap-5">
        <BalanceCard
          instantPayout={
            isAdmin
              ? {
                  disabled: getFakeDisallowInstantPayout(),
                  onClick: () => onClickInstantPayout(),
                }
              : undefined
          }
        />
        <PayoutsChart />
      </div>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : payouts.length === 0 ? (
        <div className="grow">
          <TableZeroState
            title={t(isError ? 'service-unavailable' : 'payouts.empty-table.title')}
            message={t(isError ? 'SomethingWentWrong' : 'payouts.empty-table.desc')}
          />
        </div>
      ) : (
        <>
          <Table data-testid="payout-table">
            <TableRow variant="header">
              <TableCell width="30%">{t('payouts.table.amount')}</TableCell>
              <TableCell width="50%">{t('payouts.table.status')}</TableCell>
              <TableCell width="50%">{t('payouts.table.payout-date')}</TableCell>
              <TableCell width="40%">{t('payouts.table.period')}</TableCell>
            </TableRow>

            {payouts.map((payout, idx) => (
              <TableRow key={idx} className="h-[64px]">
                <TableCell width="30%">{formatMoney(payout.payout_amount)}</TableCell>
                <TableCell width="50%">{getStatus(payout)}</TableCell>
                <TableCell width="50%">{getPayoutDate(payout)}</TableCell>
                <TableCell width="40%">{getPeriod(payout)}</TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, payouts) ? (
            <Pagination
              hasNext={payouts.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={payouts.length}
            />
          ) : (
            <div className="border-t border-border-secondary" />
          )}
        </>
      )}

      <div className="mt-auto pt-6">
        <div className="flex items-start gap-3 rounded-md border border-border-secondary bg-fg-secondary px-3 py-2 font-[12px] leading-[14px] text-text-secondary">
          <InfoCircle size={14} />
          <div>
            <Trans
              i18nKey="payouts.callout.text"
              values={{ email: 'partners@aghanim.com' }}
              components={{
                a: <a href="mailto:partners@aghanim.com" target="_blank" />,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
