import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { StorePromotionCreate } from '@/api/dashboard'

interface StorePromotionCreateMutate {
  create: StorePromotionCreate
}

export const useStorePromotionCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: StorePromotionCreateMutate) =>
      dashboardClient.v1.createStorePromotion(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['store-promotions', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['store-promotions', gameId] })
    },
  })
}
