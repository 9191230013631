import { CSSProperties, forwardRef, useCallback, useState } from 'react'
import { Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import { Input, Skeleton, optimizeImageFile } from '@dashboard/ui'
import { fetchUploadImage } from '@/api/files'
import { S3Bucket } from '@/types'
import { PlaceholderContainer } from './PlaceholderContainer'
import { ImageSize, ValueContainer } from './ValueContainer'

interface ImageUploaderProps {
  accept: Accept
  value: string | null | undefined
  imageSize?: ImageSize
  recommendedSize?: [number, number]
  bucket?: S3Bucket
  style?: CSSProperties
  disabled?: boolean
  onChange: (value: string | null) => void
  onLoadingChanged?: (loading: boolean) => void
  showName?: boolean
}

export const ImageUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(function ImageUploader(
  {
    accept,
    value,
    disabled = false,
    imageSize,
    recommendedSize = [800, 600],
    onChange,
    bucket = S3Bucket.news,
    style,
    onLoadingChanged,
    showName,
  },
  ref,
) {
  const { t } = useTranslation()
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState(0)

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      if (onLoadingChanged) {
        onLoadingChanged(true)
      }

      setProgress(1)
      const file = await optimizeImageFile(acceptedFiles[0])
      setFile(file)

      try {
        const data = await fetchUploadImage(file, { bucket: bucket, onProgress: setProgress })
        onChange(data.get_url)
        setFile(null)
        setProgress(0)
      } catch (err) {
        setFile(null)
        setProgress(0)
      }

      if (onLoadingChanged) {
        onLoadingChanged(false)
      }
    },
    [onChange],
  )

  const onDelete = () => {
    onChange('')
  }

  return (
    <div style={style}>
      <div className="mb-1.5">
        <AnimatePresence>
          {file || value ? (
            <ValueContainer
              showName={showName}
              file={file ? file : { src: value || '' }}
              progress={progress}
              disabled={disabled}
              imageSize={imageSize}
              onDelete={onDelete}
            />
          ) : (
            <PlaceholderContainer
              accept={accept}
              recommendedSize={recommendedSize}
              disabled={disabled}
              onUpload={onUpload}
            />
          )}
        </AnimatePresence>
      </div>
      <Input
        ref={ref}
        placeholder={t('upload-input.placeholder')}
        disabled={disabled || !!progress}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
})

export const ImageUploaderSkeleton = () => (
  <div>
    <Skeleton className="mb-1.5 h-[140px] w-full" />
    <Skeleton className="h-9 w-full" />
  </div>
)
