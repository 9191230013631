import { ReactNode } from 'react'

import { Card } from '@dashboard/ui'
import ImageCellNoText from '@/components/ImageCellNoText'

interface EngagementItemCardProps {
  item: {
    name: string
    image_url?: string
    sku?: string
    description: string
  }
  extra?: ReactNode
  actions?: ReactNode
}

export const EngagementItemCard = ({
  item: { name, image_url, sku, description },
  extra,
  actions,
}: EngagementItemCardProps) => (
  <Card className="inline-flex max-w-full items-center justify-between gap-6 shadow-xs">
    <div className="inline-flex min-w-0 items-center gap-3">
      <div className="h-full max-h-12 shrink-0 rounded-md bg-fg-primary-alt px-3.5">
        <ImageCellNoText
          image_url={image_url}
          image_size="3rem"
          style={{
            objectPosition: 'center',
            objectFit: 'cover',
          }}
        />
      </div>

      <div className="flex min-w-0 grow flex-col overflow-hidden">
        <div className="inline-flex items-center gap-1.5">
          <span className="h-6 truncate text-base font-semibold text-text-secondary" title={name}>
            {name}
          </span>
          <span className="text-caption-sm font-normal text-text-tertiary">·</span>
          <span className="truncate text-caption-sm font-normal text-text-tertiary" title={sku}>
            {sku}
          </span>
        </div>
        <span className="truncate text-caption-sm font-normal text-text-tertiary" title={description}>
          {description}
        </span>
      </div>
    </div>

    {actions || extra}
  </Card>
)
