import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../../../../components/icons/IconBase'

export const EmptyOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        d="M9 1.5C10.1788 1.5 11.2745 1.82985 12.2256 2.40565C12.4878 2.56434 12.5147 2.92463 12.298 3.14131L11.9181 3.52125C11.75 3.68938 11.4879 3.71337 11.2798 3.59834C10.5838 3.21359 9.80781 3 9 3C6.18111 3 3.75 5.60081 3.75 9C3.75 9.70374 3.8542 10.3733 4.04448 10.9926C4.10145 11.178 4.05765 11.3817 3.92046 11.5189L3.50725 11.9321C3.26163 12.1777 2.84732 12.1034 2.72989 11.7765C2.41953 10.9124 2.25 9.97389 2.25 9C2.25 4.94335 5.19147 1.5 9 1.5Z"
        fill="#475569"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6761 4.94228C14.5578 4.73768 14.5835 4.47717 14.7506 4.31002L16.2803 2.78033C16.5732 2.48744 16.5732 2.01256 16.2803 1.71967C15.9874 1.42678 15.5126 1.42678 15.2197 1.71967L1.71967 15.2197C1.42678 15.5126 1.42678 15.9874 1.71967 16.2803C2.01256 16.5732 2.48744 16.5732 2.78033 16.2803L4.12954 14.9311C4.32508 14.7356 4.64106 14.7375 4.84974 14.9189C5.98413 15.9053 7.41718 16.5 9 16.5C12.8085 16.5 15.75 13.0566 15.75 9C15.75 7.51998 15.3585 6.12159 14.6761 4.94228ZM13.6738 6.25986C13.5392 5.96099 13.1503 5.91035 12.9185 6.14215L5.89989 13.1608C5.70433 13.3563 5.70326 13.6756 5.91621 13.8521C6.79224 14.5781 7.86467 15 9 15C11.8189 15 14.25 12.3992 14.25 9C14.25 8.00273 14.0407 7.07418 13.6738 6.25986Z"
        fill="#475569"
      />
    </IconBase>
  )
})
