import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CashbackRewardUpdate } from '@/api/dashboard'
import {
  SKU_CASHBACK_REWARDS_PATH,
  SkuCashbackRewardFormSkeleton,
  SkuCashbackRewardsForm,
  skuCashbackRewardQueryOpts,
  skuCashbackRewardUpdateMutation,
} from 'src/layouts/sku-cashback–rewards'
import { Drawer, useDrawerPageState } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'

export const SkuCashbackRewardsEditPage = () => {
  const navigate = useNavigate()
  const { generatePath, companyId, gameId, rewardId } = useGenerateCompanyPath<{ rewardId: string }>()
  const { data: reward, isLoading } = useQuery(skuCashbackRewardQueryOpts(companyId, gameId, rewardId))
  const { mutateAsync } = skuCashbackRewardUpdateMutation()

  const { open, onClose: onFormClose } = useDrawerPageState(() => {
    navigate(generatePath(SKU_CASHBACK_REWARDS_PATH))
  })

  const onFormSubmit = async (data: CashbackRewardUpdate) => {
    if (!reward) {
      return
    }
    await mutateAsync({
      rewardId: reward.id,
      data: {
        ...data,
        from_usd: data.from_usd ? data.from_usd * 100 : undefined,
        reward_points: data.reward_points,
      },
    })
    onFormClose()
  }

  return (
    <Drawer open={open}>
      {reward && !isLoading ? (
        <SkuCashbackRewardsForm reward={reward} onSubmit={onFormSubmit} onClose={onFormClose} />
      ) : (
        <SkuCashbackRewardFormSkeleton />
      )}
    </Drawer>
  )
}
