import { HTMLAttributes, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { useMergeRefs } from '@floating-ui/react';
import { cn } from '../../libs';

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Items to be displayed in the menu.
   */
  children: ReactNode;
  /**
   * Props for the motion ul element. Use this to animate the menu.
   * ! Use this props for custom classes or animations of menu entry.
   */
  motionProps?: HTMLMotionProps<'div'>;
  /**
   * Max height of the menu.
   * @default '45vh'
   */
  maxHeight?: string | number;
  /**
   * Footer element to be displayed at the bottom of the menu.
   */
  footer?: ReactNode;
}

export const MENU_ITEMS_OPTIMIZED_THRESHOLD = 25;

/**
 * Component for displaying a dropdown menu.
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ children, motionProps, maxHeight = '45vh', footer, ...rest }, ref) => {
    const menuRef = useRef<HTMLDivElement>();
    const referenceMergedRef = useMergeRefs([menuRef, ref]);
    const [initialMenuWidth, setInitialMenuWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
      if (menuRef.current) {
        setInitialMenuWidth(menuRef.current.getBoundingClientRect().width);
      }
    }, []);

    return (
      <div
        {...rest}
        ref={referenceMergedRef}
        className={cn('w-full will-change-transform', rest.className)}
        style={{ ...rest.style, width: initialMenuWidth }}
      >
        <motion.div
          {...motionProps}
          className={cn(
            'group/menu',
            'box-border w-full min-w-44 max-w-96 overflow-hidden rounded-xl border border-border-primary bg-fg-primary shadow-lg outline-none',
            motionProps?.className,
          )}
        >
          <OverlayScrollbarsComponent
            className="p-3"
            options={{ scrollbars: { autoHide: 'scroll' } }}
            style={{ maxHeight }}
            defer={true}
          >
            <ul role="listbox">{children}</ul>
          </OverlayScrollbarsComponent>
          {footer && (
            <div className="relative px-3 pb-3">
              <div
                className={cn(
                  'pointer-events-none absolute -top-4 left-0 right-0 h-4',
                  'bg-gradient-to-b from-transparent to-fg-primary',
                )}
              />
              {footer}
            </div>
          )}
        </motion.div>
      </div>
    );
  },
);
