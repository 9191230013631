import { useCallback } from 'react'
import { PathParam, generatePath as routerGeneratePath, useParams, useSearchParams } from 'react-router-dom'

export type GenerateCompanyPathFn = <Path extends string>(
  path: Path,
  params?: Omit<{ [key in PathParam<Path>]: string | null }, 'companyId' | 'gameId'>,
  saveSearch?: boolean,
) => string

/**
 * This hook helps to generate an url with pre-filled `companyId` and `gameId` parameters.
 */
export const useGenerateCompanyPath = <T>() => {
  const [searchParams] = useSearchParams()
  const allParams = useParams() as Record<'companyId' | 'gameId' | keyof T, string>

  const generatePath: GenerateCompanyPathFn = useCallback(
    <Path extends string>(
      path: Path,
      params?: Omit<
        {
          [key in PathParam<Path>]: string | null
        },
        'companyId' | 'gameId'
      >,
      saveSearch?: boolean,
    ) => {
      const resultPath = routerGeneratePath(path, {
        ...allParams,
        ...params,
      } as {
        [key in PathParam<Path>]: string | null
      })

      return [resultPath, saveSearch ? window.location.search : ''].filter(Boolean).join('')
    },
    [routerGeneratePath, searchParams, allParams],
  )
  return { generatePath, ...allParams }
}
