import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { DataGranularity, FilterType, ItemClaimedType } from '@/api/dashboard'

type Query = {
  /**
   * Tz
   * @default 0
   */
  tz?: number

  /** An enumeration. */
  item_type?: ItemClaimedType

  type?: FilterType

  limit?: number

  start_at?: number

  end_at?: number

  granularity?: DataGranularity
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useTopClaimedQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['top-claimed-items', gameId, query],
    queryFn: () => dashboardClient.v1.getTopClaimedItems(companyId, gameId, query),
    select: response => response.data,
  })
}
