import { Badge } from '@/ui'
import { Clock, CloseOutline, Coins } from '@/icons'
import { PaymentRead, PaymentStatus } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { SlashCircle } from '@/layouts/transaction/icons/SlashCircle'
import { AlertTriangleOutline } from '@dashboard/ui'
import { Check } from '@/layouts/transaction/icons/Check/Check'
import { DisputeOutline } from '@/layouts/transaction/icons/DisputeOutline'
import { EmptyOutline } from '@/layouts/transaction/icons/EmptyOutline'
import { ClockExpiredOutline } from '@/layouts/transaction/icons/ClockExpiredOutline'
import { CancelOutline } from '../icons/CancelOutline'
import { CoinsHandOutline } from '@/layouts/transaction/icons/CoinsHandOutline'

export const getStatusIcon = (payment: PaymentRead[]) => {
  let status = payment[0].status as PaymentStatus

  if (status == PaymentStatus.Created && payment.find(p => p.status != PaymentStatus.Created)) {
    return <Clock />
  }

  switch (status) {
    case PaymentStatus.Created:
      return <Coins />

    case PaymentStatus.Failed:
      return <AlertTriangleOutline />

    case PaymentStatus.Refunded:
      return <CoinsHandOutline />

    case PaymentStatus.Canceled:
      return <CloseOutline />

    case PaymentStatus.Rejected:
      return <SlashCircle />

    case PaymentStatus.Voided:
      return <EmptyOutline />

    case PaymentStatus.Dispute:
      return <DisputeOutline />

    case PaymentStatus.Done:
      return <Check />

    case PaymentStatus.Expired:
      return <ClockExpiredOutline />

    case PaymentStatus.Chargeback:
      return <CancelOutline />
  }

  return <></>
}

export const getStatusClassTextColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Rejected:
    case PaymentStatus.Failed:
    case PaymentStatus.Chargeback:
      return 'text-text-error-solid'
    case PaymentStatus.Done:
      return 'text-text-success-solid'
    case PaymentStatus.Dispute:
    case PaymentStatus.Refunded:
      return 'text-text-violet-solid'
    case PaymentStatus.Canceled:
    case PaymentStatus.Voided:
    case PaymentStatus.Expired:
      return 'text-text-gray-primary'
  }

  return 'text-text-gray-primary'
}

export const getStatusClassBackgroundColorAsText = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Rejected:
    case PaymentStatus.Failed:
    case PaymentStatus.Chargeback:
      return 'text-fg-error-secondary'
    case PaymentStatus.Done:
      return 'text-fg-success-secondary'
    case PaymentStatus.Dispute:
    case PaymentStatus.Refunded:
      return 'text-fg-violet-secondary'
    case PaymentStatus.Canceled:
    case PaymentStatus.Voided:
      return 'text-fg-gray-secondary'
    case PaymentStatus.Expired:
      return 'text-fg-gray-primary'
  }

  return 'text-fg-gray-secondary'
}

export default function PaymentStatusChip({ showText, payment }: { payment: PaymentRead[]; showText?: boolean }) {
  const { t } = useTranslation()
  let status = payment[0].status as PaymentStatus

  if (status == PaymentStatus.RefundRequested) {
    status = PaymentStatus.Done
  }

  let variant = 'gray-secondary'

  switch (status) {
    case PaymentStatus.Created:
      variant = 'gray-secondary'
      break
    case PaymentStatus.Chargeback:
    case PaymentStatus.Canceled:
    case PaymentStatus.Rejected:
    case PaymentStatus.Failed:
      variant = 'red-secondary'
      break
    case PaymentStatus.Done:
      variant = 'green-secondary'
      break
    case PaymentStatus.Dispute:
    case PaymentStatus.Refunded:
      variant = 'violet-secondary'
      break
    case PaymentStatus.Expired:
      variant = 'gray-primary'
      break
  }

  if (showText !== false) {
    return (
      // @ts-ignore
      <Badge variant={variant}>
        {t(`transactions-table.status.${status}`)}
        {getStatusIcon(payment)}
      </Badge>
    )
  }

  return (
    // @ts-ignore
    <Badge variant={variant} className="px-1">
      {getStatusIcon(payment)}
    </Badge>
  )
}
