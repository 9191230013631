import { ReactNode } from 'react'
import { useBanner, useStickyAttr } from '@/libs'
import { cn } from '@dashboard/ui'

interface PageHeaderProps {
  children: ReactNode
  variant?: 'default' | 'bordered'
  sticky?: boolean
  extra?: ReactNode
}

export const PageHeader = ({ children, variant = 'default', extra, sticky = false }: PageHeaderProps) => {
  const { ref } = useStickyAttr({ offsetY: 64 })
  const { getStickyTop } = useBanner()

  return (
    <div
      ref={ref}
      className={cn(
        'flex h-[60px] shrink-0 items-center justify-between',
        variant === 'default' && 'bg-fg-primary',
        variant === 'bordered' && 'border-b border-border-secondary bg-[rgba(255,255,255,0.9)] backdrop-blur',
        sticky && ['sticky left-0 z-20', '[&[is-sticky="true"]]:rounded-none', getStickyTop()],
      )}
    >
      <h1 className="flex shrink-0 items-center font-nohemi text-[24px] font-medium leading-6">{children}</h1>
      {extra && <div className="flex items-center gap-3">{extra}</div>}
    </div>
  )
}
