import { InputDateRangePreset } from '@dashboard/ui'
import i18next from 'i18next'

const getTodayWithoutTime = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

export const lastHoursRangePreset = (n: number, key: string): InputDateRangePreset => {
  const start = new Date()
  start.setHours(start.getHours() - n, 0, 0, 0)

  return {
    label: i18next.t(key),
    range: { from: start, to: new Date() },
    isHourly: true,
  }
}

export const todayRangePreset = (): InputDateRangePreset => {
  return {
    label: i18next.t('filter.today'),
    range: { from: getTodayWithoutTime(), to: new Date() },
  }
}

export const yesterdayRangePreset = () => {
  const yesterday = getTodayWithoutTime()
  yesterday.setDate(yesterday.getDate() - 1)

  const endAt = getTodayWithoutTime()
  endAt.setDate(endAt.getDate() - 1)
  endAt.setHours(23)
  endAt.setMinutes(59)
  endAt.setSeconds(59)
  return {
    label: i18next.t('filter.yesterday'),
    range: { from: yesterday, to: endAt },
  }
}

export const lastDaysRangePreset = (n: number, key: string) => {
  const start = getTodayWithoutTime()
  start.setDate(start.getDate() - n)
  return {
    label: i18next.t(key),
    range: { from: start, to: new Date() },
  }
}
