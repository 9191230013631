import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { DailyRewardType } from '@/api/dashboard'

export interface DailyRewardsQueryParams {
  /** Q */
  q?: string
  /** An enumeration. */
  type?: DailyRewardType
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 300
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

export const dailyRewardsQueryOptions = (companyID: string, gameID: string, params?: DailyRewardsQueryParams) =>
  queryOptions({
    queryKey: ['daily-rewards', companyID, gameID, params],
    queryFn: () => dashboardClient.v1.getDailyRewards(companyID, gameID, params).then(r => r.data),
    staleTime: 60000,
  })
