import { useCallback, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Callout, InfoCircle, cn } from '@dashboard/ui'
import { ENGAGEMENT_DAILY_REWARDS_PATH, useBanner } from '@/libs'
import { BackButton, PageHeader, TableRowSkeleton } from '@/ui'
import {
  DailyRewardsDays,
  DailyRewardsEnabler,
  DailyRewardsSettings,
  DailyRewardsSettingsForm,
  Item,
  RewardsConfiguration,
} from '@/layouts/engagement/widgets'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { useDailyReward } from '@/layouts/engagement/hooks/useDailyReward'
import { DailyRewardType } from '@/api/dashboard'
import { useCurrentUser } from '@/api/useCurrentUser'

export const DailyRewardsProgramPage = () => {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const navigate = useNavigate()
  const { companyId, gameId, programId } = useParams() as {
    companyId: string
    gameId: string
    programId: string
  }
  const { isLoading, data: campaign, isError } = useDailyReward(programId)
  const { getStickyTop } = useBanner()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(true)
  const [buttonsConfig, setButtonsConfig] = useState<RewardsConfiguration['buttons']>()
  const configure = useCallback(
    (cfg: RewardsConfiguration) => {
      setIsPreview(cfg.isPreview)
      setButtonsConfig(cfg.buttons)
    },
    [setIsPreview, setButtonsConfig],
  )
  const navigateBack = useCallback(
    () =>
      navigate(
        generatePath(ENGAGEMENT_DAILY_REWARDS_PATH, {
          companyId,
          gameId,
        }),
      ),
    [navigate, ENGAGEMENT_DAILY_REWARDS_PATH, companyId, gameId],
  )
  useEffect(() => {
    if ((!isLoading && !campaign) || isError) {
      navigateBack()
    }
  }, [isLoading, campaign, isError])

  return (
    <div className="flex size-full flex-col">
      <PageHeader
        sticky
        extra={
          canEdit &&
          (isPreview ? (
            <Button {...buttonsConfig?.edit} size="sm" disabled={buttonsConfig?.edit?.disabled}>
              {t('daily-rewards.rewards.edit')}
            </Button>
          ) : (
            <>
              <Button {...buttonsConfig?.cancel} variant="outline" color="secondary" size="sm">
                {t('daily-rewards.rewards.cancel')}
              </Button>
              <Button {...buttonsConfig?.submit} size="sm">
                {t('daily-rewards.rewards.save')}
              </Button>
            </>
          ))
        }
      >
        <BackButton text={campaign?.name || ''} onClick={() => navigateBack()} />
      </PageHeader>

      <div className="relative flex grow gap-6">
        <div className="w-2/3">
          {isLoading ? (
            <TableRowSkeleton columnCount={1} rowCount={3} />
          ) : (
            campaign && (
              <DailyRewardsDays
                interactive={canEdit}
                configure={configure}
                values={{
                  settingsID: campaign.id,
                  program: {
                    daily_rewards: campaign.rewards.daily_rewards.map(daily => ({
                      day_number: daily.day_number,
                      items: daily.items.map((item, index) => ({ ...item, ...daily.items_read[index] })),
                    })),
                    extra_reward_items: campaign.rewards.extra_reward_items?.map(
                      (item, index) =>
                        ({
                          ...item,
                          ...campaign.rewards.extra_reward_items_read?.[index],
                        }) as Item,
                    ),
                  },
                }}
              />
            )
          )}
        </div>

        <div className={cn(getStickyTop(), `sticky top-[60px] h-fit w-1/3 space-y-4`)}>
          {isLoading ? (
            <TableRowSkeleton columnCount={1} rowCount={1} />
          ) : (
            campaign && (
              <>
                {canEdit && (
                  <DailyRewardsEnabler
                    settingsID={campaign.id}
                    disabled={campaign.rewards.daily_rewards.length === 0}
                    state={campaign.enabled}
                  />
                )}
                <DailyRewardsSettings interactive={canEdit} campaign={campaign} onEditClick={() => setIsEdit(true)} />
                {campaign.type === DailyRewardType.Seasonal && (
                  <Callout variant="ai" icon={InfoCircle}>
                    {t('daily-rewards.program.type.seasonal.info')}
                  </Callout>
                )}
              </>
            )
          )}
        </div>
      </div>
      {campaign && canEdit && (
        <DrawerSidebar open={isEdit} width="720px" position="right">
          <DailyRewardsSettingsForm campaign={campaign} onClose={() => setIsEdit(false)} />
        </DrawerSidebar>
      )}
    </div>
  )
}
