import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CashbackRewardUpdate } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { skuCashbackRewardsQueryOpts } from './skuCashbackRewardsQueryOpts'
import { skuCashbackRewardQueryOpts } from './skuCashbackRewardQueryOpts'

/**
 *
 * @returns
 */
export const skuCashbackRewardUpdateMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ rewardId, data }: { rewardId: string; data: CashbackRewardUpdate }) => {
      const r = await dashboardClient.v1.updateCashbackReward(companyId, gameId, rewardId, data)
      return r.data
    },
    onSuccess: (_, variables) =>
      Promise.all([
        ql.refetchQueries({ queryKey: skuCashbackRewardsQueryOpts(companyId, gameId).queryKey }),
        ql.refetchQueries({ queryKey: skuCashbackRewardQueryOpts(companyId, gameId, variables.rewardId).queryKey }),
      ]),
  })
}
