import {
  Button,
  Card,
  FormErrorMessage,
  InputDateRange,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { FieldGroup, Input, Tab, Tabs, ToastSeverity, useToast } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { DailyRewardCreate, DailyRewardMode, DailyRewardType } from '@/api/dashboard'
import { useEffect, useMemo, useState } from 'react'
import { LexicalController } from '@/components/lexical'
import { ErrorMessage } from '@hookform/error-message'
import { useDailyRewardsCreate } from '@/layouts/engagement/hooks'
import { getErrorText } from '@/api'

const MAX_NAME_LENGTH = 64

const DefaultFormValues: Partial<DailyRewardCreate> = {
  type: DailyRewardType.Seasonal,
  mode: DailyRewardMode.Soft,
  rewards: {
    daily_rewards: [],
  },
}

export const DailyRewardsCreateModal = ({ ...rest }: ModalProps) => {
  const { t } = useTranslation()
  const showToast = useToast()
  const [editable, setEditable] = useState<{
    mode: boolean
    period: boolean
  }>()

  const {
    handleSubmit,
    register,
    unregister,
    formState: { errors, isDirty, isSubmitting },
    getValues,
    setValue,
    watch,
    control,
  } = useForm<DailyRewardCreate>({
    defaultValues: DefaultFormValues,
  })
  const submitDisabled = useMemo(() => !isDirty, [isDirty])

  const type = watch('type')
  const [startAt, endAt] = watch(['start_at', 'end_at'])
  const desc = useMemo(() => {
    switch (type) {
      case DailyRewardType.Basic:
        return { title: t('daily-rewards.program.type.basic'), subtitle: t('daily-rewards.program.type.basic.desc') }
      case DailyRewardType.Seasonal:
        return {
          title: t('daily-rewards.program.type.seasonal'),
          subtitle: t('daily-rewards.program.type.seasonal.desc'),
        }
    }
  }, [type])

  useEffect(() => {
    switch (type) {
      case DailyRewardType.Basic:
        setValue('mode', DailyRewardMode.Soft)
        unregister('start_at')
        unregister('end_at')
        setEditable(prev => ({ ...prev, mode: false, period: false }))
        break
      case DailyRewardType.Seasonal:
        register('start_at', {
          required: t('validation.required'),
        })
        register('end_at', {
          required: t('validation.required'),
          validate: value => {
            const start_at = getValues('start_at')
            if (!value || !start_at || value <= start_at) {
              return t('daily-rewards.settings.period.invalid')
            }
          },
        })
        setEditable(prev => ({ ...prev, mode: true, period: true }))
        break
    }
  }, [type])

  const { mutateAsync: create } = useDailyRewardsCreate()

  const onSubmit = handleSubmit(async data => {
    try {
      await create(data)
      rest.onClose?.()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <Modal {...rest} size="lg">
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle onClose={rest.onClose} subtitle={t('daily-rewards.program.create.desc')}>
            {t('daily-rewards.program.create.title')}
          </ModalTitle>

          <Tabs className="mb-2">
            <Tab
              isActive={type === DailyRewardType.Seasonal}
              onClick={() => setValue('type', DailyRewardType.Seasonal)}
            >
              {t('daily-rewards.program.type.seasonal')}
            </Tab>
            <Tab isActive={type === DailyRewardType.Basic} onClick={() => setValue('type', DailyRewardType.Basic)}>
              {t('daily-rewards.program.type.basic')}
            </Tab>
          </Tabs>
          {desc && (
            <Card color="brand" className="mb-3">
              <div className="flex flex-col gap-0.5 text-paragraph-xs">
                <span className="font-medium">{desc.title}:</span>
                <span>{desc.subtitle}</span>
              </div>
            </Card>
          )}

          {editable?.mode && (
            <FieldGroup size="sm">
              <Controller
                control={control}
                name="mode"
                render={({ field }) => (
                  <Select
                    {...field}
                    {...formatHookFormErrors(errors, 'mode')}
                    items={[
                      {
                        value: DailyRewardMode.Soft,
                        children: t('daily-rewards.settings.mode.soft'),
                        caption: t('daily-rewards.settings.mode.soft.description'),
                      },
                      {
                        value: DailyRewardMode.Hard,
                        children: t('daily-rewards.settings.mode.hard'),
                        caption: t('daily-rewards.settings.mode.hard.description'),
                      },
                    ]}
                  />
                )}
              />
            </FieldGroup>
          )}

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">{t('daily-rewards.settings.name')}</span>
            }
          >
            <Input
              size="sm"
              disabled={isSubmitting}
              errors={errors}
              {...register('name', {
                required: t('validation.required'),
                validate: value => (value && value.trim().length > 0) || t('validation.required'),
                maxLength: {
                  value: MAX_NAME_LENGTH,
                  message: t('daily-rewards.settings.name.validation', { max: MAX_NAME_LENGTH }),
                },
              })}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">
                {t('daily-rewards.settings.description')}
              </span>
            }
          >
            <LexicalController
              control={control}
              layout="compact"
              name="description"
              rules={{
                required: t('validation.required'),
              }}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          {editable?.period && (
            <FieldGroup label={t('daily-rewards.settings.period')}>
              <InputDateRange
                value={
                  startAt || endAt
                    ? {
                        from: startAt ? new Date((startAt as number) * 1000) : undefined,
                        to: endAt ? new Date((endAt as number) * 1000) : undefined,
                      }
                    : null
                }
                onChange={value => {
                  setValue('start_at', value?.from ? value.from.getTime() / 1000 : undefined, { shouldValidate: true })
                  setValue('end_at', value?.to ? value.to.getTime() / 1000 : undefined, { shouldValidate: true })
                }}
              />
              {(errors.start_at || errors.end_at) && (
                <FormErrorMessage>{errors.start_at?.message || errors.end_at?.message}</FormErrorMessage>
              )}
            </FieldGroup>
          )}
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" size="sm" onClick={() => rest.onClose?.()}>
            {t('Cancel')}
          </Button>
          <Button type="submit" color="primary" size="sm" disabled={submitDisabled} loading={isSubmitting}>
            {t('Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
