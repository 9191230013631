import { Outlet, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Skeleton, useSearchFilter } from '@dashboard/ui'
import { LogQuery, webhookDetailQuery, webhookLogQuery } from '../api'
import { WebhookSearchFilterToolbar, WebhookTitleBar, WebhookTitleBarSkeleton } from '../components'
import {
  WebhookBar,
  WebhookBarSkeleton,
  WebhookDiscoverCallout,
  WebhookLogsInspector,
  WebhookLogsInspectorSkeleton,
  WebhookLogsZeroState,
} from '../widgets'
import { WebhookSearchFilter } from '../types'
import { getPeriodParamsFromUrlRange } from '@/layouts/dashboard/util'

export const WebhookDetailPage = () => {
  const { companyId, gameId, webhookId } = useParams() as {
    companyId: string
    gameId: string
    webhookId: string
  }

  const filter = useSearchFilter<WebhookSearchFilter>()

  const getQuery = (): LogQuery => {
    return {
      limit: 100,
      offset: 0,
      ...(filter.values as LogQuery),
      ...getPeriodParamsFromUrlRange(filter.values.start_at, filter.values.end_at),
    }
  }

  const { data: webhook, isLoading } = useQuery(webhookDetailQuery(companyId, gameId, webhookId))
  const { data: logs = [], isLoading: isLoadingLogs } = useQuery(
    webhookLogQuery(companyId, gameId, webhookId, getQuery()),
  )

  if (isLoading || !webhook) {
    return (
      <>
        <WebhookTitleBarSkeleton />
        <div className="mb-3">
          <WebhookBarSkeleton />
        </div>
        <Skeleton className="mb-[18px] h-8 w-full" />
        <WebhookLogsInspectorSkeleton />
      </>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <Outlet />
      <WebhookTitleBar webhook={webhook} />
      <WebhookDiscoverCallout />

      <div className="mb-3">
        <WebhookBar webhook={webhook} query={getQuery()} />
      </div>

      <div className="mb-[18px]">
        <WebhookSearchFilterToolbar webhook={webhook} filter={filter} />
      </div>

      {isLoadingLogs ? (
        <WebhookLogsInspectorSkeleton />
      ) : logs.length ? (
        <WebhookLogsInspector items={logs} />
      ) : (
        <WebhookLogsZeroState
          webhook={webhook}
          hasFilter={Object.values(filter.values).some(value => !!value)}
          onClearFilters={() => {
            filter.onChange({})
          }}
        />
      )}
    </div>
  )
}
