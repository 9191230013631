import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getDateCategoryParams } from '@/libs/dates'
import { DataGranularity } from '@/api/dashboard'
import { formatNumber } from '@/util'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { couponStatQuery, couponTotalStatQuery } from '@/layouts/coupon/api/couponStatQuery'
import { TooltipAlertCircle } from '@/ui'
import { Spinner } from '@dashboard/ui'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

export const CouponStatChart = (props: { couponId: string }) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()

  const { data: data = [], isLoading: isChartLoading } = useQuery(couponStatQuery(companyId, gameId, props.couponId))
  const { data: totalData, isLoading } = useQuery(couponTotalStatQuery(companyId, gameId, [props.couponId]))
  const maxValue = Math.max(Math.max(...data.map(it => it.value || 0)), 10)

  const options = {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#fff',
      type: 'line',
      height: '110px',
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderColor: 'transparent',
      borderRadius: 6,
      padding: 10,
      style: {
        color: '#fff',
      },
      useHTML: true,
      headerFormat:
        '<div class="text-caption-md border-b pb-2 w-[100px]" style="border-color: rgba(255, 255, 255, 0.2)">{point.key}</div><br/>',
      pointFormat: `<div class="text-caption-sm mt-[-6px]">${t('count')}: {point.y}</div>`,
    },
    legend: {
      enabled: false,
    },
    xAxis: [
      {
        tickColor: '#F1F5F9',
        tickWidth: 1,
        tickLength: 4,
        categories: data.map(it => t('intl.DateTime', getDateCategoryParams(it.category, DataGranularity.Day))),
        labels: {
          y: 14,
          style: {
            fontSize: '8px',
            color: '#94A3B8',
            fontWeight: '400',
            fontFamily: 'InterAg',
          },
        },
        lineColor: '#94A3B8',
      },
    ],
    yAxis: {
      min: 0,
      max: Math.round(maxValue + 5),
      gridLineWidth: 1,
      gridLineColor: '#F1F5F9',
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '8px',
          color: '#94A3B8',
          fontWeight: '400',
          fontFamily: 'InterAg',
        },
      },
      lineWidth: 1,
      lineColor: 'transparent',
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        color: '#0EA5E9',
        marker: {
          enabled: false,
        },
        type: 'spline',
      },
    },
    series: [
      {
        type: 'spline',
        name: t('count'),
        data: data.map(it => it.value),
      },
    ],
  }

  return (
    <div className="mb-4 flex h-[120px] items-center gap-1.5 rounded-xl border border-border-primary p-1.5">
      <div className="flex w-[140px] flex-col justify-start gap-1.5 p-3" data-testid="coupon-stat/total-value">
        <div className="flex items-center gap-0.5 whitespace-nowrap text-caption-sm text-text-quarterary-hover">
          {t('coupon.redeem-dynamics')}
          <TooltipAlertCircle message={t('coupon.update-info')} />
        </div>
        <div className="text-caption-md font-semibold text-text-secondary">
          {isLoading ? <AnimatedDots /> : totalData?.length ? formatNumber(totalData[0].value!) : 0}
        </div>
      </div>

      {isChartLoading ? (
        <div className="flex grow justify-center text-text-disabled">
          <Spinner size={16} />
        </div>
      ) : (
        <div data-testid="coupon-stat/chart" className="grow">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </div>
  )
}
