import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Calendar2Solid: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4C19.0398 4 20.723 5.52684 20.9691 7.5H21V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5H3.03095C3.277 5.52684 4.96019 4 7 4V3C7 2.44772 7.44772 2 8 2ZM7 6C5.89543 6 5 6.89543 5 8V9C5 9.55228 5.44772 10 6 10H18C18.5523 10 19 9.55228 19 9V8C19 6.89543 18.1046 6 17 6V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM14.6 15.5C13.9925 15.5 13.5 15.9925 13.5 16.6V17.4C13.5 18.0075 13.9925 18.5 14.6 18.5H16.4C17.0075 18.5 17.5 18.0075 17.5 17.4V16.6C17.5 15.9925 17.0075 15.5 16.4 15.5H14.6Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
