import { PeriodType, RotationType, StoreRead, StoreType, WebhookEventType } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { useStoreCreateMutate, useStoreUpdateMutate } from './api'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { ResponseError, getErrorText } from '@/api'
import { STORE_ITEMS_PATH } from '@/libs'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '@/Settings'
import { ErrorMessage } from '@hookform/error-message'
import { useQuery } from '@tanstack/react-query'
import { webhooksQuery } from '@/layouts/webhooks'
import { useStoreQuery } from '@/layouts/store/api/useStoreQuery'

interface EditStoreDialogProps extends ModalProps {
  item: StoreRead
}

export default function EditStoreDialog(props: EditStoreDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: store } = useStoreQuery(companyId, gameId, props.item.id)
  const { data: webhooks = [] } = useQuery(webhooksQuery(companyId, gameId))
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useStoreCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useStoreUpdateMutate(companyId, gameId)

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<StoreRead>({
    values: { ...props.item, ...store },
  })

  const type = watch('type')

  const saveClick = async (data: StoreRead) => {
    if (data.type == StoreType.Rotation) {
      if (!data.rotation_settings) {
        data.rotation_settings = {
          count: 3,
          type: RotationType.Random,
          period_type: PeriodType.Day,
          period_value: 1,
        }
      }
    } else {
      data.rotation_settings = null as unknown as undefined
    }

    delete data.limited_stock_settings

    try {
      if (props.item.id) {
        await updateMutateAsync({
          id: props.item.id,
          update: data,
        })
      } else {
        let created = await createMutateAsync({
          create: data,
        })

        if (data.type == StoreType.Rotation || data.type == StoreType.LimitedStock) {
          navigate(generatePath(STORE_ITEMS_PATH, { storeId: created.data.id, companyId, gameId }), {
            state: { openSettings: true },
          })
        }
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }

    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(saveClick)}>
        <ModalContent>
          <ModalTitle>{t(props.item.id ? 'store.edit-store' : 'store.create-store')}</ModalTitle>
          <FieldGroup label={t('store.name')}>
            <Input
              autoFocus
              {...register('name', {
                required: t('validation.required'),
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('store.name'),
                    value: MAX_NAME_LENGTH,
                  }),
                  value: MAX_NAME_LENGTH,
                },
              })}
              {...formatHookFormErrors(errors, 'name')}
            />
          </FieldGroup>

          <FieldGroup label={t('store.description')}>
            <Input
              maxLength={MAX_DESCRIPTION_LENGTH}
              {...register('description', {
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('store.description'),
                    value: MAX_DESCRIPTION_LENGTH,
                  }),
                  value: MAX_DESCRIPTION_LENGTH,
                },
              })}
              {...formatHookFormErrors(errors, 'description')}
            />
          </FieldGroup>

          <FieldGroup label={t('store.type')}>
            <Controller
              control={control}
              name="type"
              disabled={!!props.item.id}
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'type')}
                  items={Object.values(StoreType).map(it => ({
                    value: it,
                    children: t(`store.type.${it}`),
                    caption: t(`store.type.${it}.desc`),
                  }))}
                />
              )}
            />
          </FieldGroup>

          {type == StoreType.Webhook && (
            <FieldGroup label={t('store.type.webhook')}>
              <Controller
                control={control}
                name="settings.webhook_settings.webhook_id"
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Select
                    {...field}
                    items={webhooks
                      .filter(it => it.events.includes(WebhookEventType.StoreGet))
                      .map(it => ({
                        value: it.id,
                        children: it.url,
                      }))}
                  />
                )}
              />
              <ErrorMessage
                name="settings.webhook_settings.webhook_id"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>
          )}

          {errorMessage && <FormErrorMessage> {errorMessage} </FormErrorMessage>}
        </ModalContent>

        <ModalFooter>
          <Button size="sm" onClick={props.onClose} color="secondary" variant="outline" type="button">
            {t('Cancel')}
          </Button>
          <Button size="sm" color="primary" type="submit">
            {t(props.item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
