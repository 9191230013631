import { useTranslation } from 'react-i18next'
import { FormSection } from '../../components'
import { Badge, Button, ButtonIcon, Input, ModalProps, TableZeroState, Toggle } from '@/ui'

import { DotsGridSix, Plus, Trash } from '@/icons'
import { Controller, useFormContext } from 'react-hook-form'
import { SkuFormData } from '@/layouts/game-items/widgets/SkuForm/SkuForm'
import { ErrorMessage } from '@hookform/error-message'
import { SelectItemPropertyModal } from '@/layouts/components/SelectItemPropertyModal'
import { ItemPropertyConfigRange, ItemPropertyRelationRead } from '@/api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { ItemPropertyBadge } from '@/layouts/sku-settings/components/ItemPropertyBadge'
import { ReactSortable } from 'react-sortablejs'
import { ZeroItemProperties } from '@/layouts/game-items/icons/ZeroItemProperties'
import { Card, Clock, FormErrorMessage, useModal } from '@dashboard/ui'

export const ItemPropertiesEditor = (props: {
  children?: React.ReactNode
  onChange: (value: ItemPropertyRelationRead[]) => void
  value: ItemPropertyRelationRead[]
  duration: {
    value?: number
    onChange: (value: number) => void
  }
  item_id: string
}) => {
  const { t } = useTranslation()

  const { value, onChange, duration } = props

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectItemPropertyModal
      {...rest}
      selected={value.map(it => it.item_property)}
      onSubmit={items => {
        onChange(
          items.map((it, idx) => {
            const addItem = value.find(v => v.property_id == it.id)

            return (
              addItem || {
                id: '',
                property_id: it.id,
                position: idx,
                item_id: props.item_id,
                item_property: it,
                data: {
                  value: undefined,
                },
              }
            )
          }),
        )
        rest?.onClose?.()
      }}
    />
  ))

  const onChangeValue = (item: ItemPropertyRelationRead, v: string | number | null) => {
    onChange(
      value.map(it =>
        item.property_id == it.property_id
          ? {
              ...it,
              data: {
                value: v as string,
              },
            }
          : it,
      ),
    )
  }

  const renderDuration = () => (
    <Card>
      <div className="inline-flex w-full items-center justify-between">
        <div className="inline-flex items-center gap-3">
          <Toggle checked={!!duration.value} onChange={e => duration.onChange(e.target.checked ? 60 : 0)} />
          <div className="flex flex-col gap-0.5">
            <span className="text-caption-md">{t('item-props.sku.properties.duration')}</span>
            <span className="text-paragraph-xs text-text-tertiary">{t('item-props.sku.properties.duration.desc')}</span>
          </div>
        </div>
        {!!duration.value && (
          <div>
            <Input
              size="sm"
              value={duration.value}
              onChange={e => duration.onChange(parseInt(e.target.value))}
              extraRight={
                <Badge variant="brand-blue-tertiary">
                  <Clock size={14} />
                  <span>{t('seconds')}</span>
                </Badge>
              }
            />
          </div>
        )}
      </div>
    </Card>
  )

  const renderEditor = (item: ItemPropertyRelationRead, onChange: (value: string | number | null) => void) => {
    switch (item.item_property.config.property_type) {
      case 'string':
        return (
          <Input
            size="sm"
            value={item.data?.value}
            onChange={e => onChange(e.target.value)}
            extraRight={<ItemPropertyBadge itemProperty={item.item_property} />}
          />
        )
      case 'number':
        return (
          <Input
            size="sm"
            type="number"
            value={item.data?.value}
            onChange={e => onChange(e.target.value ? parseFloat(e.target.value) : null)}
            extraRight={<ItemPropertyBadge itemProperty={item.item_property} />}
          />
        )
      case 'range':
        const config = item.item_property.config as ItemPropertyConfigRange
        return (
          <Input
            size="sm"
            value={item.data?.value}
            type="number"
            min={config.min}
            max={config.max}
            onChange={e => onChange(e.target.value ? parseFloat(e.target.value) : null)}
            extraRight={<ItemPropertyBadge itemProperty={item.item_property} />}
          />
        )
    }
  }

  const renderItem = (item: ItemPropertyRelationRead) => {
    return (
      <div className="flex h-[50px] items-center gap-3 border-b border-border-secondary" key={item.property_id}>
        <div className="min-w-[32px]">
          <DotsGridSix className="cursor-move" />
        </div>
        <div className="flex grow items-center gap-3 overflow-hidden">
          <div
            className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${item.item_property.icon_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
            }}
          />
          <div className="overflow-hidden">
            <div className="mb-0.5 truncate leading-5">{item.item_property.name}</div>
          </div>
        </div>

        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="non-drag ml-auto mr-3 flex items-center gap-4">
          <div className="w-[280px]">{renderEditor(item, v => onChangeValue(item, v))}</div>
          <ButtonIcon
            variant="tertiary-destructive"
            onClick={() => onChange(value.filter(it => it.item_property.id !== item.item_property.id))}
          >
            <Trash />
          </ButtonIcon>
        </div>
      </div>
    )
  }

  if (!value.length) {
    return (
      <FormSection label={t('item-props.sku.properties')}>
        <div className="mb-6">{renderDuration()}</div>
        <TableZeroState
          image={<ZeroItemProperties />}
          title={t('item-props.zero-state.title')}
          message={t('item-props.sku.zero-state')}
          buttons={
            <Button
              size="sm"
              variant="primary"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                openPicker()
              }}
            >
              <Plus size={14} />
              <span>{t('item-props.add')}</span>
            </Button>
          }
        />
      </FormSection>
    )
  }

  return (
    <FormSection
      label={t('item-props.sku.properties')}
      action={
        value.length > 0 && (
          <Button
            variant="text-brand"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              openPicker()
            }}
          >
            <Plus size={14} />
            {t('item-props.add')}
          </Button>
        )
      }
    >
      <div className="mb-6">{renderDuration()}</div>
      <ReactSortable
        dragClass="cursor-move"
        filter=".non-drag"
        animation={200}
        delay={2}
        preventOnFilter={false}
        list={value}
        setList={v => {
          let changed = false
          for (let i = 0; i < v.length; i++) {
            if (v[i].item_property.id != value[i].item_property.id) {
              changed = true
              break
            }
          }
          if (changed) {
            onChange(
              v.map((it, idx) => ({
                ...it,
                position: idx,
              })),
            )
          }
        }}
      >
        {value.map(it => renderItem(it))}
      </ReactSortable>
      {props.children}
    </FormSection>
  )
}

export const SkuFormItemProperties = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<SkuFormData>()
  const id = watch('id')
  const [duration, setDuration] = [
    watch('duration'),
    (dur: number) => setValue('duration', dur, { shouldDirty: true }), // @ts-ignore
  ]

  return (
    <Controller
      control={control}
      name="properties"
      render={({ field }) => (
        <ItemPropertiesEditor
          item_id={id}
          value={field.value || []}
          onChange={items => field.onChange(items.length ? items : null)}
          duration={{
            value: duration,
            onChange: setDuration,
          }}
        >
          <ErrorMessage
            name="properties"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </ItemPropertiesEditor>
      )}
    />
  )
}
