import { useCallback } from 'react'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'
import { CashbackRewardRead } from '@/api/dashboard'
import { skuCashbackRewardDeleteMutation } from '../api'

interface SkuCashbackRewardsModalConfirmDeleteProps extends ModalProps {
  reward: CashbackRewardRead
}

export const SkuCashbackRewardsModalConfirmDelete = ({
  reward,
  ...rest
}: SkuCashbackRewardsModalConfirmDeleteProps) => {
  const { mutateAsync } = skuCashbackRewardDeleteMutation()

  const onClickConfirm = useCallback(async () => {
    await mutateAsync({ rewardId: reward.id })
    rest?.onClose(undefined, { closeAll: true })
  }, [reward.id, mutateAsync, rest])

  return (
    <ModalPresetConfirm
      {...rest}
      title={{ children: 'Confirm the action', subtitle: `Are you sure you want to remove "${reward.item.name}"?` }}
      cancel={{ label: 'Cancel' }}
      submit={{ label: 'Delete', color: 'danger', callback: onClickConfirm }}
    />
  )
}
