import { Button, Popper, Skeleton, cn } from '@dashboard/ui'
import { TranslationGlossaryRead } from '@/api/dashboard'
import { DotsHorizontal } from '@/icons'
import { L10nGlossaryItemMenu } from './L10nGlossaryItemMenu'

interface L10nGlossaryItemRowProps {
  item: TranslationGlossaryRead
}

export const L10nGlossaryItemRow = ({ item }: L10nGlossaryItemRowProps) => (
  <li
    className={cn(
      'group/item-row',
      'relative grid grid-cols-[1fr_1fr_auto] content-center items-center border-b border-border-secondary px-4 py-2 text-paragraph-sm text-text-secondary transition-colors',
      'focus-visible:border-transparent',
      'focus-visible:ring-border-brand',
      'focus-visible:ring-2',
      'focus-visible:rounded-md',
      'focus-visible:z-10',
      'hover:bg-fg-primary-hover',
    )}
    tabIndex={0}
    role="option"
  >
    <div>{item.term}</div>
    <div>{item.translation}</div>
    <div
      className={cn(
        'invisible flex h-full items-center gap-1.5 opacity-0 transition-all',
        'group-hover/item-row:visible',
        'group-hover/item-row:opacity-100',
        'group-has-[[data-open="true"]]/item-row:visible',
        'group-has-[[data-open="true"]]/item-row:opacity-100',
      )}
    >
      <Popper popper={props => <L10nGlossaryItemMenu {...props} item={item} />} params={{ placement: 'bottom-end' }}>
        <Button className="w-7 px-0" size="sm" variant="outline" color="secondary">
          <DotsHorizontal size={14} />
        </Button>
      </Popper>
    </div>
  </li>
)

export const GlossaryItemRowSkeleton = () => (
  <li className="grid grid-cols-[1fr_1fr_auto] content-center items-center border-b border-border-secondary px-4 py-2 text-paragraph-sm text-text-secondary transition-colors">
    <Skeleton className="h-5 w-1/2" />
    <Skeleton className="h-5 w-1/2" />
    <Skeleton className="size-7" />
  </li>
)
