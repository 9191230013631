import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 * @param user_id
 */
export const usePlayerStoreSettings = (companyId: string, gameId: string, user_id: string) => {
  return useQuery({
    queryKey: ['user-store-settings', gameId, user_id],
    queryFn: () => dashboardClient.v1.getUserPromotions(user_id, companyId, gameId),
    select: response => response.data,
  })
}
