import { FieldErrors, FieldValues, UseFormProps, UseFormSetError, useForm } from 'react-hook-form'
import { ResponseError } from '@/api'
import { useCallback } from 'react'

export function applyFormErrorsFromPydenticError<T extends FieldValues>(
  setError: UseFormSetError<T>,
  errors: FieldErrors<T>,
  paylod: T,
  response: ResponseError | unknown,
) {
  let err = response as ResponseError

  if (!err.error || !err.error.detail) {
    return
  }

  for (let error of err.error.detail) {
    if (error.loc && error.loc[0] == 'body') {
      error.loc.shift()
    }

    let loc = error.loc
    if (!loc || loc.length == 0) {
      continue
    }

    if (paylod[loc[0]] != undefined && !errors[loc[0]]) {
      // @ts-ignore
      setError(loc.join('.'), { message: error.msg })
    }
  }
}

export function usePydenticForm<T extends FieldValues = FieldValues, TContext = object>(
  props?: UseFormProps<T, TContext>,
) {
  const methods = useForm<T, TContext>(props)

  const customHandleSubmit = useCallback(
    (callback: (data: T) => void) => {
      const onSubmit = async (data: T) => {
        try {
          await callback(data)
        } catch (e) {
          applyFormErrorsFromPydenticError(methods.setError, methods.formState.errors, data, e)
        }
      }

      return methods.handleSubmit(onSubmit)
    },
    [methods.handleSubmit, methods.setError, methods.formState.errors],
  )

  return { ...methods, handleSubmit: customHandleSubmit }
}
