import { QueryClient, queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { localesQueryOptions } from './localesQueryOptions'

/**
 *
 * @param companyId
 * @param gameId
 * @param localeId
 */
export const localeQueryOptions = (companyId: string, gameId: string, localeId: string) =>
  queryOptions({
    queryKey: [gameId, 'locales', localeId],
    queryFn: () => dashboardClient.v1.getLocale(companyId, gameId, localeId).then(r => r.data),
  })

/**
 * Function to get the placeholder data for the locale query.
 * @param ql
 * @param companyId
 * @param gameId
 * @param localeId
 */
export const localeQueryPlaceholderData = (ql: QueryClient, companyId: string, gameId: string, localeId: string) =>
  ql.getQueryData(localesQueryOptions(companyId, gameId).queryKey)?.find(l => l.id === localeId)
