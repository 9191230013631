import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LocaleRead } from '@/api/dashboard'
import { ModalBaseProps, ModalPresetConfirm } from '@dashboard/ui'
import { useGenerateCompanyPath } from '@/libs'
import { localeDeleteMutation } from '../api'

interface L10nLocaleModalDeleteProps extends ModalBaseProps {
  locale: LocaleRead
  /**
   * Redirect to this path after deletion.
   */
  redirectTo: string
}

export const L10nLocaleModalDelete = ({ locale, redirectTo, ...rest }: L10nLocaleModalDeleteProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { localeId, generatePath } = useGenerateCompanyPath<{ localeId: string }>()
  const { mutateAsync } = localeDeleteMutation()

  const onConfirm = async () => {
    await mutateAsync({ localeId: locale.id })
    if (localeId === locale.id) {
      navigate(generatePath(redirectTo))
    } else {
      rest?.onClose()
    }
  }

  return (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('localization.locale.delete-modal.title'),
        subtitle: t('localization.locale.delete-modal.text'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: onConfirm,
        label: t('localization.locale.delete-modal.confirm'),
      }}
    />
  )
}
