import { useTranslation } from 'react-i18next'

import { DailyRewardMode, DailyRewardRead, DailyRewardType } from '@/api/dashboard'
import { SettingsSwitch } from '@/icons'
import { Button, FieldGroup, TooltipAlertCircle } from '@/ui'
import { EngagementSummaryCard } from '@/layouts/engagement/component'
import { getDateTimeFormatParams } from '@/libs/dates'

interface DailyRewardsSettingsProps {
  interactive?: boolean
  campaign: DailyRewardRead
  onEditClick: () => void
}

export const DailyRewardsSettings = ({ interactive = false, campaign, onEditClick }: DailyRewardsSettingsProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <EngagementSummaryCard
        title={{ text: t('daily-rewards.summary.title') }}
        action={
          interactive && (
            <Button onClick={onEditClick}>
              <div className="inline-flex items-center gap-2 text-text-primary">
                <SettingsSwitch />
                <span>{t('daily-rewards.summary.edit')}</span>
              </div>
            </Button>
          )
        }
        fields={[
          {
            title: { text: t('daily-rewards.summary.name') },
            content: <FieldGroup size="sm">{campaign.name}</FieldGroup>,
          },
          {
            title: { text: t('daily-rewards.summary.description') },
            content: (
              <FieldGroup size="sm">
                <div
                  dangerouslySetInnerHTML={{ __html: campaign.description }}
                  className="max-h-[400px] overflow-auto"
                />
              </FieldGroup>
            ),
          },
        ]}
        settings={[
          {
            name: t('daily-rewards.summary.type'),
            value:
              campaign.type === DailyRewardType.Basic
                ? t('daily-rewards.summary.type.basic')
                : t('daily-rewards.summary.type.seasonal'),
          },
          {
            name: t('daily-rewards.summary.mode'),
            value: (
              <div className="inline-flex justify-center gap-1.5">
                {campaign.mode === DailyRewardMode.Soft && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.soft.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.soft')}</span>
                  </>
                )}
                {campaign.mode === DailyRewardMode.Hard && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.hard.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.hard')}</span>
                  </>
                )}
              </div>
            ),
          },
          campaign.type === DailyRewardType.Seasonal && {
            name: t('daily-rewards.summary.start_at'),
            value: t('intl.DateTime', getDateTimeFormatParams(campaign.start_at!)),
          },
          campaign.type === DailyRewardType.Seasonal && {
            name: t('daily-rewards.summary.end_at'),
            value: t('intl.DateTime', getDateTimeFormatParams(campaign.end_at!)),
          },
          {
            name: t('daily-rewards.summary.repeatable'),
            value:
              campaign.type === DailyRewardType.Basic
                ? t('daily-rewards.summary.repeatable.every-period')
                : t('daily-rewards.summary.repeatable.one-time'),
          },
        ].filter(setting => !!setting)}
      />
    </div>
  )
}
