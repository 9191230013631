import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { skuCashbackRewardsQueryOpts } from './skuCashbackRewardsQueryOpts'

/**
 *
 * @returns
 */
export const skuCashbackRewardDeleteMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ rewardId }: { rewardId: string }) => {
      const r = await dashboardClient.v1.deleteCashbackReward(companyId, gameId, rewardId)
      return r.data
    },
    onSuccess: (_, variables) => {
      ql.setQueryData(skuCashbackRewardsQueryOpts(companyId, gameId).queryKey, (old = []) => {
        return old.filter(r => r.id !== variables.rewardId)
      })
    },
  })
}
