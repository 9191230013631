import { forwardRef } from 'react'
import { ModalProps, Table, TableCell, TableRow, TableRowSkeleton } from '@/ui'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { Button, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { useGameItemsQuery } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'

interface SelectSkuTableProps {
  value: string[]
  onChange: (value: string[]) => void
}

export const SelectSkuTable = forwardRef<HTMLDivElement, SelectSkuTableProps>(({ value, onChange }, ref) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: items = [], isLoading } = useGameItemsQuery(companyId, gameId, {
    ids: value.join(','),
  })

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      title={t('select-items')}
      picked={items.filter(it => value.includes(it.id))}
      onSubmit={items => {
        onChange(items.map(it => it.id))
        rest?.onClose?.()
      }}
    />
  ))

  return (
    <div ref={ref} className="flex flex-col gap-1.5">
      {isLoading && <TableRowSkeleton rowCount={4} columnCount={1} />}

      {value?.length > 0 && (
        <div className="max-h-[300px] overflow-auto rounded-md border border-border-secondary">
          <Table>
            {items.map(it => {
              return (
                <TableRow key={it.id}>
                  <TableCell className="flex items-center gap-3" width="100%">
                    <SkuImageName item={it} />
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
      )}

      {!isLoading && (
        <div className="flex justify-start">
          <Button variant="outline" onClick={() => openPicker()}>
            {t('select-items')}
          </Button>
        </div>
      )}
    </div>
  )
})
