import { ReactNode, useCallback } from 'react';
import { Button, ButtonProps } from '../Button';
import { Modal, ModalProps } from './Modal';
import { ModalTitle, ModalTitleProps } from './ModalTitle';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';

interface ModalPresetConfirmProps extends ModalProps {
  title: Omit<ModalTitleProps, 'onClose'>;
  cancel?: {
    color?: ButtonProps['color'];
    callback?: () => void;
    label: ReactNode;
  };
  submit?: {
    color?: ButtonProps['color'];
    callback: () => void;
    label: ReactNode;
  };
}

export const ModalPresetConfirm = ({
  children,
  title,
  size = 'sm',
  cancel,
  submit,
  onClose,
  ...rest
}: ModalPresetConfirmProps) => {
  const computedOnClose = useCallback(() => {
    cancel?.callback?.();
    onClose();
  }, [cancel, onClose]);

  return (
    <Modal {...rest} size={size} onClose={computedOnClose}>
      <ModalContent>
        <ModalTitle {...title} />
        {children}
      </ModalContent>
      <ModalFooter>
        {cancel && (
          <Button variant="outline" color={cancel.color || 'secondary'} size="sm" onClick={computedOnClose}>
            {cancel.label}
          </Button>
        )}
        {submit && (
          <Button color={submit.color || 'danger'} size="sm" autoFocus={true} onClick={submit.callback}>
            {submit.label}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
