import { ReactNode, createContext, useContext } from 'react';
import { i18n as originali18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { focusGroupKeyUX, hotkeyKeyUX, jumpKeyUX, pressKeyUX, startKeyUX } from 'keyux';
import { ModalProvider } from '../ModalProvider';
import '../../assets/css/overlayscrollbars.css';

interface UIProviderProps {
  children: ReactNode;
  i18n: originali18n;
  staticImagesPath: string;
}

interface UIContextValue {
  locale: string;
  staticImagesPath: string;
}

startKeyUX(window, [hotkeyKeyUX([]), focusGroupKeyUX(), pressKeyUX('is-pressed'), jumpKeyUX()]);

const UIContext = createContext<UIContextValue>({
  locale: 'en',
  staticImagesPath: '',
});

export const useUiContext = () => {
  return useContext(UIContext);
};

export const UIProvider = ({ children, i18n, staticImagesPath }: UIProviderProps) => {
  return (
    <I18nextProvider i18n={i18n} defaultNS="translation">
      <UIContext.Provider value={{ locale: i18n.resolvedLanguage || i18n.language, staticImagesPath }}>
        <ModalProvider />
        {children}
      </UIContext.Provider>
    </I18nextProvider>
  );
};
