import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

type Query = {
  /** Search String */
  search_string?: string
  /** Status */
  status?: string
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  countries?: string
  /** User Id */
  user_id?: string

  /** Start At */
  start_at?: number

  /** End At */
  end_at?: number
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useTransactionsTotalStatByDates = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['transactions-total-stat-by-dates', gameId, query],
    queryFn: () => dashboardClient.v1.getTotalTransactionStat(companyId, gameId, query).then(r => r.data),
  })
}
