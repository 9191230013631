import PageBlock from '../../game-items/components/PageBlock'
import { useParams } from 'react-router-dom'
import { useWebhookLogOrderQuery } from '../api/useWebhookLogOrderQuery'
import { WebhookLogsInspector } from '../../webhooks'
import { TableRowSkeleton, TableZeroState } from '@/ui'
import { useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'
import { PaymentStatus } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'

export const WebhookLogTimeline = (props: { orderId: string; paymentStatus: PaymentStatus }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const ref = useRef(null)
  const isInView = useInView(ref)
  const { data = [], isLoading, refetch } = useWebhookLogOrderQuery(companyId, gameId, props.orderId, isInView)

  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      refetch()
    } else {
      mounted.current = true
    }
  }, [props.paymentStatus])

  let isZeroState = false

  switch (props.paymentStatus) {
    case PaymentStatus.Done:
    case PaymentStatus.Refunded:
      if (data.length == 0 && !isLoading) {
        isZeroState = true
      }
      break
    default:
      // for other statuses webhooks log for transaction is not available
      if (!isLoading) {
        isZeroState = true
      }
      break
  }

  return (
    <PageBlock title={t('transaction-details.webhook-log')} ref={ref}>
      {isLoading && <TableRowSkeleton rowCount={5} columnCount={3} />}
      {isZeroState ? (
        <TableZeroState
          className="mt-4 h-[300px]"
          title={t('transaction-details.webhook-log.no-logs')}
          message={t('transaction-details.webhook-log.no-logs.desc')}
        />
      ) : (
        <WebhookLogsInspector
          items={data}
          className="max-h-[630px] overflow-hidden rounded-xl border border-border-secondary px-2"
        />
      )}
    </PageBlock>
  )
}
