import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { SelectItem } from './Select';

export interface SelectSearch {
  /**
   * Function to filter items.
   * @param query
   * @param item
   * @param all
   */
  fn: (query: string, item: SelectItem, all: SelectItem[]) => boolean;
  /**
   * Placeholder for the search input.
   */
  placeholder: string;
}

/**
 *
 * @param search
 * @param items
 */
export const useSelectSearch = (search: SelectSearch | undefined, items: SelectItem[]) => {
  const ref = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');

  const hasQuery = useMemo(() => !!query, [query]);

  const filteredItems = useMemo(
    () => (search ? items.filter(item => search.fn(query, item, items)) : items),
    [search, items, query],
  );

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value), [setQuery]);

  const reset = useCallback((focus?: boolean) => {
    if (ref.current && focus) {
      ref.current.focus();
    }
    setQuery('');
  }, []);

  return {
    filteredItems,
    searchInput: {
      ref,
      'aria-controls': 'list-results',
      placeholder: search?.placeholder,
      value: query,
      onChange,
    },
    hasQuery,
    reset,
  };
};
