import { JobFunctionName, JobRead, JobStatus } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { Badge, Tooltip } from '@/ui'
import { Button } from '@dashboard/ui'
import dayjs from 'dayjs'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { GAME_SEGMENT_GROUPS_PATH, LOCALIZATION_PATH, LOCALIZATION_TRANSLATIONS_LOCALE_PATH } from '@/libs'
import { Spreadsheet } from '@/layouts/notification/Icons/Spreadsheet'
import { Segment } from '@/layouts/notification/Icons/Segment'
import { LOCALE_FLAGS } from '@/util'
import { Localization } from '@/components/ui/AppSidebar/icons'
import { useQuery } from '@tanstack/react-query'
import { localesQueryOptions } from '@/layouts/localization/api'

export const JobItem = ({ job, onGoClick }: { job: JobRead; onGoClick?: (functionName: string) => void }) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const navigate = useNavigate()
  const { data: locales = [] } = useQuery(localesQueryOptions(companyId, gameId))

  const renderStatus = () => {
    switch (job.status) {
      case JobStatus.Canceled:
        return <Badge variant="orange-secondary">{t(`notification.status.${job.status}`)}</Badge>
      case JobStatus.Completed:
        return (
          <Tooltip message={t('notification.total', { value: job.progress_total })}>
            <Badge variant="green-primary">{t(`notification.status.${job.status}`)}</Badge>
          </Tooltip>
        )
      case JobStatus.InProgress:
        const done = job.progress_done
        const total = job.progress_total
        const percent = total ? Math.floor((done / total) * 100) : 0
        return (
          <Badge variant="orange-tertiary" className="whitespace-nowrap">
            {t(`notification.status.${job.status}`)} · {percent}%
          </Badge>
        )
      case JobStatus.Failed:
        return <Badge variant="red-secondary">{t(`notification.status.${job.status}`)}</Badge>
      default:
        return <Badge variant="gray-primary">{t(`notification.status.${job.status}`)}</Badge>
    }
  }

  const renderGoButton = () => {
    if (!onGoClick) {
      return null
    }

    switch (job.function_name) {
      case JobFunctionName.StoreImport:
        return (
          <Button
            variant="outline"
            color="secondary"
            size="sm"
            className="mt-2.5"
            onClick={() => {
              onGoClick(job.function_name)
            }}
          >
            {t('notification.go-to-import')}
          </Button>
        )
      case JobFunctionName.CalculateStaticSegmentGroup:
        return (
          <Button
            onClick={() => {
              onGoClick(job.function_name)
              navigate(generatePath(GAME_SEGMENT_GROUPS_PATH, { companyId, gameId }))
            }}
            variant="outline"
            color="secondary"
            size="sm"
            className="mt-2.5"
          >
            {t('notification.go-to-sg')}
          </Button>
        )
      case JobFunctionName.TranslateSyncAll:
      case JobFunctionName.TranslateTranslateAll:
        return (
          <Button
            onClick={() => {
              onGoClick(job.function_name)
              if (!locales.find(it => it.locale === job.function_args.locale)) {
                navigate(generatePath(LOCALIZATION_PATH, { companyId, gameId }))
              } else {
                navigate(
                  generatePath(LOCALIZATION_TRANSLATIONS_LOCALE_PATH, {
                    companyId,
                    gameId,
                    localeId: job.function_args.locale_id,
                  }),
                )
              }
            }}
            variant="outline"
            color="secondary"
            size="sm"
            className="mt-2.5"
          >
            {t('notification.go-to-locale')}
          </Button>
        )
    }
  }

  const renderDate = () => {
    return (
      <div className="text-caption-sm text-text-quarterary-hover">{dayjs(new Date()).to(job.created_at * 1000)}</div>
    )
  }

  const getIcon = () => {
    switch (job.function_name) {
      case JobFunctionName.StoreImport:
        return <Spreadsheet />
      case JobFunctionName.CalculateStaticSegmentGroup:
        return <Segment />
      case JobFunctionName.TranslateTranslateAll:
      case JobFunctionName.TranslateSyncAll:
        return <Localization variant="duotone" className="size-4" />
    }
  }

  const renderDesc = () => {
    switch (job.function_name) {
      case JobFunctionName.StoreImport:
      case JobFunctionName.CalculateStaticSegmentGroup:
        return <div>{t(`notification.${job.function_name}.desc`)}</div>
      case JobFunctionName.TranslateSyncAll:
      case JobFunctionName.TranslateTranslateAll:
        const locale = job.function_args.locale
        return (
          <div>
            {t(`notification.${job.function_name}.desc`)} {t(`locales.${locale}`)}
            <img
              className="ml-1 inline-block w-5 rounded-sm"
              src={`${LOCALE_FLAGS}/${locale.toUpperCase()}.svg`}
              alt={locale}
            />
          </div>
        )
    }
  }

  return (
    <div className="flex gap-3 py-3">
      <div style={{ marginTop: '2px' }} className="text-text-secondary">
        {getIcon()}
      </div>
      <div className="w-full">
        <div className="flex items-start justify-between">
          <div className=" text-caption-md text-text-secondary">{t(`notification.${job.function_name}`)}</div>
          {renderDate()}
        </div>
        <div className="mt-1 flex w-full items-center justify-between text-caption-sm text-text-tertiary-hover">
          {renderDesc()}
          <div className="ml-auto pl-2">{renderStatus()}</div>
        </div>

        {renderGoButton()}
      </div>
    </div>
  )
}
