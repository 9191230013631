import { useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup } from '@/ui'

import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import { FormErrorMessage } from '@dashboard/ui'
import { LexicalController } from '@/components/lexical'

const RichContentBlockEditor = () => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<Block>()

  return (
    <div>
      <FieldGroup
        size="sm"
        label={
          <span className="text-caption-sm font-normal text-text-tertiary">
            {t('hub-editor.rich-content-block.title')}
          </span>
        }
      >
        <LexicalController
          control={control}
          layout="compact"
          name="html"
          rules={{
            required: t('validation.required'),
          }}
        />
        <ErrorMessage
          name="html"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
    </div>
  )
}

export default RichContentBlockEditor
