import { useTranslation } from 'react-i18next'
import { InputSearch, SearchFilterGeneric } from '@dashboard/ui'
import { L10nGlossaryListFilter } from '../types'

interface L10nGlossaryFilterProps {
  filter: SearchFilterGeneric<L10nGlossaryListFilter>
}

export const L10nGlossaryFilter = ({ filter }: L10nGlossaryFilterProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-3 border-b border-border-secondary p-3">
      <InputSearch {...filter.registerInput('q')} data-testid="l10n/toolbar/filter-search" placeholder={t('search')} />
    </div>
  )
}
