import { useTranslation } from 'react-i18next'
import { Button, useModal } from '@dashboard/ui'
import { TableZeroState } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'
import { L10nGlossaryItemModalCreate } from './L10nGlossaryItemModalCreate'
import { Plus } from '@/icons'

export const L10nGlossaryLocaleZeroState = () => {
  const { t } = useTranslation()
  const { localeId } = useGenerateCompanyPath<{ localeId: string }>()

  const openCreateModal = useModal(props => <L10nGlossaryItemModalCreate localeId={localeId} {...props} />)

  return (
    <TableZeroState
      title={t('l10n.glossary.zero.title')}
      message={t('l10n.glossary.zero.message')}
      buttons={
        <Button onClick={openCreateModal}>
          <Plus size={16} />
          <span>{t('l10n.glossary.add-button')}</span>
        </Button>
      }
    />
  )
}
