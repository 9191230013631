import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@dashboard/ui'

export const CancelOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        d="M10.4926 6.4424C10.7851 6.1499 11.2651 6.1499 11.5576 6.4424C11.8501 6.7349 11.8501 7.2149 11.5576 7.5074L10.0576 8.9999L11.5576 10.4924C11.8501 10.7849 11.8501 11.2649 11.5576 11.5574C11.2651 11.8499 10.7851 11.8499 10.4926 11.5574L9.00008 10.0574L7.50758 11.5574C7.21508 11.8499 6.73508 11.8499 6.44258 11.5574C6.15008 11.2649 6.15008 10.7849 6.44258 10.4924L7.94258 8.9999L6.44258 7.5074C6.15008 7.2149 6.15008 6.7349 6.44258 6.4424C6.73508 6.1499 7.21508 6.1499 7.50758 6.4424L9.00008 7.9424L10.4926 6.4424Z"
        fill="#7F1D1D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 6C16.5 7.035 16.155 7.98 15.57 8.745C15.45 8.895 15.45 9.105 15.57 9.255C16.155 10.02 16.5 10.965 16.5 12C16.5 14.4825 14.4825 16.5 12 16.5C10.965 16.5 10.02 16.155 9.255 15.57C9.105 15.45 8.895 15.45 8.745 15.57C7.98 16.155 7.035 16.5 6 16.5C3.5175 16.5 1.5 14.4825 1.5 12C1.5 10.965 1.845 10.02 2.43 9.255C2.55 9.105 2.55 8.895 2.43 8.745C1.845 7.98 1.5 7.035 1.5 6C1.5 3.5175 3.5175 1.5 6 1.5C7.035 1.5 7.98 1.845 8.745 2.43C8.895 2.55 9.105 2.55 9.255 2.43C10.02 1.845 10.965 1.5 12 1.5C14.4825 1.5 16.5 3.5175 16.5 6ZM15 12C15 11.0175 14.5275 10.1475 13.8 9.6C13.605 9.45 13.5 9.225 13.5 9C13.5 8.775 13.605 8.55 13.8 8.4C14.5275 7.8525 15 6.9825 15 6C15 4.3425 13.6575 3 12 3C11.0175 3 10.1475 3.4725 9.6 4.2C9.45 4.395 9.225 4.5 9 4.5C8.775 4.5 8.55 4.395 8.4 4.2C7.8525 3.4725 6.9825 3 6 3C4.3425 3 3 4.3425 3 6C3 6.9825 3.4725 7.8525 4.2 8.4C4.395 8.55 4.5 8.775 4.5 9C4.5 9.225 4.395 9.45 4.2 9.6C3.4725 10.1475 3 11.0175 3 12C3 13.6575 4.3425 15 6 15C6.9825 15 7.8525 14.5275 8.4 13.8C8.55 13.605 8.775 13.5 9 13.5C9.225 13.5 9.45 13.605 9.6 13.8C10.1475 14.5275 11.0175 15 12 15C13.6575 15 15 13.6575 15 12Z"
        fill="#7F1D1D"
      />
    </IconBase>
  )
})
