import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearch, SearchFilterGeneric, SelectFilter, SelectFilterItem } from '@dashboard/ui'
import { CouponFilter } from '../types'
import { useFeatureFlags } from '@/libs'
import { CouponType } from '@/api/dashboard'

interface CouponToolbarProps {
  filter: SearchFilterGeneric<CouponFilter>
}

export const CouponToolbar = ({ filter }: CouponToolbarProps) => {
  const { t } = useTranslation()
  const {
    featureFlags: { creator_program_enabled = false },
  } = useFeatureFlags()

  const typeItems: SelectFilterItem[] = useMemo(() => {
    return Object.values(CouponType)
      .filter(it => it !== CouponType.NonBenefit || creator_program_enabled)
      .map(it => ({
        children: t(`coupon.type.${it}`),
        value: it,
      }))
  }, [])

  return (
    <div className="mb-3 flex items-center gap-3">
      <InputSearch
        {...filter.registerInput('q')}
        data-testid="coupon/toolbar/filter-search"
        placeholder={t('search')}
      />
      <div className="flex gap-2">
        <SelectFilter {...filter.registerSelect('type')} items={typeItems}>
          {t('coupon.filter.type')}
        </SelectFilter>
      </div>
    </div>
  )
}
