import { Children, KeyboardEvent as ReactKeyboardEvent, ReactNode, useCallback, useRef } from 'react';
import { useInView } from 'framer-motion';
import { Checkbox } from '../Checkbox';
import { cn } from '../../libs';

export interface MenuGroupProps {
  /**
   * Menu items of the group.
   */
  children: ReactNode;
  /**
   * Label of the group.
   */
  label: ReactNode;
  /**
   * Variant of the group.
   */
  variant?: 'default' | 'checkbox';
  /**
   * State of the group for checkbox.
   */
  state?: {
    checked: boolean;
    onClick: () => void;
  };
}

/**
 * Component for grouping menu items, adds a label and a separator.
 */
export const MenuGroup = ({ children, label, variant = 'default', state }: MenuGroupProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const liRef = useRef<HTMLLIElement>(null);
  const isInView = useInView(menuRef);

  const onChangeCheckbox = useCallback(() => {
    state?.onClick();
    liRef.current?.focus();
  }, [state]);

  const onPressEnter = useCallback(
    (e: ReactKeyboardEvent<HTMLLIElement>) => {
      if (!e.key || e.key === 'Enter') {
        state?.onClick();
        e.target.dispatchEvent(new Event('mousemove'));
        return;
      }
    },
    [state],
  );

  return (
    <div
      ref={menuRef}
      className={cn(
        'group/menu-group -mx-3 mb-2 last:mb-0',
        variant === 'checkbox' && 'first-of-type:mt-[calc(-0.75rem-1px)]',
      )}
    >
      {variant === 'default' && (
        <div className="mb-1.5 flex items-center gap-3 pl-3">
          <div className="flex shrink-0 items-center gap-2 text-caption-xs uppercase text-text-quarterary-hover">
            {label}
          </div>
          <div className="h-px w-full bg-border-secondary" />
        </div>
      )}
      {variant === 'checkbox' && (
        <li
          ref={liRef}
          className={cn(
            'relative mb-1 flex items-center gap-2 border-y border-border-secondary bg-fg-secondary px-3 py-1.5 text-caption-md text-text-tertiary',
            'focus-visible:[--bg-color:theme(colors.fg-primary-hover)]',
            'focus-visible:[--label-color:theme(colors.text-secondary-hover)]',
            'focus-visible:[--caption-color:theme(colors.text-tertiary-hover)]',
            'focus-visible:[--extra-color:theme(colors.text-quarterary-hover)]',
            'focus-visible:ring-border-brand',
            'focus-visible:ring-2',
            'focus-visible:mx-1.5',
            'focus-visible:px-1.5',
            'focus-visible:rounded-md',
            'focus-visible:z-10',
          )}
          role="option"
          tabIndex={0}
          onKeyDown={onPressEnter}
        >
          <Checkbox tabIndex={-1} checked={state?.checked} onChange={onChangeCheckbox} />
          <span>{label}</span>
        </li>
      )}
      {isInView ? <div className="px-3">{children}</div> : <div style={{ minHeight: Children.count(children) * 32 }} />}
    </div>
  );
};
