import { DEFAULT_MOTION_DURATION } from '@/libs'
import { useState } from 'react'

export const useDrawerPageState = (closeCallback: () => void) => {
  const [open, setOpen] = useState(true)

  const onClose = () => {
    setOpen(false)
    setTimeout(closeCallback, DEFAULT_MOTION_DURATION)
  }

  return { open, onClose }
}
