import { createContext, useEffect, useState } from 'react'
import { Route, Routes, generatePath, useLocation, useNavigate } from 'react-router-dom'
import { focusGroupKeyUX, hotkeyKeyUX, jumpKeyUX, pressKeyUX, startKeyUX } from 'keyux'
import { AuthContext, IAppState } from './Context'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { buildTheme } from './theme/Theme'
import { getLastCompanyId, getLastGameId, setCompanyGameId, setLastGameId } from './Settings'
import AppSkeleton from './skeleton/AppSkeleton'
import { CompanyGame, CustomerCompany, ItemType } from './api/dashboard'
import {
  APP_CONNECT_PATH,
  APP_CONNECT_PLUGIN_DETAIL_PATH,
  COMPANY_PAYMENT_METHODS,
  COMPANY_SETTINGS_PATH,
  DOCUMENT_PATH,
  ENGAGEMENT_CREATORS_PATH,
  ENGAGEMENT_DAILY_REWARDS_PATH,
  ENGAGEMENT_DAILY_REWARDS_PROGRAM_PATH,
  ENGAGEMENT_LOYALTY_PATH,
  ENGAGEMENT_PATH,
  FEATURES_PATH,
  GAME_PATH,
  GAME_SETTINGS,
  GAME_WEBHOOKS_DETAIL_PATH,
  GAME_WEBHOOKS_EDIT_PATH,
  GAME_WEBHOOKS_NEW_PATH,
  GAME_WEBHOOKS_PATH,
  GET_STARTED_PATH,
  GET_STARTED_VERIFY_ACCOUNT_PATH,
  HOME_PATH,
  HUB_PATH,
  LIVEOPS_PATH,
  LOCALIZATION_GLOSSARY_LOCALE_PATH,
  LOCALIZATION_GLOSSARY_PATH,
  LOCALIZATION_PATH,
  LOCALIZATION_TRANSLATIONS_LOCALE_PATH,
  LOCALIZATION_TRANSLATIONS_PATH,
  LOCALIZATION_TRANSLATION_PATH,
  PAYOUTS_PATH,
  SKU,
  SKU_ITEMS_SETTINGS_PATH,
  TEAM_PATH,
  TRANSACTIONS_PATH,
} from '@/libs'
import PlayersTable from './layouts/player/PlayersTable'
import GameAttributeTable from './layouts/player/GameAttributeTable'
import SegmentTable from './layouts/segment/SegmentTable'
import { MainOutlet } from './layouts/MainOutlet'
import TransactionsPage from './layouts/transaction/TransactionsPage'
import ComingSoon from './components/ComingSoon'
import HubThemePage from './hub-editor/HubThemePage'
import { HubIndexPage } from './layouts/hub-settings/HubIndexPage'
import NewsPage from './layouts/news/NewsPage'
import AchievementTable from './layouts/achievement/AchievementTable'
import LeaderBoardTable from './layouts/leader-board/LeaderBoardTable'
import AcceptForm from './layouts/accept-form/AcceptForm'
import OnBoardingPage from './layouts/onboarding/OnBoardingPage'
import Dashboard from './layouts/dashboard/Dashboard'
import { CompanySettingsPage } from './layouts/company'
import { SkuListPage } from './layouts/game-items/pages/SkuListPage'
import { SkuEditPage } from './layouts/game-items/pages/SkuEditPage'
import CouponTable from './layouts/coupon/CouponTable'
import MembersTable from './layouts/users/MembersTable'
import TransactionDetails from './layouts/transaction/TransactionDetails'
import CampaignsTable from './layouts/campaigns/pages/CampaignTable/CampaignsTable'
import { CampaignSettingsPage } from './layouts/campaigns/pages/CampaignSettingsPage'
import CampaignEditPage from './layouts/campaigns/CampaignEditPage'
import HubEditor from './hub-editor/HubEditor'
import PlayerDetails from './layouts/player/PlayerDetails'
import { PagesIndexPage } from './layouts/pages/PagesIndexPage'
import SegmentGroupGraphEditor from './layouts/segment/SegmentGroupGraphEditor'
import PagesPage from './layouts/pages/PagesPage'
import FeatureSettings from './layouts/game-settings/widgets/FeatureSettings'
import { loadCompanyAndGame, useCompaniesQuery } from './api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { ResponseError, dashboardClient } from './api'
import { identifyPosthogUser } from './api/postLogin'
import { L10nLocalePage, L10nOutlet, L10nRedirect, L10nTranslationPage } from '@/layouts/localization'
import { SkuCreatePage } from './layouts/game-items/pages/SkuCreatePage'
import StorePage from './layouts/store/StorePage'
import StoreEditor from './layouts/store/StoreEditor'
import StoreGraphPage from './layouts/store/StoreGraphPage'
import ItemCategoryPage from './layouts/item-category/ItemCategoryPage'
import { WebhookDetailPage, WebhookEditPage, WebhookNewPage, WebhookTable } from './layouts/webhooks'
import { useAuthToken } from './auth/useAuthToken'
import PaymentSettingsTable from './layouts/payment-settings/PaymentSettingsTable'
import { GameSettingsPage } from './layouts/game-settings/GameSettingsPage'
import { HubSettingsPage } from './layouts/hub-settings/HubSettingsPage'
import { GetStartedPage, GetStartedVerifyPage } from './layouts/get-started'
import { PayoutsPage } from '@/layouts/payouts'
import { hasOnlyDemoCompany } from '@/util'
import { DailyRewardsPage, DailyRewardsProgramPage, LoyaltyPage } from '@/layouts/engagement'
import { SkuSettingsPage } from '@/layouts/sku-settings/pages/SkuSettingsPage'
import { SkuOutlet } from '@/layouts/game-items/pages/SkuOutlet'
import ItemPropertiesList from '@/layouts/sku-settings/widgets/ItemPropertiesList'
import { PluginTable } from '@/layouts/app-connect/PluginTable'
import PluginEditPage from '@/layouts/app-connect/PluginEditPage'
import { CreatorsPage } from '@/layouts/engagement/pages'
import { L10nGlossaryLocalePage, L10nGlossaryOutlet } from '@/layouts/localization-glossary'
import StorePromotionPage from '@/layouts/store-promotion/StorePromotionPage'
import {
  SKU_CASHBACK_REWARDS_CREATE_PATH,
  SKU_CASHBACK_REWARDS_EDIT_PATH,
  SKU_CASHBACK_REWARDS_PATH,
  SkuCashbackRewardsCreatePage,
  SkuCashbackRewardsEditPage,
  SkuCashbackRewardsOutlet,
} from './layouts/sku-cashback–rewards'

export const lightTheme = buildTheme('light')

export const ThemeContext = createContext({
  theme: {},
})

startKeyUX(window, [hotkeyKeyUX([]), focusGroupKeyUX(), pressKeyUX('is-pressed'), jumpKeyUX()])

export default function App() {
  const [customer, setCustomer] = useState<CustomerCompany | null>(null)
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>(null)
  const [theme] = useState(lightTheme)
  const { pathname } = useLocation()
  const [appState, setAppState] = useState<IAppState>({} as IAppState)
  const [acceptForm, setAcceptForm] = useState<boolean>(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: dataCompanies = null, refetch } = useCompaniesQuery()
  const [isCompanyLoaded, setIsCompanyLoaded] = useState(false)
  const { authDone } = useAuthToken()

  const companies = dataCompanies?.data
  const errorGetCompany = dataCompanies?.error as ResponseError

  const openDefaultCompany = async (companies: CompanyGame[], companyWithGames: CompanyGame) => {
    const firstGame = companyWithGames?.games
      ? companyWithGames.games.find(g => !g.sandbox) || companyWithGames.games[0]
      : undefined
    let params = {
      companyId: companyWithGames.id,
      gameId: firstGame?.id || '',
    }

    let lastGameId = getLastGameId()
    let lastCompanyId = getLastCompanyId()

    if (lastGameId && lastCompanyId) {
      let c = companies.find(c => c.id == lastCompanyId)
      if (!c) {
        let game = await loadCompanyAndGame(queryClient, lastCompanyId, lastGameId)

        if (game) {
          params = { companyId: lastCompanyId, gameId: lastGameId }
        } else {
          setLastGameId(null)
          setCompanyGameId(null)
        }
      } else {
        params = { companyId: lastCompanyId, gameId: lastGameId }
      }
    }

    navigate(generatePath(HOME_PATH, params))
  }

  const fetchInvitesOrOpenOnboarding = async () => {
    try {
      const { data: invitePreview } = await dashboardClient.v1.getMyInvite()
      if (invitePreview) {
        navigate(`/join/${invitePreview.id}`)
        return
      }
    } catch (e) {
      //do nothing
    }

    navigate('/onboarding')
  }

  const loadCompanies = async () => {
    try {
      if (errorGetCompany) {
        return
      }

      if (!companies) {
        return
      }

      let companyWithGames = companies.find(c => c.games && c.games.length > 0)

      if (hasOnlyDemoCompany(companies) || !companies.length || !companyWithGames) {
        fetchInvitesOrOpenOnboarding()
        return
      }

      if (pathname.startsWith('/company')) {
        //useParams can't be use outside of Router
        let arr = pathname.split('/')
        let companyId = arr[2]
        let gameId = arr[3]
        let company = companies.find(it => it.id == companyId)

        if (!company) {
          let game = await loadCompanyAndGame(queryClient, companyId, gameId)
          if (!game) {
            console.log("Can't load game, open default company...")
            openDefaultCompany(companies, companyWithGames)
          }
        } else {
          let games = company?.games
          if (!games?.length) {
            openDefaultCompany(companies, companyWithGames)
          } else if (!games.find(g => g.id === gameId)) {
            navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: games[0].id }))
          }
        }
        return
      }

      if (!pathname.startsWith('/onboarding')) {
        openDefaultCompany(companies, companyWithGames)
      }
    } finally {
      setIsCompanyLoaded(true)
    }
  }

  useEffect(() => {
    if (authDone) {
      refetch()
      identifyPosthogUser()
    }
  }, [authDone])

  useEffect(() => {
    if (companies != null) {
      loadCompanies()
    }
  }, [companies])

  useEffect(() => {
    if (errorGetCompany) {
      if (errorGetCompany.status == 403) {
        navigate('/403')
      }

      if (errorGetCompany.status == 451) {
        setIsCompanyLoaded(true)
        setAcceptForm(true)
      }
    }
  }, [errorGetCompany])

  if (!isCompanyLoaded) {
    return <AppSkeleton />
  }

  return (
    <AuthContext.Provider
      value={{
        customer: customer!,
        setCustomer: setCustomer,
        currentCompanyId: currentCompanyId,
        setCurrentCompanyId: setCurrentCompanyId,
        state: appState,
        setState: setAppState,
      }}
    >
      <ThemeContext.Provider value={{ theme: theme }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {acceptForm ? (
            <AcceptForm
              onClose={() => {
                refetch()
                setAcceptForm(false)
              }}
            />
          ) : (
            <Routes>
              <Route element={<MainOutlet />}>
                <Route path="/onboarding" element={<OnBoardingPage />} />
                <Route path="/company/:companyId/:gameId/*" element={<ComingSoon />} />
                <Route path={HOME_PATH} element={<Dashboard />} />

                <Route path={LIVEOPS_PATH}>
                  <Route path="table" element={<CampaignsTable />} />
                  <Route path="settings" element={<CampaignSettingsPage />} />

                  <Route path="segments" element={<SegmentTable />} />
                  <Route path="segments/groups" element={<SegmentTable segmentGroupVisible={true} />} />
                  <Route path="segments/groups/:groupId" element={<SegmentGroupGraphEditor />} />

                  <Route path=":eventId" element={<CampaignEditPage />} />
                  <Route path=":eventId/:userCampaignId" element={<CampaignEditPage />} />

                  <Route path="store" element={<StorePage />} />
                  <Route path="store/rules" element={<StoreGraphPage />} />
                  <Route path="store/:storeId" element={<StoreEditor />} />

                  <Route path="promotions" element={<StorePromotionPage />} />
                  <Route path="promotions/:promotionId" element={<StorePromotionPage />} />
                </Route>

                <Route path={ENGAGEMENT_PATH}>
                  <Route path={ENGAGEMENT_LOYALTY_PATH} element={<LoyaltyPage />} />
                  <Route path={ENGAGEMENT_DAILY_REWARDS_PATH}>
                    <Route index={true} element={<DailyRewardsPage />} />
                    <Route path={ENGAGEMENT_DAILY_REWARDS_PROGRAM_PATH} element={<DailyRewardsProgramPage />} />
                  </Route>
                  <Route path={ENGAGEMENT_CREATORS_PATH} element={<CreatorsPage />} />
                </Route>

                <Route path={SKU}>
                  <Route path="store">
                    <Route index={true} element={<StorePage />} />
                    <Route path="rules" element={<StoreGraphPage />} />
                    <Route path=":storeId" element={<StoreEditor />} />
                  </Route>

                  <Route path="category">
                    <Route index={true} element={<ItemCategoryPage />} />
                  </Route>

                  <Route path="items" element={<SkuOutlet />}>
                    <Route path=":itemId" element={<SkuEditPage type={ItemType.Item} />} />
                    <Route path="new" element={<SkuCreatePage type={ItemType.Item} />} />

                    <Route path={SKU_ITEMS_SETTINGS_PATH} element={<SkuSettingsPage />}>
                      <Route path="categories" element={<ItemCategoryPage />} />
                      <Route path="properties" element={<ItemPropertiesList />} />
                    </Route>
                  </Route>

                  <Route path="bundles" element={<SkuListPage types={[ItemType.Bundle]} />} />
                  <Route path="bundles/:itemId" element={<SkuEditPage type={ItemType.Bundle} />} />
                  <Route path="bundles/new" element={<SkuCreatePage type={ItemType.Bundle} />} />

                  <Route path={SKU_CASHBACK_REWARDS_PATH} element={<SkuCashbackRewardsOutlet />}>
                    <Route path={SKU_CASHBACK_REWARDS_CREATE_PATH} element={<SkuCashbackRewardsCreatePage />} />
                    <Route path={SKU_CASHBACK_REWARDS_EDIT_PATH} element={<SkuCashbackRewardsEditPage />} />
                  </Route>

                  <Route path="codes">
                    <Route index={true} element={<CouponTable />} />
                    <Route path=":codeId" element={<CouponTable />} />
                  </Route>
                </Route>

                <Route path={LOCALIZATION_PATH}>
                  <Route index={true} element={<L10nRedirect />} />

                  <Route path={LOCALIZATION_TRANSLATIONS_PATH} element={<L10nOutlet />}>
                    <Route path={LOCALIZATION_TRANSLATIONS_LOCALE_PATH} element={<L10nLocalePage />}>
                      <Route path={LOCALIZATION_TRANSLATION_PATH} element={<L10nTranslationPage />} />
                    </Route>
                  </Route>

                  <Route path={LOCALIZATION_GLOSSARY_PATH} element={<L10nGlossaryOutlet />}>
                    <Route path={LOCALIZATION_GLOSSARY_LOCALE_PATH} element={<L10nGlossaryLocalePage />} />
                  </Route>
                </Route>

                <Route path={TRANSACTIONS_PATH}>
                  <Route index={true} element={<TransactionsPage />} />
                  <Route path=":paymentNumber" element={<TransactionDetails />} />
                </Route>

                <Route path={GAME_PATH}>
                  <Route path="players">
                    <Route index={true} element={<PlayersTable />} />
                    <Route path=":userId" element={<PlayerDetails />} />
                  </Route>
                  <Route path="attributes" element={<GameAttributeTable />} />

                  <Route path={GAME_WEBHOOKS_PATH}>
                    <Route index={true} element={<WebhookTable />} />
                    <Route path={GAME_WEBHOOKS_NEW_PATH} element={<WebhookNewPage />} />
                    <Route path={GAME_WEBHOOKS_DETAIL_PATH} element={<WebhookDetailPage />}>
                      <Route path={GAME_WEBHOOKS_EDIT_PATH} element={<WebhookEditPage />} />
                    </Route>
                  </Route>
                </Route>

                <Route path={HUB_PATH}>
                  <Route index={true} element={<HubIndexPage />} />
                  <Route path="themes">
                    <Route index={true} element={<HubThemePage />} />
                    <Route path=":wbId" element={<HubEditor />} />
                  </Route>
                  <Route path="settings" element={<HubSettingsPage />} />
                  <Route path="news">
                    <Route path=":group?" element={<NewsPage />} />
                    <Route path=":group/:newsId" element={<NewsPage />} />
                  </Route>
                  <Route path="leaderboard">
                    <Route index={true} element={<LeaderBoardTable />} />
                  </Route>
                  <Route path="achievements">
                    <Route index={true} element={<AchievementTable />} />
                  </Route>
                  <Route path="pages">
                    <Route index={true} element={<PagesIndexPage />} />
                    <Route path=":parent/:child?" element={<PagesPage />} />
                    <Route path=":parent/:child?/pages/:pageId" element={<PagesPage />} />
                  </Route>
                </Route>

                <Route path={GET_STARTED_PATH}>
                  <Route path={GET_STARTED_PATH} element={<GetStartedPage />} />
                  <Route path={GET_STARTED_VERIFY_ACCOUNT_PATH} element={<GetStartedVerifyPage />} />
                </Route>

                <Route path={APP_CONNECT_PATH} element={<PluginTable />} />
                <Route path={APP_CONNECT_PLUGIN_DETAIL_PATH} element={<PluginEditPage />} />

                <Route path={DOCUMENT_PATH} element={<ComingSoon />} />
                <Route path={PAYOUTS_PATH} element={<PayoutsPage />} />
                <Route path={TEAM_PATH} element={<MembersTable />} />
                <Route path={COMPANY_SETTINGS_PATH} element={<CompanySettingsPage />} />
                <Route path={COMPANY_PAYMENT_METHODS} element={<PaymentSettingsTable />} />
                <Route path={GAME_SETTINGS} element={<GameSettingsPage />} />
                <Route path={FEATURES_PATH} element={<FeatureSettings />} />
              </Route>
            </Routes>
          )}
        </ThemeProvider>
      </ThemeContext.Provider>
    </AuthContext.Provider>
  )
}
