import { Skeleton } from '@dashboard/ui'
import { TableCell, TableRow } from '@/ui'

export const SkuCashbackSkeleton = () => (
  <>
    <TableRow variant="header">
      <TableCell width="100%">
        <Skeleton className="h-5 w-1/4" />
      </TableCell>
      <TableCell width="60%">
        <Skeleton className="h-5 w-2/4" />
      </TableCell>
      <TableCell width="60%">
        <Skeleton className="h-5 w-2/4" />
      </TableCell>
      <TableCell width="50%">
        <Skeleton className="h-5 w-2/4" />
      </TableCell>
      <TableCell width="25%">
        <Skeleton className="h-5 w-3/4" />
      </TableCell>
      <TableCell width={52} />
    </TableRow>
    {new Array(10).fill('').map((_, index) => (
      <TableRow key={`table-row-${index}`}>
        <TableCell width="100%">
          <div className="flex items-center gap-3">
            <Skeleton className="size-8 shrink-0" />
            <Skeleton className="h-4 w-1/4" />
          </div>
        </TableCell>
        <TableCell width="60%">
          <div className="flex items-center gap-3">
            <Skeleton className="size-8 shrink-0" />
            <div className="w-full">
              <Skeleton className="mb-1 h-4 w-1/4" />
              <Skeleton className="h-3 w-2/4" />
            </div>
          </div>
        </TableCell>
        <TableCell width="60%">
          <Skeleton className="h-5 w-3/4" />
        </TableCell>
        <TableCell width="50%">
          <Skeleton className="h-5 w-3/5" />
        </TableCell>
        <TableCell width="25%">
          <Skeleton className="h-5 w-full" />
        </TableCell>
        <TableCell width={52}>
          <Skeleton className="size-[28px]" />
        </TableCell>
      </TableRow>
    ))}
  </>
)
