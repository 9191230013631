import { Outlet } from 'react-router-dom'
import { Page } from '@/ui'
import { SkuCashbackRewardsPage } from './SkuCashbackRewardsPage'

export const SkuCashbackRewardsOutlet = () => {
  return (
    <Page>
      <SkuCashbackRewardsPage />
      <Outlet />
    </Page>
  )
}
