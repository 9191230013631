import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'

interface UnsavedChangesModalProps extends ModalProps {}

export const UnsavedChangesModal = ({ ...rest }: UnsavedChangesModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onSubmit = () => {
    navigate(-1)
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalContent>
        <ModalTitle subtitle={t('sku.unsaved.modal.desc')}>{t('sku.unsaved.modal.title')}</ModalTitle>
      </ModalContent>
      <ModalFooter>
        <Button type="button" size="sm" onClick={rest.onClose} variant="outline" color="secondary">
          {t('sku.unsaved.modal.cancel')}
        </Button>
        <Button type="submit" color="danger" size="sm" onClick={onSubmit}>
          {t('sku.unsaved.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
