import { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { dashboardClient } from '@/api'
import { SegmentRead } from '@/api/dashboard'
import EditSegmentDialog from './widgets/EditSegmentDialog'
import {
  ConfirmDialog,
  Page,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import SegmentGroupTable from './SegmentGroupTable'
import { useSegmentsQuery } from './api/useSegmentsQuery'
import { useSegmentGroupsQuery } from './api/useSegmentGroupsQuery'
import { GAME_SEGMENTS_PATH, GAME_SEGMENT_GROUPS_PATH } from '@/libs'
import { Plus, Trash } from '@/icons'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { Button, Edit03, SelectFilter, useModal } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'

export default function SegmentTable(props: { segmentGroupVisible?: boolean }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const segmentGroupVisible = !!props.segmentGroupVisible
  const navigate = useNavigate()
  const { data: groups = [], isLoading: isGroupLoading } = useSegmentGroupsQuery(companyId, gameId)
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<SegmentRead | undefined>(undefined)

  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const {
    data: items = [],
    isLoading,
    refetch,
  } = useSegmentsQuery(companyId, gameId, {
    ...query,
    segment_group_id: selectedGroupId ? selectedGroupId : undefined,
  })

  const onDeleteItemConfirm = async () => {
    if (deleteConfirmItem) {
      await dashboardClient.v1.deleteSegment(companyId, gameId, deleteConfirmItem.id)
      refetch()
    }
    setDeleteConfirmItem(undefined)
  }

  const getGroupName = (id: string | undefined) => {
    if (!id) {
      return ''
    }
    const group = groups.find(g => g.id === id)
    return group?.name || ''
  }

  const openEditModal = useModal<{ item: SegmentRead; onClose?: () => void }>(props => (
    <EditSegmentDialog {...props} onClose={props.onClose!} />
  ))

  return (
    <Page>
      <PageHeader
        extra={
          canEdit && (
            <>
              <Button
                variant="outline"
                color="secondary"
                size="sm"
                onClick={() => navigate(generatePath(GAME_SEGMENT_GROUPS_PATH, { companyId, gameId }))}
              >
                {t('segments.edit-groups')}
              </Button>
              {(!!items.length || page > 1) && (
                <Button
                  size="sm"
                  onClick={() =>
                    openEditModal({
                      item: {
                        name: '',
                        color: '#0EA5E9',
                      } as SegmentRead,
                    })
                  }
                >
                  {t('segment.add')}
                </Button>
              )}
            </>
          )
        }
      >
        {t('sidebar.segments')}
      </PageHeader>

      {(!!items.length || selectedGroupId) && (
        <div className="flex justify-end py-1">
          <SelectFilter
            multiple={false}
            items={groups.map(item => ({
              children: item.name,
              value: item.id,
            }))}
            value={selectedGroupId}
            onChange={v => setSelectedGroupId(v as string)}
          >
            {t('segments.group')}
          </SelectFilter>
        </div>
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={t('remove')}
          subMessage={t('confirm.text', { name: deleteConfirmItem.name })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteItemConfirm}
        />
      )}

      <DrawerSidebar open={segmentGroupVisible} width="864px" position="right">
        {segmentGroupVisible && (
          <SegmentGroupTable onClose={() => navigate(generatePath(GAME_SEGMENTS_PATH, { companyId, gameId }))} />
        )}
      </DrawerSidebar>

      {isGroupLoading || isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 && page == 1 ? (
        <TableZeroState
          title={t('segment.zero.title')}
          message={t('segment.zero.message')}
          buttons={
            canEdit && (
              <Button
                size="sm"
                onClick={() =>
                  openEditModal({
                    item: {
                      name: '',
                      color: '#0EA5E9',
                    } as SegmentRead,
                  })
                }
              >
                <Plus size={16} />
                <span>{t('segment.zero.button-add')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow>
              <TableCell width="200%">{t('segment.name')}</TableCell>
              <TableCell width="200%">{t('segment.description')}</TableCell>
              <TableCell>{t('segment.group')}</TableCell>
              <TableCell />
            </TableRow>

            {items.map(it => (
              <TableRow key={it.id}>
                <TableCell width="200%" data-testid={`segment-table/row/${it.id}`}>
                  <SegmentBadge segment={it} />
                </TableCell>
                <TableCell width="200%">{it.description}</TableCell>
                <TableCell>{getGroupName(it.segment_group_id)}</TableCell>
                <TableCell>
                  {canEdit && (
                    <div className="flex justify-end" data-testid={'segment-table/edit-row-button'}>
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              setDeleteConfirmItem(it)
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <Edit03 />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: (
                              <span className="text-text-error-primary">
                                <Trash />
                              </span>
                            ),
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </Page>
  )
}
