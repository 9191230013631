import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const PlayerCustom: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <g clipPath="url(#clip0_3489_662)">
        <path
          d="M0 5.71077C0 3.71181 0 2.71233 0.389023 1.94883C0.731217 1.27724 1.27724 0.731217 1.94883 0.389023C2.71233 0 3.71181 0 5.71077 0H10.2892C12.2882 0 13.2877 0 14.0512 0.389023C14.7228 0.731217 15.2688 1.27724 15.611 1.94883C16 2.71233 16 3.71181 16 5.71077V10.2892C16 12.2882 16 13.2877 15.611 14.0512C15.2688 14.7228 14.7228 15.2688 14.0512 15.611C13.2877 16 12.2882 16 10.2892 16H5.71077C3.71181 16 2.71233 16 1.94883 15.611C1.27724 15.2688 0.731217 14.7228 0.389023 14.0512C0 13.2877 0 12.2882 0 10.2892V5.71077Z"
          fill="url(#paint0_linear_3489_662)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.06634 12.2666C5.80272 12.2666 6.39967 11.6696 6.39967 10.9333C6.39967 10.1969 5.80272 9.59992 5.06634 9.59992C4.32996 9.59992 3.73301 10.1969 3.73301 10.9333C3.73301 11.6696 4.32996 12.2666 5.06634 12.2666ZM6.57496 12.7999C6.16277 13.1335 5.63789 13.3333 5.06634 13.3333C3.74086 13.3333 2.66634 12.2587 2.66634 10.9333C2.66634 9.60777 3.74086 8.53325 5.06634 8.53325C5.63789 8.53325 6.16277 8.73304 6.57496 9.06659H11.4663C12.4973 9.06659 13.333 9.90232 13.333 10.9333C13.333 11.9642 12.4973 12.7999 11.4663 12.7999H6.57496ZM13.333 5.06659C13.333 6.39207 12.2585 7.46659 10.933 7.46659C10.3615 7.46659 9.83658 7.2668 9.42439 6.93325H4.53301C3.50208 6.93325 2.66634 6.09752 2.66634 5.06659C2.66634 4.03565 3.50208 3.19992 4.53301 3.19992H9.42439C9.83658 2.86637 10.3615 2.66659 10.933 2.66659C12.2585 2.66659 13.333 3.7411 13.333 5.06659ZM10.933 6.39992C11.6694 6.39992 12.2663 5.80297 12.2663 5.06659C12.2663 4.33021 11.6694 3.73325 10.933 3.73325C10.1966 3.73325 9.59967 4.33021 9.59967 5.06659C9.59967 5.80297 10.1966 6.39992 10.933 6.39992Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3489_662" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="#94A3B8" />
          <stop offset="1" stopColor="#64748B" />
        </linearGradient>
        <clipPath id="clip0_3489_662">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
