import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CashbackRewardCreate } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { skuCashbackRewardsQueryOpts } from './skuCashbackRewardsQueryOpts'

/**
 *
 * @returns
 */
export const skuCashbackRewardCreateMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ data }: { data: CashbackRewardCreate }) => {
      const r = await dashboardClient.v1.createCashbackReward(companyId, gameId, data)
      return r.data
    },
    onSuccess: data => {
      ql.setQueryData(skuCashbackRewardsQueryOpts(companyId, gameId).queryKey, (old = []) => {
        return [data, ...old]
      })
    },
  })
}
