import { WebhookEventType } from '@/api/dashboard'

/**
 * Sorted keys of webhook event types.
 * Used to sort the event types object in the webhook form.
 */
export const webhookSortedKeys: `${WebhookEventType}`[] = [
  'player.verify',
  'item.add',
  'item.remove',
  'order.paid',
  'order.canceled',
  'order.refunded',
  'coupon.redeemed',
  'ingame.push',
  'ingame.popup',
  'player.is_idle',
  'campaign.custom',
  'player.lookup',
  'store.get',
]
