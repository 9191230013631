import i18next from 'i18next'
import { StoreGraph } from '../../api/dashboard'
import { FieldSection } from '../../components/ui/FieldSection'
import { CheckBoxContainer } from '../../components/ui/CheckBoxContainer'
export default function StoreGraphSettings(props: { storeGraph: StoreGraph; setStoreGraph: (c: StoreGraph) => void }) {
  return (
    <div>
      <FieldSection label={i18next.t('store.settings.published')}>
        <CheckBoxContainer
          checked={props.storeGraph.enabled}
          onChange={v => {
            props.setStoreGraph({
              ...props.storeGraph,
              enabled: v.target.checked,
            })
          }}
          label={i18next.t('store.settings.published')}
          caption={i18next.t('store.settings.published.subtitle')}
        />
      </FieldSection>
    </div>
  )
}
