import { Link } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Badge, ButtonIcon, DrawerContent, DrawerTopbar, FieldGroup, Input } from '@/ui'
import { Textarea } from '../../../../components/ui/Textarea'
import { Plus, XClose } from '@/icons'
import { useNavigateBack } from '../../../../libs/hooks'
import { GAME_WEBHOOKS_PATH } from '../../../../libs/routerPaths'
import { urlRegexp } from '../../../../libs/string'
import { WebhookFormData, WebhookFormStage } from '../WebhookForm'
import { stageMotionProps } from './stageMotionProps'
import { Button, Checkbox } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'

interface GeneralStageProps {
  setStage: (stage: WebhookFormStage) => void
}

export const GeneralStage = ({ setStage }: GeneralStageProps) => {
  const { t } = useTranslation()
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })
  const { isSuperAdmin } = useCurrentUser()
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
    setValue,
  } = useFormContext<WebhookFormData>()

  const events = watch('events')

  const onClickSelectEvents = () => {
    setStage('select-events')
  }

  const onClickRemoveEvent = (event: string) => {
    setValue(
      'events',
      events.filter(e => e !== event),
    )
  }

  return (
    <>
      <DrawerTopbar>
        <Link {...back} className="-my-2">
          <ButtonIcon variant="secondary-gray" size="sm">
            <XClose />
          </ButtonIcon>
        </Link>
        <h2 className="mr-auto text-title-t5">{t('webhook.form.header.edit-title')}</h2>
        <Button type="submit" loading={isSubmitting} size="sm">
          {t('webhook.form.save')}
        </Button>
      </DrawerTopbar>

      <DrawerContent className="overflow-y-auto">
        <motion.div {...stageMotionProps}>
          <div className="mb-6">
            <FieldGroup label={t('webhook.form.endpoint-url')} required={true}>
              <Input
                {...register('url', {
                  required: t('validation.required'),
                  pattern: { value: urlRegexp, message: t('validation.invalid_url') },
                  maxLength: {
                    message: t('validation.maxLength', {
                      field: t('webhook.form.endpoint-url'),
                      value: 255,
                    }),
                    value: 255,
                  },
                  setValueAs: value => value.trim(),
                })}
                placeholder="https://example.com"
                autoFocus={true}
                errors={errors}
              />
            </FieldGroup>

            <FieldGroup label={t('webhook.form.desc')}>
              <Textarea
                {...register('description', {
                  maxLength: {
                    message: t('validation.maxLength', {
                      field: t('webhook.form.desc'),
                      value: 255,
                    }),
                    value: 255,
                  },
                })}
                placeholder={t('webhook.form.description-placeholder')}
                errors={errors}
              />
            </FieldGroup>

            <Checkbox {...register('enabled')}>{t('webhook.enabled')}</Checkbox>

            {isSuperAdmin && (
              <Checkbox className="mt-3" {...register('enabled_logs')}>
                {t('webhook.logs.enabled')}
              </Checkbox>
            )}
          </div>

          <div>
            <div className="mb-6 text-title-t5">{t('webhook.form.select-events.title')}</div>

            {!!events.length && (
              <div className="mb-4 flex flex-wrap gap-3">
                {events.map(event => (
                  <Badge key={`event-${event}`} variant="brand-blue-tertiary">
                    <span className="truncate">{t(`webhook.event.${event}.label`)}</span>
                    <button
                      className="-mx-0.5 cursor-pointer rounded-md p-0.5 transition-all hover:text-text-brand-primary"
                      type="button"
                      onClick={() => onClickRemoveEvent(event)}
                    >
                      <XClose size={16} />
                    </button>
                  </Badge>
                ))}
              </div>
            )}

            <Button variant="outline" type="button" onClick={onClickSelectEvents} size="sm">
              <Plus size={14} />
              <span>{t('webhook.form.select-events.button')}</span>
            </Button>
          </div>
        </motion.div>
      </DrawerContent>
    </>
  )
}
