import { Node, SortOrder, SortStoreItemsNode, SortStoreType } from '../../../api/dashboard'
import { BlockError } from '../../campaigns/types'
import i18next from 'i18next'
import { FieldGroup, FieldValidationMessage, Select } from '@/ui'
import { FieldSection } from '../../../components/ui/FieldSection'
import { Controller, useForm } from 'react-hook-form'
import SettingsHeader from '../../campaigns/SettingsHeader'
import { ErrorMessage } from '@hookform/error-message'

export default function SortItemsNodeEditor(props: {
  node: SortStoreItemsNode
  error?: BlockError | null
  setNode: (node: Node) => void
  onClose: () => void
}) {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<SortStoreItemsNode>({
    defaultValues: {
      ...props.node,
    },
  })

  const onSaveClick = (data: SortStoreItemsNode) => {
    props.setNode({
      ...props.node,
      ...data,
    } as Node)
    props.onClose()
  }

  return (
    <>
      <SettingsHeader node={props.node as Node} onClose={props.onClose} onSave={handleSubmit(onSaveClick)} />

      <div className="p-6">
        <FieldSection label={i18next.t('store.AddStoreItemsNode.general')}>
          <FieldGroup label={i18next.t('store.SortStoreItemsNode.sort_by')}>
            <Controller
              control={control}
              name="sort_by"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <Select
                  placeholder={i18next.t('campaign.condition.select')}
                  value={field.value}
                  options={[
                    { children: i18next.t('store.SortStoreItemsNode.sort_by.name'), value: SortStoreType.Name },
                    { children: i18next.t('store.SortStoreItemsNode.sort_by.price'), value: SortStoreType.Price },
                    { children: i18next.t('store.SortStoreItemsNode.sort_by.discount'), value: SortStoreType.Discount },
                    { children: i18next.t('store.SortStoreItemsNode.sort_by.position'), value: SortStoreType.Position },
                  ]}
                  onChange={field.onChange}
                />
              )}
            />
            <ErrorMessage
              name="sort_by"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('store.SortStoreItemsNode.order')}>
            <Controller
              control={control}
              name="order"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <Select
                  placeholder={i18next.t('campaign.condition.select')}
                  value={field.value}
                  options={[
                    { children: i18next.t('store.sort.asc'), value: SortOrder.Asc },
                    { children: i18next.t('store.sort.desc'), value: SortOrder.Desc },
                  ]}
                  onChange={field.onChange}
                />
              )}
            />
            <ErrorMessage
              name="sort_by"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </FieldSection>
      </div>
    </>
  )
}
