import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TranslationGlossaryCreate } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { l10nGlossariesInfiniteQuery } from './l10nGlossariesInfiniteQuery'

/**
 *
 * @returns
 */
export const l10nGlossaryEditMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ data, itemId }: { data: TranslationGlossaryCreate; itemId: string }) => {
      const r = await dashboardClient.v1.updateTranslationGlossary(companyId, gameId, itemId, data)
      return r.data
    },
    onSuccess: data => {
      return ql.refetchQueries({
        queryKey: l10nGlossariesInfiniteQuery(companyId, gameId, { locale_id: data.locale_id }).queryKey,
        exact: false,
      })
    },
  })
}
