import { useTranslation } from 'react-i18next'
import {
  Button,
  CountryFlag,
  InputDateRange,
  SearchFilterGeneric,
  SelectFilter,
  SelectFilterItem,
  SelectItem,
  useCountryGroups,
  useModal,
} from '@dashboard/ui'
import { TransactionsFilter } from '../types'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { Tooltip } from '@/ui'
import { Export } from '@/icons'
import GenerateReportDialog, { GenerateReportDialogProps } from '@/layouts/transaction/widgets/GenerateReportDialog'
import { getCountries } from '@/translates'
import { useState } from 'react'
import { createPresets } from '@/layouts/dashboard/util'
import { PaymentStatus } from '@/api/dashboard'
import { addTimezoneOffset } from '@/libs/dates'

interface TransactionsFiltersProps {
  filter: SearchFilterGeneric<TransactionsFilter>
}

export const TransactionsFilters = ({ filter }: TransactionsFiltersProps) => {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { game } = useCurrentGame()
  const [presets] = useState(createPresets())

  const openReportModal = useModal<GenerateReportDialogProps>(props => <GenerateReportDialog {...props} />)
  const countryGroups = useCountryGroups()

  const statusItems: SelectFilterItem[] = [
    { children: t(`transactions-table.status.${PaymentStatus.Done}`), value: PaymentStatus.Done },
    { children: t(`transactions-table.status.${PaymentStatus.Canceled}`), value: PaymentStatus.Canceled },
    { children: t(`transactions-table.status.${PaymentStatus.Chargeback}`), value: PaymentStatus.Chargeback },
    { children: t(`transactions-table.status.${PaymentStatus.Dispute}`), value: PaymentStatus.Dispute },
    { children: t(`transactions-table.status.${PaymentStatus.Expired}`), value: PaymentStatus.Expired },
    { children: t(`transactions-table.status.${PaymentStatus.Failed}`), value: PaymentStatus.Failed },
    { children: t(`transactions-table.status.${PaymentStatus.Created}`), value: PaymentStatus.Created },
    { children: t(`transactions-table.status.${PaymentStatus.Refunded}`), value: PaymentStatus.Refunded },
    { children: t(`transactions-table.status.${PaymentStatus.Rejected}`), value: PaymentStatus.Rejected },
    { children: t(`transactions-table.status.${PaymentStatus.Voided}`), value: PaymentStatus.Voided },
  ]

  const countryItems: SelectItem[] = Object.entries(getCountries(true)).map(([value, children]) => ({
    children: children as string,
    icon: () => <CountryFlag country={value} />,
    value: value as string,
  }))

  return (
    <div className="flex items-center gap-2">
      {canEdit && !game?.sandbox && (
        <Tooltip message={t('transactions-table.generate-report')}>
          <Button className="px-0" variant="outline" color="secondary" onClick={() => openReportModal()}>
            <Export className="text-text-secondary" />
          </Button>
        </Tooltip>
      )}

      <SelectFilter
        {...filter.registerSelect('countries', true)}
        items={countryItems}
        groups={countryGroups}
        title={t('transactions-table.filter.countries')}
      >
        {t('transactions-table.filter.countries')}
      </SelectFilter>

      <SelectFilter {...filter.registerSelect('status', true)} items={statusItems} enableSearch={false}>
        {t('transactions-table.status')}
      </SelectFilter>

      <InputDateRange
        clearable
        value={
          filter.values.start_at && filter.values.end_at
            ? {
                from: new Date(parseInt(filter.values.start_at as string)),
                to: new Date(parseInt(filter.values.end_at as string)),
              }
            : null
        }
        onChange={v => {
          const utcRange = {
            from: v?.from ? addTimezoneOffset(v.from) : undefined,
            to: v?.to ? addTimezoneOffset(v.to) : undefined,
          }

          filter.onChange({
            ...filter.values,
            start_at: utcRange?.from ? utcRange.from.getTime() : undefined,
            end_at: utcRange?.to ? utcRange.to.getTime() : undefined,
          })
        }}
        presets={presets}
      />
    </div>
  )
}
