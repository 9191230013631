import '../ToolTip/ToolTip.css'
import { HTMLAttributes, useContext } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import { useTheme } from '@mui/material'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import SingleValue, { SingleValueType } from '../SingleValue/SingleValue'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { getSerName } from '../../util'
import { ConversionHubValue, FilterType } from '../../../../api/dashboard'
import { Legend } from '../Legend'
import { formatNumber } from '../../../../util'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

function getTotalPercent(data: ConversionHubValue[]) {
  const totalUsers = data.map(it => it.users_count).reduce((acc, it) => acc + it, 0)
  const totalPaymentUsers = data.map(it => it.payment_users_count).reduce((acc, it) => acc + it, 0)
  return totalUsers > 0 ? (100 * totalPaymentUsers) / totalUsers : 0
}

interface ConversionGameHubProps extends HTMLAttributes<HTMLDivElement> {}

const ConversionGameHubChart = (props: ConversionGameHubProps) => {
  const theme = useTheme()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { t } = useTranslation()
  const loading = !context.data.conversion_game_hub_first_purchase_by_date
  const data = context.data.conversion_game_hub_first_purchase_by_date?.data || []
  const prevData = context.data.conversion_game_hub_first_purchase_by_date?.prev_data || []

  const totalMeasure = {
    value: getTotalPercent(data),
    prev_value: getTotalPercent(prevData),
  }

  const serName = getSerName(context)

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let usersCount = data[params[0].dataIndex].users_count
        let paymentUsersCount = data[params[0].dataIndex].payment_users_count

        let prevUsersCount = prevData[params[0].dataIndex].users_count
        let prevPaymentUsersCount = prevData[params[0].dataIndex].payment_users_count
        let prevDate = t(
          'intl.DateTime',
          getDateCategoryParams(prevData[params[0].dataIndex].date, context.granularity),
        )

        let prevHtml = params[1]
          ? `<div class="chart-tooltip--title mt-2">${prevDate}</div>
              <div class="chart-tooltip--separator"></div>
              <div class="chart-tooltip--subtitle w-full justify-between">
                <span>${i18next.t('dashboard.game-hub-conversion.users_count')}</span>
                <span>${formatNumber(prevUsersCount)}</span>
              </div>
              <div class="chart-tooltip--subtitle w-full justify-between">
                <span>${i18next.t('dashboard.game-hub-conversion.payment_users_count')}</span>
                <span>${formatNumber(prevPaymentUsersCount)}</span>
              </div>
              <div class="chart-tooltip--subtitle w-full justify-between">
               <span>${i18next.t('dashboard.conversion')}</span>
              <span>${params[1].value.toFixed(2)}%</span>
            </div>`
          : ''

        return `<div class="chart-tooltip" style="height: auto; min-width: 212px">
                  <div class="chart-tooltip--title">${params[0].axisValue}</div>
                   <div class="chart-tooltip--separator"></div>
                  <div class="chart-tooltip--subtitle w-full justify-between">
                      <div>${i18next.t('dashboard.game-hub-conversion.users_count')}</div>
                      <div>${formatNumber(usersCount)}</div>
                  </div>
                  <div class="chart-tooltip--subtitle w-full justify-between">
                      <span>${i18next.t('dashboard.game-hub-conversion.payment_users_count')}</span>
                      <span>${formatNumber(paymentUsersCount)}</span>
                  </div>
                  <div class="chart-tooltip--subtitle w-full justify-between">
                      <span>${i18next.t('dashboard.conversion')}</span>
                      <span>${params[0].value.toFixed(2)}%</span>
                  </div>
                  
                  ${prevHtml}
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: 0,
      right: '5%',
      top: 0,
      bottom: 10,
      containLabel: true,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: data.map(it => t('intl.DateTime', getDateCategoryParams(it.date, context.granularity))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: serName,
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        color: '#0EA5E9',
        lineStyle: {
          color: '#0EA5E9',
          width: 3,
        },
        smooth: 0.05,
        data: data.map(it => (it.users_count > 0 ? (100 * it.payment_users_count) / it.users_count : 0)),
      },
      context.type != FilterType.Today && {
        name: i18next.t('dashboard.revenue.prev'),
        type: 'line',
        showSymbol: false,
        smooth: 0.1,
        color: 'rgba(14, 165, 233, 0.2)',
        data: prevData.map(it => (it.users_count > 0 ? (100 * it.payment_users_count) / it.users_count : 0)),
        lineStyle: {
          color: '#0EA5E9',
          type: 'dashed',
          opacity: 0.3,
        },
      },
    ],
  }

  return (
    <DashboardCard
      title={i18next.t('dashboard.game-hub-conversion')}
      tooltip={i18next.t('dashboard.game-hub-conversion.tooltip')}
      style={props.style}
      data-testid="dashboard/chart/conversation"
    >
      {loading ? (
        <LoaderContainer style={{ height: '382px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <SingleValue value={totalMeasure} label={''} type={SingleValueType.percent} />
          <div>
            <ReactECharts
              option={options}
              style={{
                height: '290px',
                width: '100%',
              }}
            />
            <Legend
              items={[
                {
                  name: serName,
                  classColor: 'bg-fg-brand-primary',
                  type: 'line',
                },
              ]}
            />
          </div>
        </>
      )}
    </DashboardCard>
  )
}

export default ConversionGameHubChart
