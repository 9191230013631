import { ComponentProps, Ref, forwardRef, useState } from 'react'
import { PropertyCheckboxControl } from '@/ui'
import { cn } from '@dashboard/ui'

interface PropertyControlSwitchProps<V> {
  orientation?: 'horizontal' | 'vertical'
  size?: ComponentProps<typeof PropertyCheckboxControl>['size']
  left: Omit<ComponentProps<typeof PropertyCheckboxControl>, 'value' | 'onChange'> & { value: V }
  right: Omit<ComponentProps<typeof PropertyCheckboxControl>, 'value' | 'onChange'> & { value: V }
  value: V
  onChange: (value: V) => void
}

export const PropertyControlSwitch = forwardRef(function <V>(
  { orientation = 'horizontal', size = 'md', left, right, value, onChange }: PropertyControlSwitchProps<V>,
  ref: Ref<HTMLButtonElement>,
) {
  const [selected, setSelected] = useState<typeof value>(value)

  return (
    <div
      className={cn(
        'flex w-full gap-3',
        orientation === 'horizontal' && 'flex-row',
        orientation === 'vertical' && 'flex-col',
      )}
    >
      <PropertyCheckboxControl
        ref={ref}
        className={cn(orientation === 'horizontal' && 'w-1/2')}
        size={size}
        label={left.label}
        desc={left.desc}
        value={left.value === selected}
        onChange={() => {
          onChange(left.value)
          setSelected(left.value)
        }}
      />
      <PropertyCheckboxControl
        ref={ref}
        className={cn(orientation === 'horizontal' && 'w-1/2')}
        size={size}
        label={right.label}
        desc={right.desc}
        value={right.value === selected}
        onChange={() => {
          onChange(right.value)
          setSelected(right.value)
        }}
      />
    </div>
  )
})
