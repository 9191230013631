import { Drawer, DrawerContent, DrawerTopbar, FieldGroup } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import {
  LimitedStockSettings,
  LimitedStockType,
  PeriodType,
  RotationSettings,
  RotationType,
  StoreRead,
  StoreType,
  WebhookEventType,
} from '@/api/dashboard'
import { AghanimIcon, RefreshIcon2, Shuffle } from '@/icons'
import { Button, FormErrorMessage, Input, Select, Textarea, formatHookFormErrors } from '@dashboard/ui'
import { SelectDateTime } from '@/components/ui/SelectDateTime'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'
import { MAX_NAME_LENGTH } from '@/Settings'
import { useQuery } from '@tanstack/react-query'
import { webhooksQuery } from '@/layouts/webhooks'
import { useParams } from 'react-router-dom'

export const GeneralStoreSettings = (props: {
  onClose: () => void
  store: StoreRead
  onApplied: (store: StoreRead) => void
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { canEdit } = useCurrentUser()
  const { t } = useTranslation()
  const { data: webhooks = [] } = useQuery(webhooksQuery(companyId, gameId))
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    register,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<StoreRead>({
    values: props.store,
  })

  const [type, rotation_settings] = watch(['type', 'rotation_settings'])

  const onApplyClick = (data: StoreRead) => {
    if (data.type !== StoreType.Rotation) {
      data.rotation_settings = null as unknown as RotationSettings
    }

    if (data.type !== StoreType.LimitedStock) {
      data.limited_stock_settings = null as unknown as LimitedStockSettings
    }

    if (data.start_at && data.end_at) {
      if (data.start_at >= data.end_at) {
        setError('start_at', { message: t('validation.start_date') })
        return
      }
    }

    props.onApplied(data)
  }

  const renderDivider = () => {
    return <div className="my-[18px] w-full border border-border-secondary" />
  }

  const renderRotationSettings = () => {
    return (
      <>
        {renderDivider()}

        <FieldGroup label={t('store.rotation.type')} tooltip={t('store.rotation.type.tooltip')}>
          <Controller
            control={control}
            name="rotation_settings.type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                disabled={!canEdit}
                {...field}
                items={Object.values(RotationType).map(it => ({
                  value: it,
                  children: t(`store.rotation.type.${it}`),
                  caption: t(`store.rotation.type.${it}.desc`),
                  icon: (it == RotationType.RoundRobin ? RefreshIcon2 : Shuffle) as unknown as AghanimIcon,
                }))}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={t('store.rotation.count')} tooltip={t('store.rotation.count.tooltip')}>
          <Input
            disabled={!canEdit}
            type="number"
            {...register('rotation_settings.count', {
              required: t('validation.required'),
              min: {
                value: 1,
                message: t('validation.min-no-field', { value: 1 }),
              },
            })}
            {...formatHookFormErrors(errors, 'rotation_settings.count')}
          />
        </FieldGroup>

        <div className="flex gap-[18px]">
          <div className="w-1/2">
            <FieldGroup label={t('store.rotation.change-items')}>
              <Input
                disabled={!canEdit}
                type="number"
                {...register('rotation_settings.period_value', {
                  required: t('validation.required'),
                  min: {
                    value: 1,
                    message: t('validation.min-no-field', { value: 1 }),
                  },
                })}
                extraRight={{
                  icon: <div className="flex items-center whitespace-nowrap">{t('store.rotation.period_value')}</div>,
                }}
              />
            </FieldGroup>
          </div>
          <div className="w-1/2">
            <FieldGroup label={t('store.rotation.period')}>
              <Controller
                control={control}
                name="rotation_settings.period_type"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    disabled={!canEdit}
                    {...field}
                    items={Object.values(PeriodType)
                      .filter(it => it !== PeriodType.Second)
                      .map(value => ({
                        children: t(`store.rotation.period.type.${value}`),
                        value: value,
                      }))}
                  />
                )}
              />
            </FieldGroup>
          </div>
        </div>
        <ErrorMessage
          name="rotation_settings.period_value"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </>
    )
  }

  const renderLimitedStockSettings = () => {
    return (
      <>
        {renderDivider()}

        <FieldGroup label={t('store.limited_stock.type')}>
          <Controller
            control={control}
            name="limited_stock_settings.type"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Select
                disabled={!canEdit}
                {...field}
                items={Object.values(LimitedStockType).map(value => ({
                  children: t(`store.limited_stock.type.${value}`),
                  caption: t(`store.limited_stock.type.${value}.desc`),
                  value: value,
                }))}
                {...formatHookFormErrors(errors, 'limited_stock_settings.type')}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={t('store.limited_stock.total-quantity')}>
          <Input
            disabled={!canEdit}
            type="number"
            {...register('limited_stock_settings.total_quantity', {
              required: t('validation.required'),
              min: {
                value: 1,
                message: t('validation.min-no-field', { value: 1 }),
              },
            })}
            {...formatHookFormErrors(errors, 'limited_stock_settings.total_quantity')}
          />
        </FieldGroup>
      </>
    )
  }

  const renderTimelimitedSettings = () => {
    return (
      <>
        <FieldGroup label={t('store.start_at')}>
          <Controller
            control={control}
            name="start_at"
            rules={{ required: type == StoreType.LimitedStock ? t('validation.required') : false }}
            render={({ field }) => <SelectDateTime {...field} />}
          />
          <ErrorMessage
            name="start_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('store.end_at')}>
          <Controller
            control={control}
            name="end_at"
            rules={{ required: type == StoreType.LimitedStock ? t('validation.required') : false }}
            render={({ field }) => <SelectDateTime {...field} />}
          />
          <ErrorMessage
            name="end_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </>
    )
  }

  const renderWebhookSettings = () => {
    return (
      <>
        <FieldGroup label={t('store.type.webhook')}>
          <Controller
            control={control}
            name="settings.webhook_settings.webhook_id"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                items={webhooks
                  .filter(it => it.events.includes(WebhookEventType.StoreGet))
                  .map(it => ({
                    value: it.id,
                    children: it.url,
                  }))}
              />
            )}
          />
          <ErrorMessage
            name="settings.webhook_settings.webhook_id"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </>
    )
  }

  return (
    <Drawer open={true} width={500}>
      <DrawerTopbar>
        <h2 className="text-title-t5">{t('store.settings')}</h2>
        <div className="ml-auto flex gap-2">
          <Button
            onClick={props.onClose}
            color="secondary"
            data-testid="close-store-settings"
            variant="outline"
            size="sm"
          >
            {t('close')}
          </Button>
          <Button onClick={handleSubmit(onApplyClick)} color="primary" data-testid="close-store-settings" size="sm">
            {t('apply')}
          </Button>
        </div>
      </DrawerTopbar>
      <DrawerContent className="h-full w-[500px] overflow-auto">
        <FieldGroup label={t('store.name')}>
          <Input
            maxLength={MAX_NAME_LENGTH}
            disabled={!canEdit}
            autoFocus
            {...register('name', { required: t('validation.required') })}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('store.description')}>
          <Textarea disabled={!canEdit} {...register('description')} {...formatHookFormErrors(errors, 'description')} />
        </FieldGroup>

        <FieldGroup label={t('store.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                disabled={!canEdit}
                value={field.value}
                onChange={v => {
                  if (v == StoreType.Rotation && !rotation_settings) {
                    setValue('rotation_settings', {
                      type: RotationType.Random,
                      count: 1,
                      period_value: 1,
                      period_type: PeriodType.Day,
                    })
                  }

                  if (v != StoreType.LimitedStock) {
                    clearErrors(['start_at', 'end_at'])
                  }

                  field.onChange(v)
                }}
                items={Object.values(StoreType).map(it => ({
                  value: it,
                  children: t(`store.type.${it}`),
                  caption: t(`store.type.${it}.desc`),
                }))}
              />
            )}
          />
        </FieldGroup>

        {type != StoreType.Default && renderTimelimitedSettings()}
        {type == StoreType.Rotation && renderRotationSettings()}
        {type == StoreType.Webhook && renderWebhookSettings()}
        {type == StoreType.LimitedStock && renderLimitedStockSettings()}
      </DrawerContent>
    </Drawer>
  )
}
