import { useTranslation } from 'react-i18next'
import { getDateCategoryParams, getTimeParams } from '@/libs/dates'
import { DataGranularity, TransactionStat } from '@/api/dashboard'
import { formatNumber } from '@/util'
import { Skeleton, cn } from '@dashboard/ui'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

export const TransactionStatChart = ({
  data,
  total,
  isLoading,
  className,
  status,
  granularity = DataGranularity.Day,
}: {
  data: TransactionStat[]
  total?: TransactionStat
  isLoading: boolean
  className?: string
  color?: string
  granularity?: DataGranularity
  status?: string
}) => {
  const { t } = useTranslation()

  let serieName = t('transactions.total-transactions')
  let serieColor = '#0EA5E9'

  switch (status) {
    case 'done':
      serieColor = '#4ADE80'
      break
    case 'created':
      serieColor = '#475569'
      break
    default:
      if (status && !status.includes(',')) {
        serieColor = '#EF4444'
      }
      break
  }

  if (status) {
    if (status.includes(',')) {
      serieName = t(`count`)
    } else {
      serieName = t(`transactions-table.status.${status}`)
    }
  }

  const viewData = isLoading
    ? [10, 20, 10, 20, 10, 15].map(total => ({
        category: '...',
        total,
      }))
    : data

  const maxValue = Math.max(Math.max(...viewData.map(it => it.total || 0)), 10)

  if (isLoading) {
    serieColor = '#F1F5F9'
  }

  const options = {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#fff',
      type: 'line',
      height: '110px',
    },
    tooltip: {
      enabled: !isLoading,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderColor: 'transparent',
      borderRadius: 6,
      padding: 10,
      shared: true,
      style: {
        color: '#fff',
      },
      useHTML: true,
      formatter: function () {
        // @ts-ignore
        const categoryName = t('intl.DateTime', getDateCategoryParams(this.key, granularity))
        // @ts-ignore
        const total = this.y
        return `<div>
            <div class="text-caption-md border-b pb-2 min-w-[100px]" style="border-color: rgba(255, 255, 255, 0.2)">${categoryName}</div><br/>
            <div class="text-caption-sm mt-[-6px] flex flex-col gap-2">
              <div class="flex items-center gap-1.5"><div class="size-2.5 rounded" style="background-color: ${serieColor}"></div>${serieName} <div class="ml-auto">${total}</div></div>
            </div>
           </div>
         `
      } as unknown,
    },
    legend: {
      enabled: false,
    },
    xAxis: [
      {
        tickColor: '#F1F5F9',
        tickWidth: 1,
        tickLength: 4,
        categories: viewData.map(it => it.category),
        labels: {
          rotation: 0,
          step: data.length > 15 ? Math.floor(data.length / 10) : 1,
          y: 14,
          style: {
            fontSize: '8px',
            color: '#94A3B8',
            fontWeight: '400',
            fontFamily: 'InterAg',
          },
          formatter: isLoading
            ? undefined
            : (function () {
                // @ts-ignore
                const v = this.value
                const fmt =
                  granularity == DataGranularity.Hour ? getTimeParams(v) : getDateCategoryParams(v, granularity)
                return t('intl.DateTime', fmt)
              } as unknown),
        },
        lineColor: '#F1F5F9',
      },
    ],
    yAxis: {
      opposite: true,
      min: 0,
      max: Math.round(maxValue + 5),
      gridLineWidth: 1,
      gridLineColor: '#F1F5F9',
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '8px',
          color: '#94A3B8',
          fontWeight: '400',
          fontFamily: 'InterAg',
        },
      },
      lineWidth: 1,
      lineColor: 'transparent',
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        type: 'spline',
      },
    },
    series: [
      {
        color: serieColor,
        type: 'spline',
        name: serieName,
        data: viewData.map(it => it.total),
        marker: {
          symbol: 'circle',
        },
      },
    ],
  }

  const renderValue = (color: string, value: number) => {
    return (
      <div className="flex items-center gap-1">
        <div
          className={`size-2.5 rounded`}
          style={{
            backgroundColor: color,
          }}
        />
        {formatNumber(value)}
      </div>
    )
  }

  return (
    <div className={cn('flex h-[124px] items-center gap-1.5 rounded-xl border border-border-primary p-1.5', className)}>
      <div className="flex h-full w-[140px] flex-col justify-between p-3" data-testid="transaction-stat/total-value">
        <div className="text-caption-sm text-text-quarterary-hover">{serieName}</div>
        <div className="text-caption-md font-semibold text-text-secondary">
          {isLoading || !total ? <Skeleton className="h-[14px] rounded-md" /> : renderValue(serieColor, total.total)}
        </div>
      </div>
      <div data-testid="transaction-stat/chart" className={cn('grow overflow-hidden', isLoading && 'animate-pulse')}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}
