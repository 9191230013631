import { AnyNode, ErrorInfo, EventActionNodeType } from './types'
import {
  AttrType,
  Campaign,
  CampaignEventType,
  ConditionNode,
  CouponType,
  CreateCouponNode,
  CreateItemBannerActionNode,
  CreateUserStoreSettingsNode,
  CreateVirtualSKUNode,
  GraphRoot,
  ItemDiscountOfferActionNode,
  Token,
  TokenType,
  WebhookEventType,
} from '../../api/dashboard'
import i18next from 'i18next'
import { DescContext } from './blocks/descriptions/types'
import { CalcPlayerAttributes, EMPTY_VALUE, OperatorType } from './expr/types'
import { dashboardClient } from '@/api'
import { getAttrType } from '@/layouts/campaigns/expr/getAttrType'
import { EditorMode, ICampaignContext } from '@/layouts/campaigns/Context'

function validateItemOfferActionNode(node: ItemDiscountOfferActionNode) {
  let errors: ErrorInfo[] = []

  if (!node.item_id && !node.use_event_item) {
    errors.push({
      field: 'item_id',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (!node.discount_percent && !node.bonus_percent) {
    errors.push({
      field: 'discount_percent',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.discount_percent && (node.discount_percent < 0 || node.discount_percent > 99)) {
    errors.push({
      field: 'discount_percent',
      message: i18next.t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error'),
    })
  }

  return errors
}

function validatCreateVirtualSKUActionNode(node: CreateVirtualSKUNode) {
  let errors: ErrorInfo[] = []

  if (!node.item_id && !node.use_event_item) {
    errors.push({
      field: 'item_id',
      message: i18next.t('campaign.field-required'),
    })
  }
  return errors
}

function validateCreateUserStoreSettings(node: CreateUserStoreSettingsNode) {
  if (!node.store_ids?.length) {
    return [
      {
        message: i18next.t('campaign.field-required'),
        field: 'store_ids',
      },
    ]
  }

  return []
}

function validateConditionNode(node: ConditionNode) {
  if (node.expr.tokens?.length == 0) {
    return [
      {
        message: i18next.t('campaign.condition.error.empty-condition'),
        field: '',
      },
    ]
  }

  return []
}

function validateCreateCouponNode(node: CreateCouponNode) {
  let errors: ErrorInfo[] = []

  if (!node.type) {
    errors.push({
      field: 'type',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.code_mask) {
    if (node.code_mask.split('*').length != 2) {
      errors.push({
        field: 'code_mask',
        message: i18next.t('campaign.field-incorrect'),
      })
    }
  }

  if (node.is_stackable_with_bonus_coupons && !node.stackable_bonus_limit_percent) {
    errors.push({
      field: 'stackable_bonus_limit_percent',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.is_stackable_with_discount_coupons && !node.stackable_discount_limit_percent) {
    errors.push({
      field: 'stackable_discount_limit_percent',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.applicable_item_ids && !node.applicable_item_ids.length) {
    errors.push({
      field: 'applicable_item_ids',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.payment_method_ids && !node.payment_method_ids.length) {
    errors.push({
      field: 'payment_method_ids',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (node.min_amount != undefined && isNaN(node.min_amount)) {
    errors.push({
      field: 'min_amount',
      message: i18next.t('campaign.field-required'),
    })
  }

  switch (node.type) {
    case CouponType.Bonus:
      if (!node.bonus_percent) {
        errors.push({
          field: 'bonus_percent',
          message: i18next.t('campaign.field-required'),
        })
      }
      break
    case CouponType.Discount:
      if (!node.discount_percent) {
        errors.push({
          field: 'discount_percent',
          message: i18next.t('campaign.field-required'),
        })
      } else if (node.discount_percent < 0 || node.discount_percent > 99) {
        errors.push({
          field: 'discount_percent',
          message: i18next.t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error'),
        })
      }
      break
    case CouponType.FreeItem:
      if (!node.item_id && !node.use_event_item) {
        errors.push({
          field: 'item_id',
          message: i18next.t('campaign.field-required'),
        })
      }
      break
  }
  return errors
}

function validateCreateItemBannerActionNode(node: CreateItemBannerActionNode) {
  let errors = [] as {
    field: string
    message: string
  }[]

  if (!node.link_created_offer && !node.config.featured_item_id) {
    errors.push({
      field: 'config.featured_item_id',
      message: i18next.t('campaign.field-required'),
    })
  }

  if (!node.page_id && !node.page_slug) {
    errors.push({
      field: 'page_id',
      message: i18next.t('campaign.field-required'),
    })
  }

  return errors
}

function notImlemented() {
  return []
}

export const NODE_VALIDATORS = {
  [EventActionNodeType.CreateCouponNode]: validateCreateCouponNode,
  [EventActionNodeType.CreateVirtualSKUNode]: validatCreateVirtualSKUActionNode,

  [EventActionNodeType.ItemOfferActionNode]: validateItemOfferActionNode,
  [EventActionNodeType.ItemDiscountOfferActionNode]: validateItemOfferActionNode,
  [EventActionNodeType.ItemBonusOfferActionNode]: validateItemOfferActionNode,

  [EventActionNodeType.CreateItemBannerActionNode]: validateCreateItemBannerActionNode,
  [EventActionNodeType.AddToSegmentNode]: notImlemented,
  [EventActionNodeType.EmailActionNode]: notImlemented,
  [EventActionNodeType.MobilePushActionNode]: notImlemented,
  [EventActionNodeType.BrowserPushNotificationActionNode]: notImlemented,
  [EventActionNodeType.MobilePopupActionNode]: notImlemented,
  [EventActionNodeType.BrowserPopupNotificationActionNode]: notImlemented,
  [EventActionNodeType.WebhookActionNode]: notImlemented,
  [EventActionNodeType.ConditionNode]: validateConditionNode,
  [EventActionNodeType.SplitNode]: notImlemented,
  [EventActionNodeType.TimerNode]: notImlemented,
  [EventActionNodeType.CreateUserStoreSettingsNode]: validateCreateUserStoreSettings,
} as unknown as { [key: string]: (node: AnyNode, context?: DescContext) => ErrorInfo[] }

export const validateConditionTokens = (tokenGroups: Token[][], context?: ICampaignContext) => {
  if (tokenGroups.length == 0) {
    return { error: i18next.t('campaign.condition.error.empty-condition'), newErrorTokens: [] }
  }

  if (!context) {
    context = {
      campaign: {
        type: CampaignEventType.CustomEvent,
      } as unknown as Campaign,
      playerCustomAttributes: [],
      readOnly: false,
      historyMode: false,
      mode: EditorMode.StoreItem,
    } as unknown as ICampaignContext
  }

  let newErrorTokens: Token[] = []
  for (let g of tokenGroups) {
    let operator = g[1]

    for (let idx = 0; idx < g.length; idx++) {
      let t = g[idx]

      if (t.value == EMPTY_VALUE || t.value == null || t.value === '') {
        if (idx == 2) {
          if (operator.value != OperatorType.is_none && operator.value != OperatorType.is_not_none) {
            newErrorTokens.push(t)
          }
        } else {
          newErrorTokens.push(t)
        }
        continue
      }

      switch (t.type) {
        case TokenType.Number:
          if (isNaN(t.value as number)) {
            newErrorTokens.push(t)
          }
          break
        case TokenType.List:
          let attrType = getAttrType(g[0], context)
          if (attrType == AttrType.Number) {
            let list = t.value as number[]
            if (list.some(v => isNaN(v) || v == null)) {
              newErrorTokens.push(t)
            } else if (list[0] > list[1]) {
              return {
                error: i18next.t('campaign.condition.error.from-more-to'),
                errorTokens: [t],
              }
            }
          }
          break
      }
    }
  }

  return {
    error: newErrorTokens.length > 0 ? i18next.t('campaign.condition.error.empty-values') : '',
    errorTokens: newErrorTokens,
  }
}

export const validateWebhooks = async (
  graph: GraphRoot,
  companyId: string,
  gameId: string,
): Promise<WebhookEventType[]> => {
  let reqTypes: WebhookEventType[] = []

  for (let id in graph.nodes) {
    let node = graph.nodes[id]

    switch (node.model_type) {
      case EventActionNodeType.ConditionNode:
        if (!node.expr.tokens?.length) {
          continue
        }

        let playerIsIdleAttr = node.expr.tokens.find(
          t => t.type == TokenType.Attribute && t.value == CalcPlayerAttributes.player_is_idle,
        )

        if (playerIsIdleAttr) {
          reqTypes.push(WebhookEventType.PlayerIsIdle)
        }
        break
      case EventActionNodeType.MobilePopupActionNode:
        reqTypes.push(WebhookEventType.IngamePush)
        break
      case EventActionNodeType.MobilePushActionNode:
        reqTypes.push(WebhookEventType.IngamePopup)
        break
    }
  }

  if (reqTypes.length) {
    let { data } = await dashboardClient.v1.getWebhooks(companyId, gameId)

    let res: WebhookEventType[] = []
    for (let t of reqTypes) {
      if (!data.find(w => w.events.includes(t))) {
        if (!res.includes(t)) {
          res.push(t)
        }
      }
    }
    return res
  }

  return []
}
