import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Edit03, Menu, MenuItem, MenuItemDivider, PopperMenuProps, Trash, useModal } from '@dashboard/ui'
import { CashbackRewardRead } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import { SkuCashbackRewardsModalConfirmDelete } from './SkuCashbackRewardsModalConfirmDelete'
import { SKU_CASHBACK_REWARDS_EDIT_PATH } from '../paths'

interface SkuCashbackRewardsMenuProps extends PopperMenuProps {
  reward: CashbackRewardRead
}

export const SkuCashbackRewardsMenu = forwardRef<HTMLDivElement, SkuCashbackRewardsMenuProps>(
  ({ reward, ...rest }, ref) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { generatePath } = useGenerateCompanyPath()

    const openDeleteModal = useModal(props => <SkuCashbackRewardsModalConfirmDelete {...props} reward={reward} />)

    const onClickEdit = () => {
      navigate(generatePath(SKU_CASHBACK_REWARDS_EDIT_PATH, { rewardId: reward.id }))
      rest?.onClose()
    }

    const onClickDelete = () => {
      openDeleteModal()
      rest?.onClose()
    }

    return (
      <Menu ref={ref} {...rest}>
        <MenuItem icon={Edit03} onClick={onClickEdit}>
          {t('edit')}
        </MenuItem>
        <MenuItemDivider />
        <MenuItem variant="danger" icon={Trash} onClick={onClickDelete}>
          {t('delete')}
        </MenuItem>
      </Menu>
    )
  },
)
