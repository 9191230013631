import { infiniteQueryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export interface l10nGlossariesInfiniteQueryParams {
  /**
   * Locale id
   * Filter by locale
   */
  locale_id?: string
  /**
   * Q
   * Filter by search query
   */
  q?: string
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 300
   * @default 10
   */
  limit?: number
  /**
   * Starting After
   * The cursor for pagination
   */
  starting_after?: string
  /**
   * Ending Before
   * The cursor for pagination
   */
  ending_before?: string
}

/**
 *
 * @param companyId
 * @param gameId
 * @param locale_id
 * @param params
 * @returns
 */
export const l10nGlossariesInfiniteQuery = (
  companyId: string,
  gameId: string,
  { locale_id, ...params }: l10nGlossariesInfiniteQueryParams,
) =>
  infiniteQueryOptions({
    queryKey: [gameId, 'glossaries', locale_id, params],
    queryFn: async ({ pageParam }) => {
      const { data } = await dashboardClient.v1.getTranslationGlossaries(companyId, gameId, {
        ...params,
        locale_id,
        starting_after: pageParam || undefined,
      })
      return data
    },
    initialPageParam: '',
    getNextPageParam: lastPage => {
      return lastPage.next_cursor || null
    },
  })
