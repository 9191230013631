export const HOME_PATH = '/company/:companyId/:gameId'

export const LIVEOPS_PATH = '/company/:companyId/:gameId/campaigns'
export const LIVEOPS_PATH_TABLE = '/company/:companyId/:gameId/campaigns/table'
export const LIVEOPS_SETTINGS_PATH = '/company/:companyId/:gameId/campaigns/settings'
export const LIVEOPS_DETAILS_PATH = '/company/:companyId/:gameId/campaigns/:eventId'
export const LIVEOPS_ITEM_DETAILS_PATH = '/company/:companyId/:gameId/campaigns/:eventId/:userCampaignId'
export const STORE_PROMOTIONS_PATH = '/company/:companyId/:gameId/campaigns/promotions'
export const STORE_PROMOTIONS_PATH_DETAILS = '/company/:companyId/:gameId/campaigns/promotions/:promotionId'
export const STORE_PATH = '/company/:companyId/:gameId/campaigns/store'
export const STORE_RULES_PATH = '/company/:companyId/:gameId/campaigns/store/rules'
export const STORE_ITEMS_PATH = '/company/:companyId/:gameId/campaigns/store/:storeId'

export const ENGAGEMENT_PATH = '/company/:companyId/:gameId/engagement'
export const ENGAGEMENT_LOYALTY_PATH = `${ENGAGEMENT_PATH}/loyalty`
export const ENGAGEMENT_DAILY_REWARDS_PATH = `${ENGAGEMENT_PATH}/daily-rewards`
export const ENGAGEMENT_DAILY_REWARDS_PROGRAM_PATH = `${ENGAGEMENT_DAILY_REWARDS_PATH}/:programId`
export const ENGAGEMENT_CREATORS_PATH = `${ENGAGEMENT_PATH}/creators`

export const GAME_ATTRS_PATH = '/company/:companyId/:gameId/game/attributes'
export const GAME_SEGMENTS_PATH = '/company/:companyId/:gameId/campaigns/segments'
export const GAME_SEGMENT_GROUPS_PATH = '/company/:companyId/:gameId/campaigns/segments/groups'
export const GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH = '/company/:companyId/:gameId/campaigns/segments/groups/:groupId'

export const SKU = '/company/:companyId/:gameId/sku'

export const SKU_ITEMS_PATH = '/company/:companyId/:gameId/sku/items'
export const SKU_ITEMS_NEW_PATH = '/company/:companyId/:gameId/sku/items/new'
export const SKU_ITEMS_DETAILS_PATH = '/company/:companyId/:gameId/sku/items/:itemId'
export const SKU_ITEMS_SETTINGS_PATH = '/company/:companyId/:gameId/sku/items/settings'
export const SKU_ITEMS_SETTINGS_CATEGORIES_PATH = '/company/:companyId/:gameId/sku/items/settings/categories'
export const SKU_ITEMS_SETTINGS_PROPERTIES_PATH = '/company/:companyId/:gameId/sku/items/settings/properties'

export const SKU_BUNDLE_ITEMS_PATH = '/company/:companyId/:gameId/sku/bundles'
export const SKU_BUNDLE_ITEMS_NEW_PATH = '/company/:companyId/:gameId/sku/bundles/new'
export const SKU_BUNDLE_ITEMS_DETAILS_PATH = '/company/:companyId/:gameId/sku/bundles/:itemId'

export const SKU_CODES_PATH = '/company/:companyId/:gameId/sku/codes'
export const SKU_CODES_DETAILS_PATH = '/company/:companyId/:gameId/sku/codes/:codeId'

export const LOCALIZATION_PATH = '/company/:companyId/:gameId/localization'
export const LOCALIZATION_TRANSLATIONS_PATH = '/company/:companyId/:gameId/localization/translations'
export const LOCALIZATION_TRANSLATIONS_LOCALE_PATH = '/company/:companyId/:gameId/localization/translations/:localeId'
export const LOCALIZATION_TRANSLATION_PATH =
  '/company/:companyId/:gameId/localization/translations/:localeId/:translationId'
export const LOCALIZATION_GLOSSARY_PATH = '/company/:companyId/:gameId/localization/glossary'
export const LOCALIZATION_GLOSSARY_LOCALE_PATH = '/company/:companyId/:gameId/localization/glossary/:localeId'

export const TRANSACTIONS_PATH = '/company/:companyId/:gameId/transactions'
export const TRANSACTIONS_DETAILS_PATH = '/company/:companyId/:gameId/transactions/:paymentNumber'

export const GAME_PATH = '/company/:companyId/:gameId/game'
export const GAME_PLAYERS_PATH = '/company/:companyId/:gameId/game/players'
export const GAME_PLAYERS_DETAILS_PATH = '/company/:companyId/:gameId/game/players/:userId'

export const GAME_WEBHOOKS_PATH = '/company/:companyId/:gameId/game/webhooks'
export const GAME_WEBHOOKS_NEW_PATH = '/company/:companyId/:gameId/game/webhooks/new'
export const GAME_WEBHOOKS_DETAIL_PATH = '/company/:companyId/:gameId/game/webhooks/:webhookId'
export const GAME_WEBHOOKS_EDIT_PATH = '/company/:companyId/:gameId/game/webhooks/:webhookId/edit'

export const HUB_PATH = '/company/:companyId/:gameId/hub'
export const HUB_THEMES_PATH = '/company/:companyId/:gameId/hub/themes'
export const HUB_THEMES_EDITOR_PATH = '/company/:companyId/:gameId/hub/themes/:wbId'
export const HUB_SETTINGS_PATH = '/company/:companyId/:gameId/hub/settings'
export const HUB_NEWS_PATH = '/company/:companyId/:gameId/hub/news'
export const HUB_NEWS_GROUP_PATH = '/company/:companyId/:gameId/hub/news/:group?'
export const HUB_NEWS_DETAIL_PATH = '/company/:companyId/:gameId/hub/news/:group/:newsId'
export const HUB_LEADERBOARD_PATH = '/company/:companyId/:gameId/hub/leaderboard'
export const HUB_LEADERBOARD_DETAIL_PATH = '/company/:companyId/:gameId/hub/leaderboard/:leaderboardId'
export const HUB_ACHIEVEMENTS_PATH = '/company/:companyId/:gameId/hub/achievements'
export const HUB_ACHIEVEMENTS_DETAIL_PATH = '/company/:companyId/:gameId/hub/achievements/:achievementId'
export const HUB_PAGES_PATH = '/company/:companyId/:gameId/hub/pages'
export const HUB_PAGES_PARENT_PATH = `${HUB_PAGES_PATH}/:parent/:child?`
export const HUB_PAGES_DETAIL_PATH = `${HUB_PAGES_PATH}/:parent/:child?/pages/:pageId`

export const GET_STARTED_PATH = '/company/:companyId/:gameId/get-started'
export const GET_STARTED_VERIFY_ACCOUNT_PATH = '/company/:companyId/:gameId/get-started/verify-account'

export const APP_CONNECT_PATH = '/company/:companyId/:gameId/app-connect'
export const APP_CONNECT_PLUGIN_DETAIL_PATH = '/company/:companyId/:gameId/app-connect/:pluginSlug'

export const EXPERTS_PATH = '/company/:companyId/:gameId/experts'
export const FEATURES_PATH = '/company/:companyId/:gameId/features'

export const DOCUMENT_PATH = '/company/:companyId/:gameId/documents'
export const PAYOUTS_PATH = '/company/:companyId/:gameId/payouts'
export const TEAM_PATH = '/company/:companyId/:gameId/team'
export const COMPANY_SETTINGS_PATH = '/company/:companyId/:gameId/company-settings'
export const COMPANY_PAYMENT_METHODS = '/company/:companyId/:gameId/payment-methods'
export const GAME_SETTINGS = '/company/:companyId/:gameId/game-settings'
