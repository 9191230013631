import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useKey } from 'react-use'
import { ErrorMessage } from '@hookform/error-message'
import { Button, FormErrorMessage, Input, XClose, formatHookFormErrors } from '@dashboard/ui'
import { CashbackRewardCreate, CashbackRewardRead } from '@/api/dashboard'
import Divider from '@/components/Divider'
import {
  DrawerContent,
  DrawerSpinner,
  DrawerTopbar,
  DrawerTopbarSkeleton,
  FieldGroup,
  FieldSection,
  ImageUploader,
} from '@/ui'
import { useGenerateCompanyPath, usePydenticForm } from '@/libs/hooks'
import { dashboardClient } from '@/api'
import { S3Bucket } from '@/types'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'

interface SkuCashbackRewardsFormProps {
  reward?: CashbackRewardRead
  onSubmit: (data: CashbackRewardCreate) => Promise<void>
  onClose: () => void
}

export const SkuCashbackRewardsForm = ({ reward, onSubmit, onClose }: SkuCashbackRewardsFormProps) => {
  const { t } = useTranslation()
  const { t: tSkuCashback } = useTranslation('sku-cashback')
  const { companyId, gameId } = useGenerateCompanyPath()

  const [isImageUploading, setIsImageUploading] = useState(false)

  const {
    formState: { errors, isSubmitting, isDirty },
    register,
    control,
    handleSubmit,
  } = usePydenticForm<CashbackRewardCreate & { icon_url: string }>({
    defaultValues: {
      ...reward,
      from_usd: reward ? reward?.from_usd / 100 : undefined,
      icon_url: reward?.item.icon_url || '',
    },
  })

  useKey('Escape', onClose, undefined, [onClose])

  const onFormSubmit = handleSubmit(async data => {
    await dashboardClient.v1.updateItem(companyId, gameId, data.item_id, {
      icon_url: data.icon_url,
    })
    await onSubmit(data)
  })

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={onFormSubmit}>
      <DrawerTopbar>
        <div className="mr-auto font-nohemi text-[20px] font-medium tracking-[1px]">
          {reward ? tSkuCashback('form.title', { name: reward.item.name }) : `Add Cashback`}
        </div>
        <div className="flex items-start gap-3">
          <Button type="submit" loading={isSubmitting} disabled={!isDirty || isImageUploading}>
            {reward ? t('Save') : tSkuCashback('add')}
          </Button>
          <Button className="w-7 px-0" variant="outline" color="secondary" onClick={onClose}>
            <XClose />
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent>
        <FieldSection
          label={tSkuCashback('form.section.linked-item.label')}
          caption={tSkuCashback('form.section.linked-item.caption')}
        >
          <FieldGroup label={tSkuCashback('form.item')}>
            <Controller
              control={control}
              name="item_id"
              rules={{ required: t('validation.required') }}
              render={({ field }) => <SelectSkuItem {...field} isStackable={true} />}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
          <FieldGroup label={tSkuCashback('form.icon')}>
            <Controller
              name="icon_url"
              control={control}
              render={({ field }) => (
                <ImageUploader
                  {...field}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                    'image/gif': [],
                  }}
                  recommendedSize={[512, 512]}
                  bucket={S3Bucket.sku}
                  onLoadingChanged={setIsImageUploading}
                />
              )}
            />
            <ErrorMessage
              name="icon_url"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </FieldSection>

        <Divider />

        <FieldSection
          label={tSkuCashback('form.section.exchange-rate.label')}
          caption={tSkuCashback('form.section.exchange-rate.caption')}
        >
          <div className="grid grid-cols-2 gap-4 rounded-md bg-fg-secondary p-3">
            <div>
              <FieldGroup label={tSkuCashback('form.exchange-rate.from.label')}>
                <Input
                  {...register('from_usd', {
                    required: t('validation.required'),
                    min: {
                      value: 1,
                      message: t('validation.min-no-field', { value: 1 }),
                    },
                    max: {
                      value: 1_000_000,
                      message: t('validation.max-no-field', { value: 1_000_000 }),
                    },
                  })}
                  {...formatHookFormErrors(errors, 'from_usd')}
                  placeholder={tSkuCashback('form.exchange-rate.from.placeholder')}
                  extraLeft={{ icon: () => <span className="font-bold">$</span> }}
                  type="number"
                  step="0.01"
                />
              </FieldGroup>
            </div>
            <div>
              <FieldGroup label={tSkuCashback('form.exchange-rate.to.label')}>
                <Input
                  {...register('reward_points', {
                    required: t('validation.required'),
                    min: {
                      value: 1,
                      message: t('validation.min-no-field', { value: 1 }),
                    },
                    max: {
                      value: 1_000_000_000,
                      message: t('validation.max-no-field', { value: 1_000_000_000 }),
                    },
                  })}
                  {...formatHookFormErrors(errors, 'reward_points')}
                  placeholder={tSkuCashback('form.exchange-rate.to.placeholder')}
                  type="number"
                  step="0.01"
                />
              </FieldGroup>
            </div>
          </div>
        </FieldSection>
      </DrawerContent>
    </form>
  )
}

export const SkuCashbackRewardFormSkeleton = () => {
  return (
    <>
      <DrawerTopbarSkeleton />
      <DrawerContent className="h-full">
        <DrawerSpinner />
      </DrawerContent>
    </>
  )
}
