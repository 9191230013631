import { cloneElement, createElement } from 'react';
import { AghanimIconOrCustom } from '../../icons';

/**
 *
 * @param icon
 * @param size
 * @returns
 */
export const renderItemIcon = (icon: AghanimIconOrCustom, size: number) => {
  // @ts-ignore
  return icon.render ? createElement(icon, { size }) : cloneElement(icon(), { size });
};
