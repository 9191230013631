import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@dashboard/ui'

export const ClockExpiredOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 3C5.68629 3 3 5.68629 3 9C3 9.15108 2.9074 9.28721 2.76797 9.34541C2.66862 9.38687 2.57107 9.4318 2.47548 9.48004C2.11878 9.66003 1.94044 9.75003 1.82552 9.73473C1.71485 9.72 1.62844 9.66681 1.56543 9.57465C1.5 9.47894 1.5 9.31929 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C8.68071 16.5 8.52106 16.5 8.42535 16.4346C8.33319 16.3716 8.28 16.2852 8.26527 16.1745C8.24997 16.0596 8.33997 15.8812 8.51996 15.5245C8.5682 15.4289 8.61313 15.3314 8.65459 15.232C8.71279 15.0926 8.84892 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM9 4.2C9.41421 4.2 9.75 4.53579 9.75 4.95V8.53647L12.0354 9.67918C12.4059 9.86442 12.5561 10.3149 12.3708 10.6854C12.1856 11.0559 11.7351 11.2061 11.3646 11.0208L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.95C8.25 4.53579 8.58579 4.2 9 4.2ZM0.75 13.5C0.75 11.4289 2.42893 9.75 4.5 9.75C6.57107 9.75 8.25 11.4289 8.25 13.5C8.25 15.5711 6.57107 17.25 4.5 17.25C2.42893 17.25 0.75 15.5711 0.75 13.5ZM6.02275 12.7727C6.24242 12.5531 6.24242 12.1969 6.02275 11.9773C5.80308 11.7576 5.44692 11.7576 5.22725 11.9773L4.60607 12.5984C4.54749 12.657 4.45251 12.657 4.39393 12.5984L3.77275 11.9773C3.55308 11.7576 3.19692 11.7576 2.97725 11.9773C2.75758 12.1969 2.75758 12.5531 2.97725 12.7727L3.59844 13.3939C3.65702 13.4525 3.65702 13.5475 3.59844 13.6061L2.97725 14.2273C2.75758 14.4469 2.75758 14.8031 2.97725 15.0227C3.19692 15.2424 3.55308 15.2424 3.77275 15.0227L4.39393 14.4016C4.45251 14.343 4.54749 14.343 4.60607 14.4016L5.22725 15.0227C5.44692 15.2424 5.80308 15.2424 6.02275 15.0227C6.24242 14.8031 6.24242 14.4469 6.02275 14.2273L5.40156 13.6061C5.34298 13.5475 5.34298 13.4525 5.40156 13.3939L6.02275 12.7727Z"
        fill="#475569"
      />
    </IconBase>
  )
})
