import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup, FieldValidationMessage } from '@/ui'

import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import { urlRegexp } from '@/libs'
import { Input, Select, formatHookFormErrors } from '@dashboard/ui'

const IFrameBlockEditor = () => {
  const { t } = useTranslation()

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<Block>()
  const aspectRatio = watch('aspect_ratio')
  return (
    <div>
      <FieldGroup label={t('hub-editor.iframe.url')}>
        <Input
          placeholder="https://example.com"
          {...register('url', {
            required: i18next.t('validation.required'),
            pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') },
          })}
        />
        <ErrorMessage
          name="url"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.iframe.aspect-ratio')}>
        <Controller
          control={control}
          name="aspect_ratio"
          render={({ field }) => (
            <Select
              {...field}
              {...formatHookFormErrors(errors, 'aspect_ratio')}
              items={[
                { children: 'auto', value: 0 },
                {
                  children: '1:1',
                  value: 1,
                },
                {
                  children: '4:3',
                  value: 4 / 3,
                },
                {
                  children: '16:9',
                  value: 16 / 9,
                },
              ]}
            />
          )}
        />
      </FieldGroup>
      {!aspectRatio && (
        <>
          <FieldGroup label={t('hub-editor.iframe.height')}>
            <Input
              {...register('height', {
                setValueAs: value => {
                  return value.trim()
                },
              })}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-editor.iframe.width')}>
            <Input
              {...register('width', {
                setValueAs: value => {
                  return value.trim()
                },
              })}
            />
          </FieldGroup>
        </>
      )}
    </div>
  )
}

export default IFrameBlockEditor
