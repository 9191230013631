import { forwardRef } from 'react'
import { ArrayPath, Controller, FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { ModalProps } from '@/ui'
import { EngagementAddButton, EngagementItemCard } from '@/layouts/engagement/component'
import { PlusContained } from '@/icons'
import { ItemRead } from '@/api/dashboard'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { Button, FormErrorMessage, Input, Trash, useModal } from '@dashboard/ui'
import { Item } from '@/layouts/engagement/widgets/index'
import { useTranslation } from 'react-i18next'

interface ItemsFieldProps {
  preview?: boolean
  addText: string
  addLimit?: number
  pickerTitle: string
  name: ArrayPath<FieldValues>
  countable?: boolean
}

export const SkuItemsField = forwardRef<HTMLDivElement, ItemsFieldProps>(
  ({ preview = true, addText, addLimit, pickerTitle, name, countable = false }, ref) => {
    const { t } = useTranslation()
    const {
      control,
      watch,
      clearErrors,
      formState: { isSubmitting, errors },
    } = useFormContext()
    const { replace, remove } = useFieldArray({ control, name })
    const picked: Item[] = watch(name) || []

    const openPicker = useModal<ModalProps>(({ ...rest }) => (
      <SelectSkuModal
        {...rest}
        title={pickerTitle}
        picked={[...picked] as ItemRead[]}
        limit={addLimit}
        onSubmit={items => {
          replace(items.map(it => ({ ...it, count: it.is_stackable ? it.quantity : undefined })))
          clearErrors(`${name}.root`)
          rest?.onClose?.()
        }}
      />
    ))

    return (
      <div ref={ref} className="flex flex-col gap-1.5">
        {picked.map((item, index) => (
          <EngagementItemCard
            key={index}
            item={item}
            extra={
              preview &&
              countable &&
              item.is_stackable && (
                <span className="text-nowrap text-caption-md text-text-quarterary-hover">
                  {t('daily-rewards.reward.item.quantity')}: {item.count || item.quantity}
                </span>
              )
            }
            actions={
              !preview && (
                <div className="inline-flex items-center gap-1">
                  {countable && item.is_stackable && (
                    <>
                      <span className="text-nowrap text-caption-md text-text-quarterary-hover">
                        {t('daily-rewards.reward.item.quantity')}:
                      </span>
                      <Controller
                        control={control}
                        name={`${name}.${index}.count`}
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="max-w-24"
                            type="number"
                            step={1}
                            onChange={e => field.onChange(parseInt(e.target.value))}
                            onBlur={e => {
                              const value = parseInt(e.target.value)
                              if (isNaN(value) || value < 1) {
                                field.onChange(item.quantity)
                              }
                              field.onBlur()
                            }}
                            disabled={isSubmitting}
                          />
                        )}
                      />
                    </>
                  )}
                  <Button
                    variant="tertiary"
                    color="danger"
                    type="button"
                    onClick={() => remove(index)}
                    disabled={isSubmitting}
                  >
                    <Trash />
                  </Button>
                </div>
              )
            }
          />
        ))}
        {!preview && (!addLimit || picked.length < addLimit) && (
          <EngagementAddButton disabled={isSubmitting} icon={PlusContained} onClick={() => openPicker()}>
            {addText}
          </EngagementAddButton>
        )}
        <ErrorMessage
          name={`${name}.root`}
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </div>
    )
  },
)
