import { useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { cn } from '@dashboard/ui'
import { ToolbarGroup } from './ToolbarGroup'
import { ToolbarDivider } from './ToolbarDivider'
import { ToolbarAlignButton } from './ToolbarAlignButton'
import { ToolbarFormattingButton } from './ToolbarFormattingButton'
import { ToolbarImageButton } from './ToolbarImageButton'
import { ToolbarListsButton } from './ToolbarListsButton'
import { ToolbarTableButton } from './ToolbarTableButton'

interface ToolbarPluginProps {
  stickyTopOffset?: number
}

export const ToolbarPlugin = ({ stickyTopOffset }: ToolbarPluginProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [editor] = useLexicalComposerContext()
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      {
        threshold: [1],
        rootMargin: stickyTopOffset ? `${stickyTopOffset}px 0px 0px 0px` : undefined,
      },
    )

    observer.observe(ref.current)
  }, [stickyTopOffset, ref])

  return (
    <div
      ref={ref}
      className={cn(
        'flex shrink-0 items-center gap-1.5 rounded-t-md border-b border-b-border-secondary bg-fg-secondary p-1',
        stickyTopOffset && 'sticky left-0 top-0 z-[200]',
        isSticky && 'rounded-none shadow-xs',
      )}
      style={stickyTopOffset ? { top: stickyTopOffset - 1 } : {}}
    >
      <ToolbarFormattingButton editor={editor} />

      <ToolbarDivider />

      <ToolbarGroup>
        <ToolbarListsButton editor={editor} />
      </ToolbarGroup>

      <ToolbarDivider />

      <ToolbarGroup>
        <ToolbarAlignButton editor={editor} />
      </ToolbarGroup>

      <ToolbarDivider />

      <ToolbarTableButton editor={editor} />

      <ToolbarGroup>
        <ToolbarImageButton editor={editor} />
      </ToolbarGroup>
    </div>
  )
}
