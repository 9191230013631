import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { DataGranularity, FilterType } from '@/api/dashboard'

type Query = {
  /**
   * Last Count Days
   * @max 30
   * @default 30
   */
  last_count?: number
  /**
   * Tz
   * @default 0
   */
  tz?: number

  type?: FilterType

  limit?: number

  start_at?: number

  end_at?: number

  granularity?: DataGranularity
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useTopCodesQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['top-codes', gameId, query],
    queryFn: () => dashboardClient.v1.getTopCodes(companyId, gameId, query),
    select: response => response.data,
  })
}
