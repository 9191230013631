import { useContext } from 'react'
import { LastDateFilter, OperatorType, SelectorValueType, TokenValue } from './types'
import AttrSelector from './AttrSelector'
import OperatorSelector from './OperatorSelector'
import TextIconButton from '../../../components/buttons/TextIconButton'
import { AttrType, ConditionNode, Token, TokenType } from '@/api/dashboard'
import { CampaignContext, ICampaignContext } from '../Context'
import { isMoneyAttribute } from '../blocks/common'
import { ButtonIcon, Input, Select } from '@/ui'
import { getAttrType } from './getAttrType'
import { AttrValueEditor } from './AttrValueEditor'
import { findAttr } from './findAttr'
import { getTokenGroups } from '../util'
import { PlusContained } from '@/icons'
import { useTranslation } from 'react-i18next'
import { Trash, cn } from '@dashboard/ui'

export default function ConditionEditor(props: {
  node: ConditionNode
  setNode: (node: ConditionNode) => void
  className?: string
  errTokens?: Token[]
}) {
  const context = useContext(CampaignContext) as ICampaignContext
  const { t } = useTranslation()

  const tokenGroups = getTokenGroups(props.node.expr.tokens || []) as Token[][]

  const onChange = (value: Token[][]) => {
    props.setNode({
      ...props.node,
      expr: {
        ...props.node.expr,
        tokens: value.flat(),
      },
    })
  }

  const attrTypeToToken = (attrType: AttrType | undefined): TokenType => {
    switch (attrType) {
      case AttrType.String:
        return TokenType.String
      case AttrType.Number:
        return TokenType.Number
      case AttrType.Boolean:
        return TokenType.Boolean
      case AttrType.Date:
        return TokenType.Date
      case AttrType.List:
        return TokenType.List
      default:
        return TokenType.String
    }
  }

  const onTokenValueChanged = (groupIndex: number, tokenIndex: number, value: TokenValue, type?: TokenType) => {
    const newTokenGroups = [...tokenGroups]
    const currentToken = newTokenGroups[groupIndex][tokenIndex]
    currentToken.value = value
    if (type) {
      currentToken.type = type
    }
    onChange(newTokenGroups)
  }

  const onAttrTokenValueChanged = (
    groupIndex: number,
    tokenIndex: number,
    value: TokenValue,
    extAttrType: TokenType,
  ) => {
    let newTokenGroups = [...tokenGroups]
    let currentToken = newTokenGroups[groupIndex][tokenIndex]
    let group = newTokenGroups[groupIndex]
    let attr = group[tokenIndex]

    let newAttrType = getAttrType(
      {
        value: value,
        type: extAttrType,
      },
      context,
    )

    if (!attr?.value || getAttrType(attr, context) != newAttrType) {
      group[1].value = ''

      group[2].type = attrTypeToToken(newAttrType)
      if (group[2].type == TokenType.List && Array.isArray(group[2].value)) {
        // keep list value
      } else {
        group[2].value = undefined
      }
    }
    currentToken.type = extAttrType
    currentToken.value = value
    onChange(newTokenGroups)
  }

  const renderValueEditor = (groupIndex: number, leftOperand: Token, operator: Token, rightOperand: Token) => {
    let attrType = getAttrType(leftOperand, context)

    if (!attrType) {
      return <Input value={''} disabled style={{ width: '100%' }} />
    }

    if (operator.value == OperatorType.is_none || operator.value == OperatorType.is_not_none) {
      return <></>
    }

    let attr = findAttr(leftOperand.value) || context.playerCustomAttributes?.find(it => it.name == leftOperand.value)

    return (
      <AttrValueEditor
        operator={operator.value as OperatorType}
        attr={attr!}
        isMoney={isMoneyAttribute(leftOperand.value)}
        onChange={v => onTokenValueChanged(groupIndex, 2, v as string)}
        value={rightOperand.value}
      />
    )
  }

  const renderTokenGroupRow = (groupIndex: number, tokens: Token[]) => {
    if (tokens.length == 1) {
      return (
        <div key={groupIndex} className="flex h-[48px] items-start justify-start">
          <Select
            style={{ width: '80px' }}
            value={tokens[0].value}
            onChange={v => onTokenValueChanged(groupIndex, 0, v as TokenValue)}
            options={[
              { value: OperatorType.or, children: t('campaign.operator.or') },
              { value: OperatorType.and, children: t('campaign.operator.and') },
            ]}
          />
        </div>
      )
    }

    let s1 = { maxWidth: '70%' }
    let s2 = { maxWidth: '30%' }
    let s3 = { maxWidth: '40%' }
    let type = getAttrType(tokens[0], context)
    if (type == AttrType.Number || type == AttrType.Boolean) {
      s1 = { maxWidth: '50%' }
      s2 = { maxWidth: '30%' }
      s3 = { maxWidth: '30%' }
    }

    if (tokens[1].value == OperatorType.is_not_none || tokens[1].value == OperatorType.is_none) {
      s1 = { maxWidth: '70%' }
      // @ts-ignore
      s2 = { maxWidth: '30%', width: '30%' }
    }

    if (tokens[1].value == OperatorType.in_range || tokens[1].value == OperatorType.not_in_range) {
      // @ts-ignore
      s3 = { maxWidth: '40%', width: '40%' }
    }

    let attr = findAttr(tokens[0].value)

    if (
      attr?.selectorValueType == SelectorValueType.sku_no_price ||
      attr?.selectorValueType == SelectorValueType.sku ||
      attr?.selectorValueType == SelectorValueType.segment ||
      attr?.selectorValueType == SelectorValueType.country
    ) {
      // @ts-ignore
      s3 = { maxWidth: '45%', width: '45%' }
    }

    return (
      <div className="flex h-[48px] w-full items-start justify-start" key={groupIndex}>
        <div className="flex gap-2" style={{ maxWidth: 'calc(100% - 32px)', flexGrow: 1 }}>
          <div style={s1}>
            <AttrSelector
              token={tokens[0]}
              error={props.errTokens?.includes(tokens[0])}
              onChange={(v, attrType) => {
                onAttrTokenValueChanged(groupIndex, 0, v, attrType)
                if (!tokens[1].value) {
                  onTokenValueChanged(groupIndex, 1, OperatorType['=='])
                }

                let newAttrType = getAttrType(
                  {
                    value: v,
                    type: attrType,
                  },
                  context,
                )

                switch (newAttrType) {
                  case AttrType.Number:
                    if (tokens[2].value == undefined) {
                      onTokenValueChanged(groupIndex, 2, 0)
                    }
                    break
                  case AttrType.Boolean:
                    onTokenValueChanged(groupIndex, 2, true)
                    break
                  case AttrType.Date:
                    onTokenValueChanged(groupIndex, 1, OperatorType['>'])
                    onTokenValueChanged(groupIndex, 2, LastDateFilter['1.day.ago'])
                    break
                }
              }}
            />
          </div>

          <div style={s2}>
            <OperatorSelector
              value={tokens[1].value}
              error={props.errTokens?.includes(tokens[1])}
              attrToken={tokens[0]}
              onChange={v => {
                let prevValue = tokens[1].value
                onTokenValueChanged(groupIndex, 1, v)

                switch (v) {
                  case OperatorType.is_none:
                  case OperatorType.is_not_none:
                    onTokenValueChanged(groupIndex, 2, undefined)
                    break
                  case OperatorType.in_range:
                  case OperatorType.not_in_range:
                    if (prevValue != OperatorType.in_range && prevValue != OperatorType.not_in_range) {
                      onTokenValueChanged(groupIndex, 2, [0, 10], TokenType.List)
                    }
                    break
                  case OperatorType.in_list:
                  case OperatorType.not_in_list:
                    if (prevValue != OperatorType.in_list && prevValue != OperatorType.not_in_list) {
                      onTokenValueChanged(groupIndex, 2, [], TokenType.List)
                    }
                    break
                  default:
                    if (
                      prevValue == OperatorType.in_range ||
                      prevValue == OperatorType.not_in_range ||
                      prevValue == OperatorType.in_list ||
                      prevValue == OperatorType.not_in_list
                    ) {
                      let attrType = getAttrType(tokens[0], context)
                      let value: string | number | string[] = ''
                      if (attrType == AttrType.Number) {
                        value = 0
                      } else if (attrType == AttrType.List) {
                        value = []
                      }

                      onTokenValueChanged(groupIndex, 2, value, attrTypeToToken(attrType))
                    }
                    break
                }
              }}
            />
          </div>

          <div style={s3}>{renderValueEditor(groupIndex, tokens[0], tokens[1], tokens[2])}</div>
        </div>

        <div className="ml-auto flex h-full items-center justify-center">
          <ButtonIcon variant="secondary-destructive" size="sm" onClick={() => onGroupRemoveClick(groupIndex)}>
            <Trash size={16} />
          </ButtonIcon>
        </div>
      </div>
    )
  }

  const onGroupRemoveClick = (groupIndex: number) => {
    const newTokenGroups = [...tokenGroups]

    if (props.errTokens?.length) {
      newTokenGroups[groupIndex].forEach(it => {
        props.errTokens!.splice(props.errTokens!.indexOf(it), 1)
      })
    }

    if (groupIndex > 0 && groupIndex == newTokenGroups.length - 1) {
      newTokenGroups.pop() //remove condition
      newTokenGroups.pop() //remove and/or
    } else if (groupIndex == 0) {
      newTokenGroups.shift() //remove condition
      if (newTokenGroups.length > 0) {
        newTokenGroups.shift()
      } //remove and/or
    } else {
      newTokenGroups.splice(groupIndex, 2)
    }

    onChange(newTokenGroups)
  }

  const addConditionClick = () => {
    const newTokenGroups = [...tokenGroups]

    if (tokenGroups.length > 0) {
      newTokenGroups.push([{ type: TokenType.Operator, value: OperatorType.and } as Token])
    }

    newTokenGroups.push([
      { type: TokenType.Attribute, value: '' } as Token,
      { type: TokenType.Operator, value: '' } as Token,
      { type: TokenType.Number, value: '' } as Token,
    ])

    onChange(newTokenGroups)
  }

  return (
    <div className={cn('flex flex-col gap-1 rounded-md border border-border-primary-hover p-4', props.className)}>
      {tokenGroups.map((group, i) => renderTokenGroupRow(i, group))}

      <TextIconButton
        onClick={addConditionClick}
        className={tokenGroups.length ? 'mt-3' : ''}
        text={t('campaign.condition.add')}
      >
        <PlusContained />
      </TextIconButton>
    </div>
  )
}
