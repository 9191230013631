import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Pipette: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M18.0424 2.00799L18.7142 2.13575C20.9294 2.79855 21.7531 5.56155 20.2016 7.31039L17.8105 9.69807C17.8105 9.738 18.2743 10.1692 18.3463 10.2491C18.5702 10.5286 18.5702 10.8959 18.3463 11.1674C17.8025 11.6306 17.3066 12.3093 16.7628 12.7485C16.163 13.2357 15.6992 12.6367 15.3073 12.2375L9.87725 17.6597C9.18949 18.2745 8.47774 18.8575 7.56605 19.0971C7.31814 19.1609 7.05423 19.1929 6.81432 19.2648C6.23852 19.4325 5.69471 19.8876 5.25486 20.2869C4.84701 20.6622 4.42315 21.3091 3.79137 20.8299C3.23157 20.4067 2.71975 19.664 2.15994 19.2089L2 18.9373V18.5381C2.13595 18.2027 2.44784 17.9871 2.67976 17.7235C3.08762 17.2684 3.52747 16.7653 3.7034 16.1664C3.78338 15.8949 3.81537 15.6074 3.89534 15.3359C4.15125 14.4495 4.72705 13.7547 5.33483 13.0839L10.7489 7.67772C10.3411 7.27845 9.76529 6.83126 10.2371 6.22435C10.7729 5.76119 11.2528 5.12235 11.7886 4.67516C12.0765 4.43559 12.4443 4.40365 12.7402 4.64322C12.8202 4.7071 13.2521 5.18623 13.2921 5.17825C14.1478 4.35574 14.9555 3.48531 15.8272 2.67877C16.315 2.30345 16.9068 2.04791 17.5306 2.01597L17.5626 2H18.0424V2.00799ZM11.7646 8.77174L6.44645 14.0821C5.98261 14.6012 5.45479 15.1362 5.26286 15.831C5.18289 16.1105 5.1509 16.4059 5.07093 16.6854C4.83101 17.4999 4.30319 18.0829 3.74339 18.6898L4.26321 19.2328H4.3032C4.89499 18.6978 5.46279 18.1867 6.23852 17.9392C6.64638 17.8114 7.07023 17.7874 7.46209 17.6117C8.0059 17.3722 8.46174 16.925 8.90959 16.5417L14.2197 11.2393L11.7646 8.77174Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
