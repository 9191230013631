import { FieldSection } from 'src/components/ui/FieldSection'
import { TFunction } from 'i18next'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { SelectTime } from '@/components/ui/SelectTime'
import { FieldGroup, Label } from '@/ui'
import dayjs from 'dayjs'
import { FormErrorMessage, InputDate } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { ControlHookFormError } from '@dashboard/ui/src/types'
import { AnimatePresence } from 'framer-motion'

export const validateInterval = (start_at: number | undefined, end_at: number | undefined, t: TFunction) => {
  if (!start_at || !end_at) {
    return ''
  }

  if (start_at >= end_at) {
    return t('validation.start_date')
  }

  if (start_at <= 0 || end_at <= 0) {
    return t('validation.invalid_date')
  }

  if (new Date(start_at * 1000).getFullYear() > 2037 || new Date(end_at * 1000).getFullYear() > 2037) {
    return t('validation.invalid_date')
  }
}

export interface ItemTimelimitProps extends ControlHookFormError {
  useSettingsContainer?: boolean
  start_at?: number
  end_at?: number
  children?: React.ReactNode
  setValue: (prop: 'duration' | 'start_at' | 'end_at', value?: number) => void
}

export const ItemTimelimit = ({
  start_at,
  end_at,
  setValue,
  children,
  error,
  useSettingsContainer = true,
}: ItemTimelimitProps) => {
  const { t } = useTranslation()

  const onDateChanged = (date: Date, prop: 'start_at' | 'end_at') => {
    const currentDate = new Date((prop == 'start_at' ? start_at : end_at)! * 1000)

    date.setHours(currentDate.getHours())
    date.setMinutes(0)
    date.setSeconds(0)

    setValue(prop, date.getTime() / 1000)
  }

  const onTimeChanged = (hours: number, prop: 'start_at' | 'end_at') => {
    const date = new Date((prop == 'start_at' ? start_at : end_at)! * 1000)
    date.setHours(hours)
    date.setMinutes(0)
    date.setSeconds(0)

    setValue(prop, date.getTime() / 1000)
  }

  const getStartDate = () => {
    return dayjs().startOf('day').toDate().getTime() / 1000
  }

  const getTimeValue = (value: number) => {
    let d = new Date(value * 1000)
    return d.getHours()
  }

  const renderEditor = () => {
    return (
      <>
        <CheckBoxContainer
          checked={start_at != undefined && end_at != undefined}
          onChange={v => {
            if (v.target.checked) {
              setValue('duration', null as unknown as undefined)
              setValue('start_at', getStartDate())
              setValue('end_at', getStartDate() + 86400 * 30)
            } else {
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined)
            }
          }}
          label={t('campaign.virtual-sku-editor.limit-by-dates')}
        >
          <div className="flex flex-col justify-end gap-2">
            {start_at && (
              <div>
                <Label $size="md">{t('datetime.start_at')}</Label>
                <div className="flex gap-2">
                  <InputDate
                    hideClearButton={true}
                    value={end_at ? new Date(start_at * 1000) : null}
                    onChange={value => value && onDateChanged(value, 'start_at')}
                  />
                  <SelectTime
                    onChange={value => onTimeChanged(value as number, 'start_at')}
                    value={getTimeValue(start_at)}
                  />
                </div>
              </div>
            )}

            {end_at && (
              <FieldGroup label={t('datetime.end_at')}>
                <div className="flex gap-2">
                  <InputDate
                    hideClearButton={true}
                    value={end_at ? new Date(end_at * 1000) : null}
                    onChange={value => value && onDateChanged(value, 'end_at')}
                  />
                  <SelectTime
                    onChange={value => onTimeChanged(value as number, 'end_at')}
                    value={getTimeValue(end_at)}
                  />
                </div>
              </FieldGroup>
            )}
          </div>
          <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
        </CheckBoxContainer>
      </>
    )
  }

  if (useSettingsContainer) {
    return (
      <FieldSection label={t('campaign.settings.limit-limitations')}>
        {renderEditor()}
        {children}
      </FieldSection>
    )
  }

  return renderEditor()
}
