import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { AchievementRead, AchievementType } from '@/api/dashboard'
import { FieldGroup, ImageUploader } from '@/ui'
import { Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useAchievementUpdateMutate } from './api/useAchievementUpdateMutate'
import { useAchievementCreateMutate } from './api/useAchievementCreateMutate'
import { useState } from 'react'
import { usePydenticForm } from '@/libs/hooks/usePydenticForm'

export interface EditAchievementDialogProps extends ModalProps {
  item: AchievementRead
}

export const EditAchievementDialog = ({ item, ...rest }: EditAchievementDialogProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync: updateMutateAsync } = useAchievementUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useAchievementCreateMutate(companyId, gameId)
  const [isCustom, setIsCustom] = useState(!Object.values(AchievementType).includes(item.type as AchievementType))

  const {
    formState: { isSubmitting, errors },
    control,
    register,
    handleSubmit,
  } = usePydenticForm<AchievementRead>({
    defaultValues: item,
  })

  const onSubmit = handleSubmit(async values => {
    if (item?.id) {
      await updateMutateAsync({
        id: values.id,
        update: values,
      })
      rest.onClose?.()
    } else {
      await createMutateAsync({
        create: values,
      })
    }
    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle>
            {t(item.id ? 'edit-achievement-dialog.title.edit' : 'edit-achievement-dialog.title.create')}
          </ModalTitle>

          <FieldGroup label={t('edit-achievement-dialog.name')}>
            <Input
              {...register('name', { required: t('validation.required') })}
              {...formatHookFormErrors(errors, 'name')}
              autoFocus={true}
            />
          </FieldGroup>

          <FieldGroup label={t('edit-achievement-dialog.description')}>
            <Input
              {...register('description', { required: t('validation.required') })}
              {...formatHookFormErrors(errors, 'description')}
            />
          </FieldGroup>

          <FieldGroup label={t('edit-achievement-dialog.type')}>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Select
                    items={[
                      ...Object.values(AchievementType).map(item => ({
                        children: t(`achievement.type.${item}`),
                        value: item,
                      })),
                      {
                        children: t('achievement.type.custom'),
                        value: 'custom',
                      },
                    ]}
                    value={isCustom ? 'custom' : field.value}
                    onChange={v => {
                      if (v === 'custom') {
                        setIsCustom(true)
                        field.onChange('')
                      } else {
                        setIsCustom(false)
                        field.onChange(v)
                      }
                    }}
                  />
                  {!isCustom && (
                    <ErrorMessage
                      name="type"
                      errors={errors}
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    />
                  )}
                </>
              )}
            />
          </FieldGroup>

          {isCustom && (
            <FieldGroup label={t('achievement.custom_type_name')}>
              <Input
                {...register('type', { required: t('validation.required') })}
                {...formatHookFormErrors(errors, 'type')}
              />
            </FieldGroup>
          )}

          <FieldGroup>
            <Controller
              control={control}
              name="image_url"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <ImageUploader
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="image_url"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" onClick={rest.onClose} size="sm" type="button">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isSubmitting} size="sm">
            {t(item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
