import { Globe1 } from '@/icons'
import { getPagePreviewUrl } from '@/layouts/pages/libs'

interface NewsSlugCaptionProps {
  base: string | undefined
  slug: string
  parent?: string
  child?: string
}

export const PagesSlugCaption = ({ base, slug, parent, child }: NewsSlugCaptionProps) => {
  if (!base || !slug) {
    return null
  }

  const href = getPagePreviewUrl(base, slug, { parent, child })

  return (
    <div className="mt-2 px-1.5">
      <a className="inline-flex items-start gap-1.5" target="_blank" href={href}>
        <Globe1 size={16} />
        <span className="text-paragraph-sm leading-none text-text-secondary">{href}</span>
      </a>
    </div>
  )
}
