import { useTranslation } from 'react-i18next'
import { Skeleton } from '@dashboard/ui'
import { Page, PageHeader, PageToolbar, Sidebar, SidebarLinkSkeleton } from '@/ui'
import { TransactionRowItemSkeleton } from './TranslationRowItem'

export const L10nTranslationsSkeleton = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <PageHeader>{t('localization.title')}</PageHeader>
      <div className="grid h-full grid-cols-[calc(232px+0.75rem*2)_1fr] items-start">
        <div>
          <Sidebar>
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
          </Sidebar>
        </div>
        <div className="relative h-full rounded-md border border-border-secondary">
          <PageToolbar>
            <Skeleton className="my-1 h-6 w-1/3" />
          </PageToolbar>
          <div className="h-[44px]" />
          {new Array(1).fill('').map((_, index) => (
            <TransactionRowItemSkeleton key={`skeleton-${index}`} />
          ))}
        </div>
      </div>
    </Page>
  )
}
