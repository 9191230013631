import { InputSearch, SearchFilterGeneric, SelectFilter } from '@dashboard/ui'
import { ObjectType, State } from '@/api/dashboard'
import { L10nTranslationsListFilter } from '../types'
import { useTranslation } from 'react-i18next'

interface L10nTranslationsFilterProps {
  filter: SearchFilterGeneric<L10nTranslationsListFilter>
}

export const L10nTranslationsFilter = ({ filter }: L10nTranslationsFilterProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-3 border-b border-border-secondary p-3">
      <InputSearch {...filter.registerInput('q')} data-testid="l10n/toolbar/filter-search" placeholder={t('search')} />
      <div className="flex gap-2">
        <SelectFilter
          {...filter.registerSelect('object_types', true)}
          data-testid="l10n/toolbar/filter-type"
          data-testvalue={`l10n/toolbar/filter-type/${filter.values.object_types}`}
          items={Object.values(ObjectType)
            .sort((a, b) => a.localeCompare(b))
            .map(type => ({
              children: t(`localization.translation.type.${type.toLowerCase()}`, { count: Infinity }),
              value: type,
            }))}
        >
          {t('localization.locale.filter.type')}
        </SelectFilter>
        <SelectFilter
          {...filter.registerSelect('states', true)}
          data-testid="l10n/toolbar/filter-status"
          data-testvalue={`l10n/toolbar/filter-status/${filter.values.states}`}
          items={Object.values(State).map(state => ({
            children: t(`localization.translation.status.${state}`),
            value: state,
          }))}
        >
          {t('localization.locale.filter.status')}
        </SelectFilter>
      </div>
    </div>
  )
}
