import { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import CenteredProgress from '../../components/CenteredProgress'
import { ReactFlowProvider, Viewport } from 'reactflow'
import 'reactflow/dist/style.css'
import { Flow, FlowApi } from './Flow'
import CampaignEditorHeader from './CampaignEditorHeader'
import { ROOT_NODE_ID, hasMoreOneChildren } from './Settings'
import { uuid4 } from '../../util'
import { NodeFieldEditor } from './editors/NodeEditor'
import SettingsHeader from './SettingsHeader'
import CampaignSettings from './widgets/CampaignSettings'
import { START_BLOCK_WIDTH } from './blocks/StartBlock'
import { CampaignContext, EditorMode, ICampaignContext } from './Context'
import { Campaign, ConditionNode, GraphRoot, Node, ResourceState, WebhookEventType } from '../../api/dashboard'
import { dashboardClient, getErrorText } from '../../api'
import { AnyNode, BlockError, EventActionNodeType } from './types'
import { NODE_VALIDATORS, validateWebhooks } from './validation'
import { calculateWarning } from './util'
import { DrawerSidebar } from '../../components/ui/DrawerSidebar'
import RunHistory from './widgets/RunHistory/RunHistory'
import { GAME_WEBHOOKS_NEW_PATH, LIVEOPS_DETAILS_PATH, LIVEOPS_PATH_TABLE } from '../../libs/routerPaths'
import { Button, ConfirmDialog, ModalConfirm, useModal } from '@/ui'
import { useSegmentsQuery } from '../segment/api/useSegmentsQuery'
import { DescContext } from './blocks/descriptions/types'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../api/useGameItems'
import CampaignDashboard from './widgets/CampaignDashboard'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { useGameSettingsQuery } from '../../api/useGameSettingsQuery'
import { campaignsQuery } from '@/layouts/campaigns/api'
import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { useBanner } from '@/libs/hooks/useBanner'
import { Copy03, Trash } from '@/icons'
import { ExpressionForm } from '@/layouts/campaigns/expr/ExpressionForm'
import { useCurrentUser } from '@/api/useCurrentUser'
import { cn } from '@dashboard/ui'

export default function CampaignEditPage() {
  const navigate = useNavigate()
  const { getStickyTop, getStickyHeight } = useBanner()
  const location = useLocation()
  const { state } = location
  const { t } = useTranslation()
  const { companyId, gameId, eventId, userCampaignId } = useParams() as {
    companyId: string
    gameId: string
    eventId: string
    userCampaignId: string
  }

  const ql = useQueryClient()

  const [loading, setLoading] = useState(true)
  const [graph, setGraph] = useState<GraphRoot>({} as GraphRoot)
  const [campaign, setCampaign] = useState<Campaign | null>(null)
  const [historyMode, setHistoryMode] = useState<boolean>(false)
  const [prevGraph, setPrevGraph] = useState<GraphRoot | null>(null)

  const [error, setError] = useState<BlockError | null>(null)
  const [campaignSettingsVisible, setCampaignSettingsVisible] = useState(false)
  const [campaignHistoryVisible, setCampaignHistoryVisible] = useState(!!userCampaignId)
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined)

  const [selectedBlockId, setSelectedBlockId] = useState('')
  const [selectedEdgeId, setSelectedEdgeId] = useState('')
  const [nodeSettingsVisible, setNodeSettingsVisible] = useState(false)

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const [isDirty, setIsDirty] = useState(false)
  const [showConfirmBack, setShowConfirmBack] = useState(false)
  const [confirmSaveWithWarning, setConfirmSaveWithWarning] = useState('')
  const { canEdit } = useCurrentUser()

  const [analyticsVisible, setAnalyticsVisible] = useState(false)

  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })

  const showToast = useToast()

  const flowRef = useRef<FlowApi | null>(null)

  useEffect(() => {
    loadGraph()
  }, [eventId])

  useEffect(() => {
    if (!selectedBlockId) {
      setNodeSettingsVisible(false)
      return
    }

    if (!error) {
      setNodeSettingsVisible(true)
      return
    }

    setNodeSettingsVisible(!error.useToast)
  }, [selectedBlockId])

  useEffect(() => {
    if (!userCampaignId) {
      return
    }

    loadHistoryGraph(userCampaignId)
  }, [userCampaignId])

  const setGraphAndDirty = (newGraph: GraphRoot) => {
    setIsDirty(true)
    setGraph({
      ...newGraph,
    })
  }

  const setNode = (newNode: Node) => {
    setGraph({
      ...graph,
      nodes: {
        ...graph.nodes,
        [newNode.id as string]: {
          ...newNode,
        },
      },
    })
    setIsDirty(true)
  }

  const loadHistoryGraph = async (id: string) => {
    let { data: c } = await dashboardClient.v1.getUserGraph(id, companyId, gameId, eventId)
    if (!historyMode) {
      setHistoryMode(true)
      setPrevGraph(graph)
    }
    setGraph(c)
  }

  const loadGraph = async () => {
    setLoading(true)
    try {
      let { data: c } = await dashboardClient.v1.getCampaign(companyId, gameId, eventId)
      setCampaign(c)

      if (state?.graph) {
        setGraph(state.graph)
        setIsDirty(true)
      } else {
        let { data: item } = await dashboardClient.v1.getCampaignGraph(companyId, gameId, eventId)
        if (!item) {
          let x = document.body.offsetWidth / 2 - START_BLOCK_WIDTH / 2
          item = {
            id: ROOT_NODE_ID,
            nodes: {},
            styles: {},
            title: '',
            x: x - (x % 20),
            y: 20,
            model_type: EventActionNodeType.GraphRoot,
          }
        } else if (item.view_port) {
          setViewPort(item.view_port)
        }

        setGraph(item)
      }
    } finally {
      setLoading(false)
    }
  }

  const duplicateNode = (id: string) => {
    if (!graph.nodes || !canEdit) {
      return
    }

    let node = JSON.parse(JSON.stringify(graph.nodes[id]))
    node.next_node_id = null
    node.true_node_id = null
    node.false_node_id = null
    node.x += hasMoreOneChildren(node.model_type) ? 300 : 100
    node.y += hasMoreOneChildren(node.model_type) ? 300 : 100

    if (node.model_type == EventActionNodeType.SplitNode) {
      node.distribution = [
        { value: 50, next_node_id: undefined },
        { value: 100, next_node_id: undefined },
      ]
    }

    node.id = `node_${uuid4()}`

    graph.nodes[node.id] = node

    setGraphAndDirty(graph)

    setSelectedBlockId(node.id)
  }

  const getContextValue = () => {
    return {
      items: items,
      campaign: campaign as Campaign,
      playerCustomAttributes: gameSettings?.player_attributes || [],
      segments: segments,
      graph: graph,
      readOnly: !canEdit,
      historyMode: historyMode,
      mode: EditorMode.Campaign,
      viewPort: viewPort,
    } as ICampaignContext
  }

  const validate = (): BlockError | null => {
    for (let id in graph.nodes) {
      let node = graph.nodes[id]

      let validator = NODE_VALIDATORS[node.model_type]

      if (validator) {
        const c = {
          items: items,
          segments: segments,
          graph: graph,
          type: campaign?.type,
        } as DescContext

        let errors = validator(node as AnyNode, c)
        if (errors.length) {
          return {
            blockId: id,
            errorText: errors[0].message,
            errors: errors,
          }
        }
      }

      let [warning, , itemName] = calculateWarning(node, graph)
      if (warning) {
        return {
          useToast: true,
          blockId: id,
          errorText: t('campaign.block.exit.warning.toast', { name: itemName }),
          errors: [],
        }
      }
    }
    return null
  }

  const onConfirmSaveWithWarning = () => {
    setConfirmSaveWithWarning('')
    saveClick(true)
  }

  const openConfirmWebhookModal = useModal<{ types: WebhookEventType[] }>(props => (
    <ModalConfirm
      color="primary"
      message={t('campaign.editor.required-webhook.title')}
      subMessage={
        <Trans
          i18nKey={t('campaign.editor.required-webhook', {
            value: props.types.map(it => t(`webhook.event.${it}.label`)).join(', '),
          })}
          components={{ b: <b /> }}
        />
      }
      confirmButtonText={t('campaign.editor.required-webhook.create')}
      cancelButtonText={t('campaign.editor.required-webhook.continue')}
      onConfirm={() => window.open(generatePath(GAME_WEBHOOKS_NEW_PATH, { companyId, gameId }))}
      showClose={true}
      {...props}
    />
  ))

  const publishChangeClick = async () => {
    try {
      await dashboardClient.v1.updateCampaign(companyId, gameId, eventId, {
        enabled: !campaign?.enabled,
      })
      if (campaign) {
        setCampaign({
          ...campaign,
          enabled: !campaign.enabled,
        })
      }
    } catch (e) {
      showToast({ message: t(`server-errors.${getErrorText(e)}`), severity: ToastSeverity.error })
    }
  }

  const saveClick = async (skipWarnings: boolean = false) => {
    let error = validate()
    if (error) {
      if (error.useToast) {
        if (!skipWarnings) {
          setConfirmSaveWithWarning(error.errorText)
          // setError(error)
          // setSelectedBlockId(error.blockId as string)
          return
        }
      } else {
        showError(error)
        return
      }
    }

    let whErrorTypes = await validateWebhooks(graph, companyId, gameId)
    if (whErrorTypes.length) {
      openConfirmWebhookModal({ types: whErrorTypes })
      return
    }

    graph.view_port = viewPort

    setError(null)

    let { error: srvError } = await dashboardClient.v1.updateCampaignGraph(companyId, gameId, eventId, graph)

    setIsDirty(false)

    if (srvError?.detail?.length) {
      let path = srvError.detail[0].loc
      let errorBlockId = path.find(it => graph.nodes && graph.nodes[it])
      if (errorBlockId) {
        showError({ blockId: errorBlockId, errorText: srvError.detail[0].msg, errors: [] })
      }
    }

    if (campaign) {
      try {
        await dashboardClient.v1.updateCampaign(companyId, gameId, eventId, campaign)
        await ql.refetchQueries({ queryKey: campaignsQuery(companyId, gameId).queryKey })
      } catch (e) {
        showToast({ message: t(`server-errors.${getErrorText(e)}`), severity: ToastSeverity.error })
        return
      }
    }

    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  const showError = (error: BlockError) => {
    setSelectedBlockId(error.blockId as string)
    setNodeSettingsVisible(true)
    setCampaignSettingsVisible(false)
    setError(error)

    if (error.useToast) {
      showToast({ message: error.errorText, severity: ToastSeverity.error })
    }
  }

  const onBackClick = () => {
    if (isDirty && canEdit) {
      setShowConfirmBack(true)
    } else {
      onConfirmBack()
    }
  }

  const onConfirmBack = () => {
    navigate(generatePath(LIVEOPS_PATH_TABLE, { companyId, gameId }))
  }

  const closePanels = () => {
    if (historyMode) {
      navigate(generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId }))
    }
    setCampaignSettingsVisible(false)
    setCampaignHistoryVisible(false)
    setHistoryMode(false)
  }

  const renderSideBar = () => {
    if (!graph?.nodes) {
      return null
    }

    let isCondEdit = graph.nodes && graph.nodes[selectedBlockId]?.model_type == EventActionNodeType.ConditionNode

    return (
      <DrawerSidebar
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        open={nodeSettingsVisible && !!selectedBlockId && selectedBlockId != ROOT_NODE_ID}
        width="840px"
      >
        <div style={{ pointerEvents: canEdit ? 'all' : 'none', opacity: canEdit ? 1 : 0.6 }}>
          {isCondEdit ? (
            <ExpressionForm
              error={error}
              onClose={() => {
                setSelectedBlockId('')
                setNodeSettingsVisible(false)
                closePanels()
                if (error?.blockId == selectedBlockId) {
                  setError(null)
                }
              }}
              node={graph.nodes[selectedBlockId] as ConditionNode}
              setNode={n => setNode(n as Node)}
            />
          ) : (
            <NodeFieldEditor
              error={error}
              onClose={() => {
                setSelectedBlockId('')
                setNodeSettingsVisible(false)
                closePanels()
                setError(null)
              }}
              node={graph.nodes[selectedBlockId]}
              setNode={setNode}
            />
          )}
        </div>

        {canEdit && (
          <div className="mt-auto flex gap-2 p-6">
            <Button onClick={() => duplicateNode(selectedBlockId)} variant="secondary">
              <Copy03 size={16} />
              {t('campaign.editor.duplicate-node')}
            </Button>
            <Button
              onClick={() => {
                flowRef.current?.removeBlock(selectedBlockId)
                setNodeSettingsVisible(false)
              }}
              variant="tertiary-destructive"
            >
              <Trash />
              {t('campaign.editor.delete-node')}
            </Button>
          </div>
        )}
      </DrawerSidebar>
    )
  }

  if (analyticsVisible) {
    return (
      <div className={cn('absolute left-0 z-50 w-full bg-white', getStickyTop(), getStickyHeight())}>
        <CampaignContext.Provider value={getContextValue()}>
          <CampaignDashboard onClose={() => setAnalyticsVisible(false)} />
        </CampaignContext.Provider>
      </div>
    )
  }

  return (
    <div className={cn('absolute left-0 z-50 w-full bg-white', getStickyTop(), getStickyHeight())}>
      <CampaignContext.Provider value={getContextValue()}>
        <div className="flex size-full flex-col justify-start">
          <CampaignEditorHeader
            publishChangeClick={publishChangeClick}
            isLive={!!campaign?.enabled}
            onOpenAnalytics={() => {
              setSelectedBlockId('')
              closePanels()
              setAnalyticsVisible(true)
            }}
            disabledSave={!isDirty}
            saveClick={() => saveClick()}
            onDeleteClick={
              (selectedEdgeId ? () => flowRef.current?.removeEdge(selectedEdgeId) : null) as () => void | null
            }
            onOpenSettings={() => {
              setSelectedBlockId('')
              closePanels()
              setCampaignSettingsVisible(true)
            }}
            onOpenHistory={() => {
              setSelectedBlockId('')
              closePanels()
              setCampaignHistoryVisible(true)
            }}
            onBackClick={onBackClick}
          />

          {showConfirmBack && (
            <ConfirmDialog
              color="error"
              cancelButtonText={t('campaign.confirm.no-stay')}
              confirmButtonText={t('campaign.confirm.yes')}
              subMessage={t('campaign.confirm.lost-changes')}
              onCancel={() => setShowConfirmBack(false)}
              onConfirm={onConfirmBack}
            />
          )}

          {confirmSaveWithWarning && (
            <ConfirmDialog
              color="error"
              cancelButtonText={t('campaign.confirm.no-stay')}
              confirmButtonText={t('Save')}
              subMessage={t(confirmSaveWithWarning) + '\n' + t('campaign.confirm.save-with-warnings')}
              onCancel={() => setConfirmSaveWithWarning('')}
              onConfirm={onConfirmSaveWithWarning}
            />
          )}

          <div className="relative flex grow justify-start bg-fg-primary">
            {renderSideBar()}

            {campaign && (
              <DrawerSidebar position="left" open={campaignSettingsVisible} width="840px">
                <CampaignSettings
                  onClose={closePanels}
                  campaign={campaign}
                  setCampaign={c => {
                    setIsDirty(true)
                    setCampaign(prev => ({
                      ...prev,
                      ...c,
                    }))
                  }}
                />
              </DrawerSidebar>
            )}

            {campaign && (
              <DrawerSidebar position="left" open={campaignHistoryVisible} width="840px">
                <div className="flex h-full flex-col">
                  <SettingsHeader
                    text={t('campaign.history')}
                    onClose={() => {
                      closePanels()
                      if (prevGraph?.nodes) {
                        setGraph(prevGraph)
                        setPrevGraph(null)
                      } else {
                        loadGraph()
                      }
                    }}
                  />
                  <div className="grow px-6 py-5">{campaignHistoryVisible && <RunHistory />}</div>
                </div>
              </DrawerSidebar>
            )}

            <div className="relative h-full grow bg-bg-primary">
              {loading ? (
                <CenteredProgress />
              ) : (
                graph && (
                  <ReactFlowProvider>
                    <Flow
                      actions={[
                        EventActionNodeType.ConditionNode,
                        EventActionNodeType.SplitNode,
                        EventActionNodeType.TimerNode,
                        EventActionNodeType.EmailActionNode,
                        EventActionNodeType.MobilePushActionNode,
                        EventActionNodeType.MobilePopupActionNode,
                        EventActionNodeType.BrowserPopupNotificationActionNode,
                        EventActionNodeType.CreateItemBannerActionNode,
                        EventActionNodeType.CreateCouponNode,
                        EventActionNodeType.CreateVirtualSKUNode,
                        EventActionNodeType.CreateUserStoreSettingsNode,
                      ]}
                      ref={flowRef}
                      setGraphAndDirty={setGraphAndDirty}
                      isReadOnly={!canEdit}
                      graph={graph}
                      setGraph={setGraph}
                      selectedBlockId={selectedBlockId}
                      setSelectedEdgeId={setSelectedEdgeId}
                      selectedEdgeId={selectedEdgeId}
                      setSelectedBlockId={setSelectedBlockId}
                      onViewPortChanged={vp => setViewPort(vp)}
                      viewPort={viewPort}
                      onNodeMoved={() => setIsDirty(true)}
                      onBlockSelected={id => {
                        if (historyMode) {
                          return
                        }
                        setSelectedEdgeId('')
                        closePanels()
                        setSelectedBlockId(id)
                      }}
                      onEdgeClick={id => {
                        if (historyMode) {
                          return
                        }
                        setSelectedEdgeId(id)
                      }}
                      setNode={setNode}
                    />
                  </ReactFlowProvider>
                )
              )}
            </div>
          </div>
          {/*{error?.useToast && (*/}
          {/*  <Snackbar open={true} autoHideDuration={60000} onClose={() => setError(null)}>*/}
          {/*    <Alert onClose={() => setError(null)} severity="error" variant="filled">*/}
          {/*      {error.errorText}*/}
          {/*    </Alert>*/}
          {/*  </Snackbar>*/}
          {/*)}*/}
        </div>
      </CampaignContext.Provider>
    </div>
  )
}
