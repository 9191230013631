import { useTranslation } from 'react-i18next'

export const TimeLimitInfo = ({ value }: { value: number | null | undefined }) => {
  const { t } = useTranslation()

  if (!value) {
    return null
  }

  return (
    <div className="flex gap-1.5">
      <div className="text-text-secondary">
        {t('intl.DateTime', {
          val: new Date(value * 1000),
          formatParams: { val: { dateStyle: 'short' } },
        })}
      </div>
      <div className="text-text-tertiary">
        {t('intl.DateTime', {
          val: new Date(value * 1000),
          formatParams: { val: { timeStyle: 'short' } },
        })}
      </div>
    </div>
  )
}
