import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'
import { l10nGlossaryCreateMutation } from '../api'
import { GlossaryItemFormPartData, L10nGlossaryItemFormPart } from './L10nGlossaryItemFormPart'

interface L10nGlossaryItemModalCreateProps extends ModalProps {
  localeId: string
}

export const L10nGlossaryItemModalCreate = ({ localeId, ...rest }: L10nGlossaryItemModalCreateProps) => {
  const { t } = useTranslation()
  const { mutateAsync } = l10nGlossaryCreateMutation()

  const methods = useForm<GlossaryItemFormPartData>({
    defaultValues: { locale_id: localeId },
  })

  const onSubmit = methods.handleSubmit(async data => {
    await mutateAsync({ data })
    rest?.onClose()
  })

  return (
    <Modal {...rest}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalTitle onClose={rest.onClose}>{t('l10n.glossary.modal-create.title')}</ModalTitle>
            <L10nGlossaryItemFormPart />
          </ModalContent>
          <ModalFooter>
            <Button variant="outline" color="secondary" onClick={rest.onClose}>
              {t('Cancel')}
            </Button>
            <Button loading={methods.formState.isSubmitting} type="submit">
              {t('l10n.glossary.modal-create.submit')}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
