import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react';
import { useKey } from 'react-use';
import { cva } from 'class-variance-authority';
import { cn } from '../../libs';
import { ModalBaseProps } from '../../providers';

export interface ModalProps extends ModalBaseProps {
  /**
   * Content of the modal.
   */
  children?: ReactNode;
  /**
   * Size of the modal.
   */
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const variants = cva('relative w-full overflow-hidden rounded-2xl1 bg-fg-primary shadow-xl transition-all', {
  variants: {
    size: {
      xl: 'max-w-[932px]',
      lg: 'max-w-[532px]',
      md: 'max-w-[480px]',
      sm: 'max-w-[380px]',
    },
    defaultVariants: {
      size: 'md',
    },
  },
});

export const Modal = ({ children, size = 'md', deep = 0, onClose, onResize, ...rest }: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const onPressEsc = useCallback(() => {
    if (deep === 0) {
      onClose();
    }
  }, [deep, onClose]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeObserver = new ResizeObserver(() => {
    onResize();
  });

  useLayoutEffect(() => {
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  useKey('Escape', onPressEsc, undefined, [onPressEsc]);

  return (
    <div {...rest} ref={ref} className={cn(variants({ size }), rest.className)}>
      {children}
    </div>
  );
};
