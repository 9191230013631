import { HTMLAttributes, forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Currency, ItemRead, ItemType, ItemViewOption } from '@/api/dashboard'
import { ShoppingBag03, Trash, UploadCloud2 } from '@/icons'
import { formatMoney, formatNumber } from '@/util'
import { SkuIcon } from './SkuIcon'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { RewardPointsPrice } from '@/layouts/game-items/components/RewardPointsPrice'
import { Button, Checkbox } from '@dashboard/ui'

interface PreviewProductCardProps extends HTMLAttributes<HTMLDivElement> {
  item: ItemRead
  onDropImage?: DropzoneOptions['onDrop']
  onDeleteImage?: () => void
  onSkeletonChange?: (isSkeleton: boolean) => void
}

export const PreviewProductCard = forwardRef<HTMLDivElement, PreviewProductCardProps>(function PreviewProductCard(
  { item, onDropImage, onDeleteImage, onSkeletonChange, ...rest },
  ref,
) {
  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': [],
      'image/webp': [],
    },
    onDrop: onDropImage,
    maxFiles: 1,
  })
  const isSkeletonAvailable = useMemo(
    () => item.type === ItemType.Bundle && item.nested_items && item.nested_items?.length !== 0 && item.image_url,
    [item],
  )
  const image = useMemo(
    () =>
      (item.view_option === ItemViewOption.Skeleton &&
        item.nested_items_read?.find(it => it.id === item.nested_items?.[0].id)?.image_url) ||
      item.image_url,
    [item],
  )

  return (
    <div
      ref={ref}
      {...rest}
      className="flex h-[511px] w-[330px] select-none flex-col rounded-[12px] border border-[#3F3F46] bg-black p-[18px] shadow-sku-card-preview"
    >
      <div className="group relative m-[-10px] mb-8 flex h-full">
        <div className="pointer-events-none absolute left-0 top-0 z-10 size-full max-h-[312px] rounded-[12px] shadow-inner-img-white" />

        {image ? (
          <div className="relative flex size-full max-h-[312px] overflow-hidden rounded-[12px]">
            <img className="flex size-full object-cover" src={image} alt="" />
            {onSkeletonChange && isSkeletonAvailable && (
              <div className="absolute left-3 top-3 flex items-center rounded-lg bg-white/10 p-3 ring-1 ring-white/20 backdrop-blur-sm">
                <Checkbox
                  checked={item.view_option === ItemViewOption.Skeleton}
                  onChange={e => onSkeletonChange?.(e.target.checked)}
                >
                  {t('sku.item.skeleton')}
                </Checkbox>
              </div>
            )}
            {item.view_option !== ItemViewOption.Skeleton && onDeleteImage && (
              <div className="absolute right-3 top-3 flex items-center rounded-lg bg-white/10 p-3 ring-1 ring-white/20 backdrop-blur-sm">
                <Button variant="link" color="secondary" type="button" onClick={onDeleteImage}>
                  <Trash />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex size-full max-h-[312px] items-center justify-center rounded-[12px] bg-[#27272A] text-[#3F3F46]">
            <SkuIcon size={74} />
            {onDropImage && (
              <div
                {...getRootProps()}
                className="absolute left-0 top-0 flex size-full cursor-pointer items-center justify-center rounded-[12px] bg-[rgba(0,0,0,0.5)] text-text-inverse opacity-0 transition-opacity group-hover:opacity-100"
              >
                <input {...getInputProps()} />
                <UploadCloud2 size={74} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="shrink-0">
        <div className="mb-6">
          <div className="mb-2 flex items-center justify-center gap-1.5 truncate text-center text-t5 text-white">
            {item.quantity && (item.quantity > 1 || item.is_stackable) && <span>{formatNumber(item.quantity)}</span>}
            <span>{item.name}</span>
          </div>
          <div className="line-clamp-2 min-h-10 text-center text-sm text-[#D4D4D8]">{item.description}</div>
        </div>

        {item.currency === Currency.RP ? (
          <button className="pointer-events-none flex w-full items-center justify-center gap-3 rounded-[6px] bg-[#9333EA] px-4 py-3 text-center text-t6 text-white">
            <RewardPointsPrice price={item.reward_points_price || 0} />
          </button>
        ) : (
          <button className="pointer-events-none flex w-full items-center justify-center gap-3 overflow-hidden rounded-[6px] bg-[#3F3F46] px-4 py-3 text-center text-t6 text-white">
            <ShoppingBag03 className="shrink-0" size={20} />
            <span className="truncate">{formatMoney(item.price)}</span>
          </button>
        )}
      </div>
    </div>
  )
})
