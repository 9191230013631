import '../ToolTip/ToolTip.css'
import { HTMLAttributes, useContext, useEffect, useMemo, useState } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { formatMoney } from '@/util'
import getCampaignName from '../../../campaigns/pages/CampaignTable/getCampaignName'
import SingleValue, { SingleValueType } from '../SingleValue/SingleValue'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { CampaignEventType, DataGranularity, SeriesValue } from '@/api/dashboard'
import ZeroState from '../ZeroState/ZeroState'
import { Legend } from '../Legend'
import { getSerName } from '../../util'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'
import { Select } from '@dashboard/ui'

const prepareData = (triggerName: string, data: SeriesValue[], categories: string[]) => {
  let cleanData = data?.filter(it => it.series_name === triggerName) || []
  let result: SeriesValue[] = []

  for (let cat of categories) {
    let item = cleanData.find(it => it.category === cat)
    if (item) {
      result.push(item)
    } else {
      result.push({ category: cat, value: 0 })
    }
  }

  return result
}

interface BestTriggersProps extends HTMLAttributes<HTMLDivElement> {}

const BestTriggers = (props: BestTriggersProps) => {
  const theme = useTheme()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { t } = useTranslation()
  const loading = !context.data.triggers_by_date
  const bestData = context.data?.triggers_by_date

  const [selectedTriggerName, setSelectedTriggerName] = useState<string>('')

  let total = bestData?.total.find(it => it.category === selectedTriggerName)?.value || 0
  let prevTotal = bestData?.prev_total.find(it => it.category === selectedTriggerName)?.value || 0

  let set = bestData ? new Set<string>(bestData.data.map(it => it.series_name || '')) : new Set<string>()
  let series = [...set].filter(it => it && it != CampaignEventType.GameHubFirstLogin)

  useEffect(() => {
    const d = context.data?.triggers_by_date
    if (!d) {
      setSelectedTriggerName('')
    } else if (!selectedTriggerName) {
      const defValue = series[0]
      setSelectedTriggerName(defValue as string)
    }
  }, [context.data?.triggers_by_date])

  let allCategories = useMemo(() => {
    let arr: string[] = []

    for (let i = 0; i < bestData?.data.length; i++) {
      if (!arr.includes(bestData.data[i].category)) {
        arr.push(bestData.data[i].category)
      }
    }

    return arr
  }, [bestData])

  let catFormat = context.granularity == DataGranularity.Hour ? 'YYYY-MM-DD hh:00' : 'YYYY-MM-DD'

  let filterData = useMemo(() => {
    return prepareData(selectedTriggerName, bestData?.data, allCategories)
  }, [allCategories, bestData, selectedTriggerName])

  let filterPrevData = useMemo(() => {
    let prevCategories = allCategories.map(it =>
      dayjs(it)
        .add(-allCategories.length, context.granularity == DataGranularity.Hour ? 'hour' : 'day')
        .format(catFormat),
    )
    return prepareData(selectedTriggerName, bestData?.prev_data, prevCategories)
  }, [allCategories, bestData, selectedTriggerName])

  const serName = getSerName(context)

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        if (!filterData) {
          return ''
        }

        let item = params[0]
        let value = formatMoney(item ? item.value : 0)
        let prevItem = params[1]
        let prevValue = formatMoney(prevItem ? prevItem.value : 0)

        let prevPoint = filterPrevData[params[0].dataIndex]
        let prevDay = t('intl.DateTime', getDateCategoryParams(prevPoint.category, context.granularity))

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--subtitle">
                      <span>${item.axisValue}:</span>
                      <span>${value}</span>
                  </div>
                  <div class="chart-tooltip--subtitle">
                    <span>${prevDay}:</span>
                    <span>${prevValue}</span>
                  </div>
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '0%',
      bottom: '10%',
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: allCategories.map(it => t('intl.DateTime', getDateCategoryParams(it, context.granularity))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: 's1',
        type: 'line',
        showSymbol: false,
        color: '#0EA5E9',
        lineStyle: {
          color: '#0EA5E9',
          width: 3,
        },
        smooth: 0.05,
        data: filterData?.map(it => it.value),
      },
      {
        name: 's2',
        type: 'line',
        showSymbol: false,
        color: 'rgba(14, 165, 233, 0.2)',
        lineStyle: {
          color: '#0EA5E9',
          type: 'dashed',
          opacity: 0.3,
        },
        data: filterPrevData?.map(it => it.value),
      },
    ],
  }

  return (
    <DashboardCard
      title={i18next.t('dashboard.best-triggers')}
      tooltip={i18next.t('dashboard.best-triggers.tooltip')}
      style={props.style}
      data-testid="dashboard/chart/best-triggers"
    >
      {loading ? (
        <LoaderContainer style={{ height: '382px' }}>
          <Loader />
        </LoaderContainer>
      ) : series.length > 0 ? (
        <>
          <div>
            <Select
              items={series
                .sort((a, b) => {
                  let av = bestData?.total.find(it => it.category === a)?.value || 0
                  let bv = bestData?.total.find(it => it.category === b)?.value || 0
                  return bv - av
                })
                .map(it => ({
                  children: getCampaignName(it),
                  value: it,
                }))}
              value={selectedTriggerName}
              onChange={v => setSelectedTriggerName(v as string)}
            />
          </div>
          <SingleValue
            value={{
              value: total,
              prev_value: prevTotal,
            }}
            label={''}
            type={SingleValueType.money}
          />
          <div>
            <ReactECharts
              key={selectedTriggerName}
              option={options}
              style={{
                height: '225px',
                width: '100%',
              }}
            />
            <Legend
              className="mt-2"
              items={[
                {
                  name: serName,
                  classColor: 'bg-fg-brand-primary',
                  type: 'line',
                },
              ]}
            />
          </div>
        </>
      ) : (
        <ZeroState />
      )}
    </DashboardCard>
  )
}

export default BestTriggers
