import { useContext, useEffect } from 'react'
import Divider from '../../../components/Divider'
import CampaignIntegrationBlock from './CampaignIntegrationBlock'
import { Campaign, CampaignEventType, CustomAttribute } from '@/api/dashboard'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import { isCustomEvent, isManualLaunchCampaign } from '../Settings'
import { FieldSection } from 'src/components/ui/FieldSection'
import CustomAttributeTable from './CustomAttributeTable'
import { ButtonIcon, FieldGroup, ModalTitle } from '@/ui'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { CampaignContext, ICampaignContext } from '../Context'
import { ItemTimelimit, validateInterval } from '@/layouts/components/ItemTimelimit'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, cn, formatHookFormErrors } from '@dashboard/ui'
import { ChevronLeft, CloseOutline } from '@/icons'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { useTranslation } from 'react-i18next'

export default function CampaignSettings(props: {
  onClose: () => void
  backClick?: () => void
  campaign: Campaign
  setCampaign: (c: Campaign) => void
}) {
  const context = useContext(CampaignContext) as ICampaignContext
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm<Campaign>({
    values: {
      ...props.campaign,
    },
  })

  useEffect(() => {
    reset(props.campaign)
  }, [props.campaign])

  const start_at = watch('start_at')
  const end_at = watch('end_at')

  const onSaveClick = (data: Campaign) => {
    data.time_limit = !!(data.start_at || data.end_at)

    const err = validateInterval(data.start_at, data.end_at, t)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    props.setCampaign(data)
    if (props.campaign.id) {
      props.onClose()
    }
  }

  const setAttributes = (v: CustomAttribute[]) => {
    setValue('attributes.custom_attributes', v, { shouldDirty: true })
  }

  const renderAttributes = () => {
    switch (props.campaign.type) {
      case CampaignEventType.GameHubVisit:
      case CampaignEventType.GameHubVisitStore:
      case CampaignEventType.AbandonedCart:
        break
      default:
        return <></>
    }

    return (
      <>
        <Divider />

        <FieldSection label={t('campaign.header.settings')}>
          {(props.campaign.type == CampaignEventType.GameHubVisitStore ||
            props.campaign.type == CampaignEventType.GameHubVisit) && (
            <Controller
              control={control}
              name="attributes.visit_duration"
              render={({ field }) => (
                <DurationSelectorDHMS
                  size={props.campaign.id ? 'small' : 'middle'}
                  duration={field.value == undefined ? 3600 : field.value}
                  label={t('campaign.editor.visit_duration')}
                  caption={t('campaign.editor.visit_duration.caption')}
                  onChange={v => {
                    field.onChange(v)
                  }}
                />
              )}
            />
          )}

          {props.campaign.type == CampaignEventType.AbandonedCart && (
            <Controller
              control={control}
              name="attributes.abandoned_cart_duration"
              render={({ field }) => (
                <DurationSelectorDHMS
                  size={props.campaign.id ? 'small' : 'middle'}
                  duration={field.value == undefined ? 3600 : field.value}
                  label={t('campaign.editor.abandoned_cart_duration')}
                  caption={t('campaign.editor.abandoned_cart_duration.caption')}
                  onChange={v => {
                    field.onChange(v)
                  }}
                />
              )}
            />
          )}
        </FieldSection>
      </>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <ItemTimelimit
        setValue={(prop, value) => {
          clearErrors('start_at')
          setValue(prop as 'start_at' | 'end_at', value, { shouldDirty: true })
        }}
        start_at={start_at}
        end_at={end_at}
        error={errors.start_at?.message}
      />
    )
  }

  const renderCustomAttributes = () => {
    return (
      isCustomEvent(props.campaign.type) && (
        <>
          <Divider />

          <FieldSection label={t('campaign.custom-event.custom-action')}>
            <FieldGroup label={t('campaign.custom-event.name')}>
              <Input {...register('type')} {...formatHookFormErrors(errors, 'type')} />
            </FieldGroup>
            <FieldGroup label={t('campaign.custom-event.desc')}>
              <Input {...register('name')} {...formatHookFormErrors(errors, 'name')} />
            </FieldGroup>
          </FieldSection>

          <Divider />

          <FieldSection label={t('campaign.custom-event.attributes')}>
            <CustomAttributeTable
              attributes={watch('attributes.custom_attributes') || []}
              setAttributes={setAttributes}
            />
          </FieldSection>
        </>
      )
    )
  }

  const renderCreateHeader = () => {
    return (
      <div className="px-12">
        <ModalTitle>
          <div className="flex items-center">
            <ButtonIcon variant="secondary-gray" size="xs" onClick={props.backClick} className="mr-6">
              <ChevronLeft size={16} />
            </ButtonIcon>
            {t('campaign.step3')}
            <div className="ml-auto flex items-center gap-3">
              <Button
                onClick={handleSubmit(onSaveClick)}
                size="sm"
                color="primary"
                data-testid={'new-campaign/create-button'}
              >
                {t('campaign.create')}
              </Button>
              <ButtonIcon variant="secondary-gray" size="sm" onClick={props.onClose}>
                <CloseOutline size={16} />
              </ButtonIcon>
            </div>
          </div>
        </ModalTitle>
      </div>
    )
  }

  return (
    <div
      style={{ pointerEvents: context?.readOnly ? 'none' : 'all', opacity: context?.readOnly ? 0.6 : 1 }}
      className="h-full"
    >
      {props.campaign.id ? (
        <SettingsHeader
          text={t('campaign.header.settings')}
          onSave={handleSubmit(onSaveClick)}
          onClose={props.onClose}
          className="mb-4"
        />
      ) : (
        renderCreateHeader()
      )}

      <div className={cn(props.campaign.id ? 'px-6' : 'h-full overflow-auto px-12')}>
        {!props.campaign?.archived_at && (
          <>
            <FieldSection label={t('campaign.editor.campaign-published')}>
              <CheckBoxContainer
                checked={watch('enabled')}
                onChange={v => {
                  setValue('enabled', v.target.checked, { shouldDirty: true })
                }}
                label={t('campaign.editor.campaign-published')}
                caption={t('campaign.editor.campaign-published.subtitle')}
              />
            </FieldSection>
            <Divider />
          </>
        )}

        <FieldSection label={t('campaign.description')}>
          <FieldGroup label={t('campaign.description.desc')}>
            <Input {...register('description')} {...formatHookFormErrors(errors, 'description')} />
          </FieldGroup>
        </FieldSection>

        <Divider />

        {renderTimeLimitations()}

        {isManualLaunchCampaign(props.campaign.type) && (
          <>
            <Divider />

            <FieldSection label={t('campaign.custom-event.integration')}>
              <CampaignIntegrationBlock campaign={props.campaign} />
            </FieldSection>
          </>
        )}

        {renderAttributes()}

        {renderCustomAttributes()}

        <div className="h-[100px]" />
      </div>
    </div>
  )
}
