import { ReactNode } from 'react'

import { FieldGroup, TooltipAlertCircle } from '@/ui'
import { Card } from '@dashboard/ui'

interface Title {
  text: string
  tooltip?: string
}

interface Field {
  title: Title
  content: ReactNode
}

interface Setting {
  name: string
  value: ReactNode
}

interface EngagementSummaryCardProps {
  title: Title
  action?: ReactNode
  fields: Field[]
  settings?: Setting[]
}

export const EngagementSummaryCard = ({ title, action, fields, settings }: EngagementSummaryCardProps) => (
  <Card size="lg" color="secondary">
    <div className="inline-flex w-full items-center justify-between">
      <div className="inline-flex items-center gap-1">
        <span className="h-6 text-base font-semibold text-text-secondary">{title.text}</span>
        {title.tooltip && <TooltipAlertCircle message={title.tooltip} placement="top" />}
      </div>
      {action}
    </div>

    <div className="flex flex-col gap-1">
      {fields.map(
        ({ title, content }, index) =>
          content && (
            <FieldGroup
              key={index}
              size="sm"
              label={
                <div className="inline-flex items-center gap-1">
                  <span className="text-caption-sm font-normal text-text-tertiary">{title.text}</span>
                  {title.tooltip && <TooltipAlertCircle size="sm" message={title.tooltip} />}
                </div>
              }
            >
              {content}
            </FieldGroup>
          ),
      )}

      {settings && (
        <div className="rounded-md border border-border-primary">
          {settings.map(({ name, value }, index) => (
            <div
              key={index}
              className={`flex items-center justify-between px-3 py-2 ${
                index !== settings.length - 1 ? 'border-b border-b-border-primary' : ''
              }`}
            >
              <span className="text-caption-sm font-normal text-text-tertiary">{name}</span>
              <span className="flex-1 text-right text-caption-sm font-normal">{value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  </Card>
)
