import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../../../../components/icons/IconBase'

export const DisputeOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <g clipPath="url(#clip0_3250_443)">
        <path
          d="M13.4377 0.867243C12.5497 0.668891 11.6239 0.723024 10.7651 1.02352C9.90621 1.32401 9.1486 1.85887 8.57794 2.56758C8.00729 3.27629 7.64636 4.13056 7.53601 5.03374C7.48577 5.4449 7.77835 5.81893 8.18951 5.86917C8.60067 5.91941 8.9747 5.62683 9.02494 5.21567C9.10134 4.59039 9.35121 3.99897 9.74628 3.50832C10.1413 3.01768 10.6658 2.64739 11.2604 2.43936C11.855 2.23132 12.496 2.19385 13.1107 2.33117C13.7255 2.46849 14.2896 2.77513 14.7392 3.21638C15.1887 3.65764 15.5058 4.21591 15.6546 4.82803C15.8033 5.44015 15.7778 6.08168 15.5809 6.68004C15.384 7.2784 15.0235 7.80971 14.5403 8.21385C14.0571 8.61799 13.4705 8.87884 12.8467 8.96688C12.4366 9.02477 12.151 9.40419 12.2089 9.81434C12.2668 10.2245 12.6462 10.51 13.0563 10.4522C13.9573 10.325 14.8047 9.94821 15.5027 9.36445C16.2006 8.78069 16.7213 8.01324 17.0057 7.14894C17.2901 6.28464 17.327 5.35799 17.1122 4.47382C16.8973 3.58965 16.4393 2.78326 15.7899 2.14589C15.1405 1.50851 14.3258 1.06559 13.4377 0.867243Z"
          fill="#4C1D95"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.1904 7.65904C12.1904 7.7682 12.2659 7.83957 12.3708 7.83957C12.4715 7.83957 12.5513 7.7682 12.5513 7.65904V7.39453C13.2017 7.33995 13.6843 6.96629 13.6843 6.36171C13.6843 5.82011 13.3402 5.50522 12.6478 5.37507L12.5513 5.35408V4.40102C12.7905 4.44721 12.9625 4.58995 13.038 4.81667C13.0968 4.95102 13.1891 5.02659 13.336 5.02659C13.5038 5.02659 13.6171 4.91743 13.6171 4.75789C13.6171 4.72325 13.6101 4.68862 13.603 4.65398C13.6006 4.64243 13.5983 4.63089 13.5962 4.61934C13.4871 4.21209 13.0884 3.9224 12.5513 3.86362V3.60331C12.5513 3.49415 12.4715 3.41858 12.3708 3.41858C12.2659 3.41858 12.1904 3.49415 12.1904 3.60331V3.86362C11.5567 3.92659 11.1077 4.30866 11.1077 4.86705C11.1077 5.38766 11.456 5.68995 12.1149 5.8411L12.1904 5.85789V6.86553C11.8841 6.81934 11.7036 6.6556 11.6407 6.43308C11.5819 6.28614 11.4896 6.21476 11.3469 6.21476C11.1707 6.21476 11.0574 6.32812 11.0574 6.49186C11.0574 6.53447 11.0681 6.57707 11.0796 6.62297L11.0826 6.63461C11.2 7.04606 11.6155 7.34415 12.1904 7.39453V7.65904ZM12.1904 4.40102V5.26591L12.1652 5.25751C11.8589 5.16934 11.7162 5.03499 11.7162 4.82927C11.7162 4.61514 11.8966 4.44721 12.1904 4.40102ZM12.5513 6.86553V5.94606L12.6226 5.96285C12.9541 6.05522 13.0758 6.18957 13.0758 6.40789C13.0758 6.6514 12.8996 6.82354 12.5513 6.86553Z"
          fill="#4C1D95"
        />
        <path
          d="M5.99918 9.75C6.67951 9.75 7.23167 10.2764 7.23167 10.9249C7.23167 11.5735 6.67951 12.0999 5.99918 12.0999C5.31885 12.0999 4.76669 11.5735 4.76669 10.9249C4.76669 10.2764 5.31885 9.75 5.99918 9.75Z"
          fill="#4C1D95"
        />
        <path
          d="M7.97486 14.1172C7.41285 14.485 6.73251 14.7 6.00041 14.7C5.26831 14.7 4.58675 14.485 4.02473 14.1172C3.777 13.9551 3.67101 13.6449 3.81521 13.3935C4.1147 12.8718 4.72972 12.5463 6.00041 12.5463C7.27111 12.5463 7.88612 12.8718 8.18562 13.3935C8.32859 13.6461 8.22259 13.9551 7.97486 14.1172Z"
          fill="#4C1D95"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.25 12C11.25 14.8995 8.8995 17.25 6 17.25C3.10051 17.25 0.75 14.8995 0.75 12C0.75 9.1005 3.10051 6.75 6 6.75C8.8995 6.75 11.25 9.1005 11.25 12ZM9.75 12C9.75 14.0711 8.07107 15.75 6 15.75C3.92893 15.75 2.25 14.0711 2.25 12C2.25 9.92893 3.92893 8.25 6 8.25C8.07107 8.25 9.75 9.92893 9.75 12Z"
          fill="#4C1D95"
        />
        <path
          d="M4.2082 5.52951C4.10604 5.63504 4.02127 5.68301 3.91695 5.68301C3.77132 5.68301 3.66482 5.56309 3.66482 5.40478V4.55331H3.60831C2.82584 4.55331 2.3368 4.78117 1.98687 5.50073C1.91297 5.65183 1.81733 5.67822 1.72387 5.67822C1.60433 5.67822 1.5 5.55829 1.5 5.33523C1.5 3.66826 2.14771 2.6345 3.60831 2.6345H3.66482V1.79022C3.66482 1.63192 3.77132 1.5 3.92129 1.5C4.02562 1.5 4.09517 1.55037 4.2082 1.6655L5.83181 3.33966C5.91223 3.42361 5.93831 3.51236 5.93831 3.59151C5.93831 3.66826 5.91223 3.7594 5.83181 3.84095L4.2082 5.52951Z"
          fill="#4C1D95"
        />
        <path
          d="M13.7918 12.4706C13.894 12.3651 13.9787 12.3171 14.0831 12.3171C14.2287 12.3171 14.3352 12.4371 14.3352 12.5954V13.4468H14.3917C15.1742 13.4468 15.6632 13.219 16.0132 12.4994C16.0871 12.3483 16.1827 12.3219 16.2761 12.3219C16.3957 12.3219 16.5 12.4419 16.5 12.6649C16.5 14.3319 15.8523 15.3657 14.3917 15.3657H14.3352V16.2099C14.3352 16.3682 14.2287 16.5002 14.0787 16.5002C13.9744 16.5002 13.9048 16.4498 13.7918 16.3347L12.1682 14.6605C12.0878 14.5765 12.0617 14.4878 12.0617 14.4086C12.0617 14.3319 12.0878 14.2407 12.1682 14.1592L13.7918 12.4706Z"
          fill="#4C1D95"
        />
      </g>
      <defs>
        <clipPath id="clip0_3250_443">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
