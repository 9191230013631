import { ChangeEvent, MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { InputMask } from '@react-input/mask';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CalendarOutline } from '../../icons';
import { useUiContext } from '../../providers';
import { Input, InputButtonClear, InputProps } from '../Input';
import { Popper } from '../Popper';
import { InputDateMenu } from './InputDateMenu';
import { getInputMaskValue } from './getInputMaskValue';

dayjs.extend(customParseFormat);

interface InputDateProps extends Omit<InputProps, 'defaultValue' | 'value' | 'extraLeft' | 'extraRight' | 'onChange'> {
  /**
   * Hide clear button on input.
   * @deprecated remove after create InputDate (withTime: boolean)
   */
  hideClearButton?: boolean;
  /**
   * Value of the date input.
   */
  value: Date | null;
  /**
   * Change handler for the date input.
   */
  onChange: (value: Date | null) => void;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  ({ hideClearButton = false, value, onChange, ...rest }, ref) => {
    const { locale } = useUiContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const inputFormat = useMemo(() => getInputMaskValue(locale), [locale]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.value = value
          ? value.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })
          : '';
      }
    }, [locale, value]);

    const onChangeCalendar = useCallback(
      (value: Date | null) => {
        onChange(value);
      },
      [onChange],
    );

    const onChangeInput = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
          onChange(null);
          return;
        }

        if (dayjs(e.target.value, inputFormat.toUpperCase(), true).isValid()) {
          const date = dayjs(e.target.value, inputFormat.toUpperCase()).toDate();
          onChange(date);
          return;
        }
      },
      [onChange, inputFormat],
    );

    const onClickClear = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onChange(null);
      },
      [onChange],
    );

    return (
      <Popper
        popper={props => <InputDateMenu {...props} value={value} onChange={onChangeCalendar} />}
        params={{ toggle: false, width: 'auto' }}
        focusManager={{ disabled: true }}
      >
        <InputMask
          {...rest}
          ref={ref}
          refDirect={inputRef}
          component={Input}
          mask={inputFormat}
          placeholder={rest.placeholder || inputFormat}
          replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
          showMask={false}
          separate={true}
          onChange={onChangeInput}
          extraLeft={{ icon: CalendarOutline }}
          extraRight={
            !hideClearButton && value
              ? { icon: () => <InputButtonClear onClick={onClickClear} />, pointer: true }
              : undefined
          }
        />
      </Popper>
    );
  },
);
