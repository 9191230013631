import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { l10nGlossariesInfiniteQuery } from './l10nGlossariesInfiniteQuery'

/**
 *
 * @returns
 */
export const l10nGlossaryDeleteMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId, localeId } = useGenerateCompanyPath<{ localeId: string }>()

  return useMutation({
    mutationFn: async ({ itemId }: { itemId: string }) => {
      const r = await dashboardClient.v1.deleteTranslationGlossary(companyId, gameId, itemId)
      return r.data
    },
    onSuccess: () => {
      return ql.refetchQueries({
        queryKey: l10nGlossariesInfiniteQuery(companyId, gameId, { locale_id: localeId }).queryKey,
        exact: false,
      })
    },
  })
}
