import { Skeleton } from '@dashboard/ui'

interface TranslationRowHeaderProps {
  locale: string
  targetLocale?: string
}

export const TranslationRowHeader = ({ locale, targetLocale }: TranslationRowHeaderProps) => {
  return (
    <div className="grid h-11 w-full shrink-0 grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary bg-fg-primary px-4 text-left text-caption-sm font-semibold text-text-secondary">
      <div>{locale}</div>
      <div>{targetLocale || <Skeleton className="h-4 w-1/4" />}</div>
    </div>
  )
}
