import { Fragment, cloneElement, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useBodyScrollBlockEffect } from '../../hooks';
import { MOTION_ANIMATION_SPEED_MD, cn, getMotionProps } from '../../libs';
import { getModalMotionProps } from './getModalMotionProps';
import { useModalStore } from './useModalStore';

const overlayMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
});

export const ModalProvider = () => {
  const location = useLocation();
  const { modals, reset } = useModalStore();

  const [lastRect, setLastRect] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const handleModalResize = useCallback(() => {
    requestAnimationFrame(() => {
      const last = document.getElementById(modals.at(-1)?.id || '');
      setLastRect(last ? { width: last.clientWidth, height: last.clientHeight } : { width: 0, height: 0 });
    });
  }, [modals]);

  useBodyScrollBlockEffect(!modals.length);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleModalResize);
    return () => {
      window.removeEventListener('resize', handleModalResize);
    };
  }, [handleModalResize]);

  useEffect(() => {
    handleModalResize();
  }, [modals, handleModalResize]);

  useEffect(() => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        reset();
      });
    }, MOTION_ANIMATION_SPEED_MD);
  }, [location, reset]);

  return createPortal(
    <>
      <AnimatePresence>
        {!!modals.length && (
          <motion.div
            {...overlayMotionProps}
            className="fixed left-0 top-0 z-modal-overlay size-full min-h-[50vh]"
            style={{ backgroundColor: 'rgba(100, 116, 139, 0.3)' }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="popLayout">
        {modals.map((modal, index) => {
          const deep = modals.length - index - 1;
          return (
            <Fragment key={`modal-${modal.id}`}>
              <motion.div
                {...getModalMotionProps(index, deep, modals.length)}
                className="fixed top-0 flex size-full items-center justify-center p-4"
              >
                <motion.div
                  className="pointer-events-none absolute m-auto flex w-full items-end overflow-hidden rounded-2xl1 bg-fg-primary"
                  transition={{ duration: MOTION_ANIMATION_SPEED_MD }}
                  initial={lastRect}
                  animate={{
                    ...lastRect,
                    width: lastRect.width - 24 * deep,
                    opacity: deep ? 1 : 0,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                />

                {cloneElement(modal.element, {
                  className: cn(
                    modal.element.props.className,
                    !!deep && 'pointer-events-none absolute scale-[0.9] opacity-0',
                  ),
                  onResize: deep ? handleModalResize : () => void 0,
                  deep,
                })}
              </motion.div>
            </Fragment>
          );
        })}
      </AnimatePresence>
    </>,
    document.body,
  );
};
