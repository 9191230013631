import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { URL_NEW } from '@/types'

/**
 *
 * @param companyId
 * @param gameId
 * @param id
 */
export const useCouponQuery = (companyId: string, gameId: string, id: string) => {
  return useQuery({
    enabled: id != URL_NEW,
    queryKey: ['coupon', gameId, id],
    queryFn: () => dashboardClient.v1.getCoupon(companyId, gameId, id),
    select: ({ data }) => data,
  })
}
