import { useFormContext } from 'react-hook-form'
import { Input, formatHookFormErrors } from '@dashboard/ui'
import { TranslationGlossaryCreate } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { useTranslation } from 'react-i18next'

export interface GlossaryItemFormPartData extends TranslationGlossaryCreate {}

export const L10nGlossaryItemFormPart = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext<GlossaryItemFormPartData>()

  return (
    <>
      <FieldGroup label={t('l10n.glossary.form.term.label')} size="sm">
        <Input
          {...register('term', { required: t('validation.required') })}
          {...formatHookFormErrors(errors, 'term')}
          placeholder={t('l10n.glossary.form.term.placeholder')}
          autoFocus={true}
        />
      </FieldGroup>
      <FieldGroup label={t('l10n.glossary.form.translation.label')} size="sm">
        <Input
          {...register('translation', { required: t('validation.required') })}
          {...formatHookFormErrors(errors, 'translation')}
          placeholder={t('l10n.glossary.form.translation.placeholder')}
        />
      </FieldGroup>
    </>
  )
}
