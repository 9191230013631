import { Campaign, CampaignEventType, ConditionNode, Expression, GameSettingsRead, Token } from '@/api/dashboard'
import ConditionEditor from '@/layouts/campaigns/expr/ConditionEditor'
import { CampaignContext, EditorMode, ICampaignContext } from '@/layouts/campaigns/Context'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'

export const getCampaignContext = (gameSettings: GameSettingsRead | undefined | null) => {
  return {
    campaign: {
      type: CampaignEventType.CustomEvent,
    } as unknown as Campaign,
    playerCustomAttributes: gameSettings?.player_attributes || [],
    readOnly: false,
    historyMode: false,
    mode: EditorMode.StoreItem,
  } as unknown as ICampaignContext
}

export const ExpressionEditor = (props: {
  errorTokens: Token[]
  value: Expression | undefined | null
  onChange: (value: Expression) => void
  id?: string
  className?: string
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  return (
    <CampaignContext.Provider value={getCampaignContext(gameSettings)}>
      <ConditionEditor
        className={props.className}
        errTokens={props.errorTokens}
        node={
          {
            id: props.id || '',
            expr: props.value || {
              tokens: [],
            },
          } as ConditionNode
        }
        setNode={n => {
          props.onChange(n.expr)
        }}
      />
    </CampaignContext.Provider>
  )
}
