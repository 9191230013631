import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Button, Checkbox, FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import {
  Currency,
  Expression,
  ItemType,
  MaxPurchaseLimitPeriodType,
  PeriodType,
  RotationType,
  StoreCardType,
  StoreItemRead,
  StoreRead,
  StoreType,
} from '@/api/dashboard'
import { FieldSection } from '@/components/ui/FieldSection'
import { DrawerContent, DrawerTopbar, FieldGroup, InputExtraMark, PropertyCheckboxControl } from '@/ui'
import { useParams } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import Divider from '../../../components/Divider'
import { useGameItem } from '@/api/useGameItems'
import { validateConditionTokens } from '../../campaigns/validation'
import { getTokenGroups } from '../../campaigns/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { ItemTimelimit, validateInterval } from '@/layouts/components/ItemTimelimit'
import { clearStoreItemEmptyFields } from '@/layouts/store/util'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { InfoCircle } from '@/icons'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { PriceView } from '@/layouts/game-items/components/PriceView'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { BonusItemBenefitEditor } from '@/layouts/components/BonusItemBenefitEditor'

export default function StoreItemSettings(props: {
  item: StoreItemRead
  hideIds?: string[]
  onClose: (item: StoreItemRead | null) => void
  store: StoreRead
}) {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const methods = useForm<StoreItemRead>({
    values: {
      ...props.item,
    },
  })

  const {
    setValue,
    watch,
    control,
    register,
    reset,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isDirty, errors },
  } = methods

  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  useEffect(() => {
    reset(props.item)
  }, [props.item])

  const [max_purchases, max_purchases_period_type, is_free_item, end_at, start_at, item_id, card_type] = watch([
    'max_purchases',
    'max_purchases_period_type',
    'is_free_item',
    'end_at',
    'start_at',
    'item_id',
    'card_type',
  ])

  const { data: item = null } = useGameItem(companyId, gameId, item_id)

  const onSave = async (data: StoreItemRead) => {
    console.log(data)
    const err = validateInterval(data.start_at, data.end_at, t)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    if (data.requirements_expression?.tokens?.length) {
      let { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(gameSettings),
      )
      if (error) {
        setError('requirements_expression', { message: error })
        return
      }

      if (errTokens?.length) {
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
    }

    if (data.is_free_item) {
      data.discount_percent = null as unknown as number
    }

    if (!data.max_purchases) {
      data.max_purchases = null as unknown as number
      data.max_purchases_period_type = null as unknown as PeriodType
      data.max_purchases_period_value = null as unknown as number
      data.show_disabled_by_max_purchases = null as unknown as boolean
      data.max_purchases_limit_type = null as unknown as MaxPurchaseLimitPeriodType
    } else if (!data.max_purchases_period_type) {
      data.show_disabled_by_max_purchases = null as unknown as boolean
    }

    clearStoreItemEmptyFields(data, !!item?.is_stackable || item?.type === ItemType.Bundle)

    if (item) {
      data.item = item
      props.onClose(data)
    }
  }

  const renderBenefits = () => {
    if (!item) {
      return null
    }

    return (
      <>
        <FieldSection label={t('store.item.benefits')}>
          {props.store.type != StoreType.Webhook && (
            <>
              <div className="mb-3 flex gap-3">
                <PropertyCheckboxControl
                  size="md"
                  className="w-1/2"
                  value={!is_free_item}
                  type="radio"
                  label={t('store.item.paid_item')}
                  desc={t('store.item.paid_item.desc')}
                  onChange={() => {
                    setValue('is_free_item', false, { shouldDirty: true })
                  }}
                />

                <PropertyCheckboxControl
                  size="md"
                  type="radio"
                  className="w-1/2"
                  value={!!is_free_item}
                  label={t('store.item.free_item')}
                  desc={t('store.item.free_item.desc')}
                  onChange={() => {
                    setValue('is_free_item', true, { shouldDirty: true })
                    if (!max_purchases) {
                      setValue('max_purchases', 1)
                    }
                  }}
                />
              </div>
            </>
          )}

          {!is_free_item && (
            <FieldGroup label={t('store.item.discount')}>
              <Input
                extraLeft={{
                  icon: <InputExtraMark>%</InputExtraMark>,
                }}
                type={'number'}
                min={1}
                {...register('discount_percent', {
                  validate: value => {
                    if (value) {
                      if (value < 0 || value > 99) {
                        return t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error')
                      }
                      return true
                    }
                  },
                })}
                {...formatHookFormErrors(errors, 'discount_percent')}
              />
            </FieldGroup>
          )}

          {(item.type === ItemType.Bundle || item?.is_stackable) && (
            <FieldGroup label={t('store.item.bonus')}>
              <Input
                extraLeft={{
                  icon: <InputExtraMark>%</InputExtraMark>,
                }}
                type={'number'}
                min={1}
                {...register('bonus_percent', {
                  required: false,
                  min: 1,
                  max: 1000,
                })}
                {...formatHookFormErrors(errors, 'bonus_percent')}
              />
            </FieldGroup>
          )}

          {gameSettings?.enable_reward_points && !is_free_item && item.currency != Currency.RP && (
            <FieldGroup label={t('campaign.virtual-sku-editor.reward_point_percent')}>
              <Input
                type="number"
                min={1}
                {...register('reward_points_percent', {
                  min: { value: 1, message: t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                  max: {
                    value: 100,
                    message: t('campaign.virtual-sku-editor.reward_point_percent.validation'),
                  },
                })}
                extraLeft={{
                  icon: <InputExtraMark>%</InputExtraMark>,
                }}
                {...formatHookFormErrors(errors, 'reward_points_percent')}
              />
            </FieldGroup>
          )}
        </FieldSection>
      </>
    )
  }

  const renderMaxPurchases = () => {
    return (
      <FieldSection label={t(is_free_item ? 'store.item.max_claim_limitation' : 'store.item.max_purchase_limitation')}>
        <FieldGroup label={t(is_free_item ? 'store.item.max_claim' : 'store.item.max_purchase')}>
          <Input
            type={'number'}
            min={1}
            {...register('max_purchases', {
              min: { value: 1, message: t('coupon.max_redemptions.validation') },
              max: { value: 100_000_000, message: t('coupon.max_redemptions.validation') },
            })}
            {...formatHookFormErrors(errors, 'max_purchases')}
          />
        </FieldGroup>
        <CheckBoxContainer
          variant="background"
          caption={t(
            is_free_item ? 'store.item.max_claim_period_value.desc' : 'store.item.max_purchases_period_value.desc',
          )}
          label={t('store.item.max_purchases_period_value.title')}
          disabled={!max_purchases}
          checked={!!max_purchases_period_type && !!max_purchases}
          onChange={e => {
            // @ts-ignore
            setValue('max_purchases_period_type', e.target.checked ? PeriodType.Day : (null as unknown as undefined), {
              shouldDirty: true,
            })
          }}
        >
          <FieldGroup
            label={t(
              is_free_item ? 'store.item.max_purchases_period_value.free' : 'store.item.max_purchases_period_value.buy',
              { value: max_purchases },
            )}
          >
            <div className="flex gap-[18px]">
              <div className="w-1/2">
                <Input
                  min={1}
                  type="number"
                  {...register('max_purchases_period_value', {
                    required: max_purchases_period_type ? t('validation.required') : false,
                    min: {
                      value: 1,
                      message: t('validation.min-no-field', { value: 1 }),
                    },
                    max: { value: 100_000_000, message: t('coupon.max_redemptions.validation') },
                  })}
                />
              </div>
              <div className="w-1/2">
                <Controller
                  control={control}
                  name="max_purchases_period_type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      items={Object.values(PeriodType)
                        .filter(it => it !== PeriodType.Second)
                        .map(value => ({
                          children: t(`store.rotation.period.type.${value}`),
                          value: value,
                        }))}
                    />
                  )}
                />
              </div>
            </div>
            <ErrorMessage
              name="max_purchases_period_value"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup
            label={t(
              is_free_item
                ? 'store.item.show_disabled_by_max_purchases.free'
                : 'store.item.show_disabled_by_max_purchases',
            )}
            data-testid="sku/store/item/show_disabled_by_max_purchases"
          >
            <Controller
              control={control}
              name="show_disabled_by_max_purchases"
              render={({ field }) => (
                <Select
                  value={field.value ? 'show' : 'hide'}
                  onChange={v => field.onChange(v == 'show')}
                  items={[
                    {
                      children: t('store.item.show_disabled_by_max_purchases.hide'),
                      value: 'hide',
                      caption: t('store.item.show_disabled_by_max_purchases.hide.desc'),
                    },
                    {
                      children: t('store.item.show_disabled_by_max_purchases.show'),
                      value: 'show',
                      caption: t('store.item.show_disabled_by_max_purchases.show.desc'),
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup
            label={t('store.item.max_purchases_limit_type')}
            data-testid="sku/store/item/max_purchases_limit_type"
          >
            <Controller
              control={control}
              name="max_purchases_limit_type"
              render={({ field }) => (
                <Select
                  value={field.value || MaxPurchaseLimitPeriodType.Personal}
                  onChange={v => field.onChange(v)}
                  items={[
                    {
                      children: t('store.item.max_purchases_limit_type.personal'),
                      value: MaxPurchaseLimitPeriodType.Personal,
                    },
                    {
                      children: t('store.item.max_purchases_limit_type.global'),
                      value: MaxPurchaseLimitPeriodType.Global,
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderConditions = () => {
    if (!item) {
      return null
    }

    return (
      <FieldGroup label={t('store.item.requirements_expression')}>
        <Controller
          control={control}
          name="requirements_expression"
          render={({ field }) => (
            <ExpressionEditor id={props.item.item_id} errorTokens={[]} value={field.value} onChange={field.onChange} />
          )}
        />
        <ErrorMessage
          name="requirements_expression"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
    )
  }

  const renderAdvancedSettings = () => {
    if (!item) {
      return null
    }
    return (
      <>
        <FieldSection label={t('store.item.display_settings')}>
          <FieldGroup label={t('store.item.custom_badge_text')}>
            <Input {...register('custom_badge')} maxLength={30} {...formatHookFormErrors(errors, 'custom_badge')} />
          </FieldGroup>

          <FieldGroup label={t('store.item.featured_type')}>
            <Controller
              control={control}
              name="card_type"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || StoreCardType.Default}
                  items={Object.values(StoreCardType).map(value => ({
                    children: t(`store.item.featured_type.${value}`),
                    value: value,
                  }))}
                />
              )}
            />
          </FieldGroup>

          <Checkbox
            {...register('show_first_on_category_list')}
            disabled={!card_type || card_type == StoreCardType.Default}
          >
            {t('store.item.show_first_on_category_list')}
          </Checkbox>
        </FieldSection>
      </>
    )
  }

  const renderWeightSettings = () => {
    if (!item) {
      return null
    }
    return (
      <>
        <FieldSection label={t('store.item.rotation_weight')}>
          <FieldGroup label={t('store.item.weight')}>
            <Input
              {...register('rotation_weight', {
                min: { value: 0, message: t('store.item.weight.validation') },
                max: { value: 1000, message: t('store.item.weight.validation') },
              })}
              min={0}
              type={'number'}
              {...formatHookFormErrors(errors, 'rotation_weight')}
            />
            <div className="mt-1.5 flex items-start gap-2 text-text-secondary">
              <InfoCircle size={14} className="mt-1 min-w-[14px]" />
              <span className="text-sm leading-normal">{t('store.item.rotation_weight.desc')}</span>
            </div>
          </FieldGroup>
        </FieldSection>
      </>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <ItemTimelimit
        setValue={(prop, value) => {
          clearErrors('start_at')
          // @ts-ignore: TS2615
          setValue(prop as 'start_at' | 'end_at', value, { shouldDirty: true })
        }}
        start_at={start_at}
        end_at={end_at}
        error={errors.start_at?.message}
      />
    )
  }

  return (
    <div className="flex h-full flex-col overflow-auto bg-fg-primary">
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{t('store.item.settings')}</h2>
        <div className="flex gap-2.5">
          <Button
            size="sm"
            onClick={() => props.onClose(null)}
            variant="outline"
            color="secondary"
            data-testid="sku/store/item/cancel"
          >
            {t('Cancel')}
          </Button>
          <Button size="sm" disabled={!isDirty} onClick={handleSubmit(onSave)} data-testid="sku/store/item/submit">
            {t('apply')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent>
        <FieldSection label={t('store.item.general')}>
          <div className="flex items-center gap-2 rounded-md border border-border-primary bg-fg-disabled p-1.5">
            <SkuImageName item={props.item.item} />
            <PriceView item={props.item.item} />
          </div>
        </FieldSection>

        {item && (
          <>
            <Divider />
            {renderBenefits()}

            {props.store.type != StoreType.Webhook && (
              <>
                <Divider />
                {renderMaxPurchases()}

                <Divider />
                <FormProvider {...methods}>
                  <BonusItemBenefitEditor />
                </FormProvider>
              </>
            )}
            {props.store.type == StoreType.Rotation &&
              props.store.rotation_settings?.type == RotationType.RandomByWeight && (
                <>
                  <Divider />
                  {renderWeightSettings()}
                </>
              )}
            {props.store.type != StoreType.Webhook && (
              <>
                <Divider />
                {renderTimeLimitations()}
              </>
            )}
            <Divider />
            {renderAdvancedSettings()}

            {props.store.type != StoreType.Webhook && (
              <>
                <Divider />
                {renderConditions()}
              </>
            )}
          </>
        )}
      </DrawerContent>
    </div>
  )
}
