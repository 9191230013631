import { ReactNode } from 'react'

interface L10nLayoutProps {
  children: ReactNode
  sidebar?: ReactNode
}

export const L10nLayout = ({ children, sidebar }: L10nLayoutProps) => (
  <div className="grid h-full grid-cols-[calc(232px+0.75rem*2)_1fr] items-start">
    {sidebar}
    <div className="relative h-full text-clip rounded-lg border border-border-secondary">{children}</div>
  </div>
)
