import { ReactNode, useEffect, useRef } from 'react'
import { cn } from '@dashboard/ui'
import { useBanner } from '@/libs'

interface L10nTableToolbarProps {
  children?: ReactNode
}

export const L10nTableToolbar = ({ children }: L10nTableToolbarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { bannerMode } = useBanner()

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        ref.current?.setAttribute('data-sticky', (e.intersectionRatio < 1).toString())
      },
      { threshold: [1] },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={ref}
      className={cn(
        'sticky left-0 z-10 rounded-t-lg border-b border-border-secondary bg-fg-primary',
        bannerMode ? 'top-[calc(theme(height.banner)-1px)]' : '-top-px',
        'data-[sticky=true]:rounded-none',
        'data-[sticky=true]:shadow-xs',
      )}
    >
      {children}
    </div>
  )
}
