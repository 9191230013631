import { CustomerCompany, Role } from './api/dashboard'

export function canEdit(customer: CustomerCompany | null | undefined) {
  return (
    customer?.role == Role.Editor ||
    customer?.role == Role.Admin ||
    customer?.role == Role.Developer ||
    customer?.role == 'super_admin'
  )
}

export function canManageUsers(customer: CustomerCompany | null | undefined) {
  return customer?.role == Role.Admin || customer?.role == 'super_admin'
}

export function isAdmin(customer: CustomerCompany | null | undefined) {
  return customer?.role == Role.Admin || customer?.role == 'super_admin'
}
