import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { dashboardClient } from '@/api'
import { DailyRewardCreate } from '@/api/dashboard'

export const useDailyRewardsCreate = () => {
  const queryClient = useQueryClient()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  return useMutation({
    mutationFn: (create: DailyRewardCreate) =>
      dashboardClient.v1.createDailyReward(companyId, gameId, { ...create, enabled: false }).then(r => r.data),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        predicate: ({ queryKey }) =>
          queryKey.includes('daily-rewards') && queryKey.includes(companyId) && queryKey.includes(gameId),
      })
    },
  })
}
