import { cn } from '@dashboard/ui'

export default function TextIconButton(props: {
  onClick: () => void
  text: string
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={cn(
        'inline-flex cursor-pointer items-center gap-2 text-[14px] font-semibold text-fg-brand-primary',
        props.className,
      )}
      onClick={props.onClick}
    >
      {props.children}
      {props.text}
    </div>
  )
}
