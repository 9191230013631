import { useTranslation } from 'react-i18next'
import { Page, PageHeader, Sidebar, SidebarLinkSkeleton } from '@/ui'
import { L10nLayout } from '@/layouts/localization'
import { L10nGlossaryLocaleSkeleton } from './L10nGlossaryLocaleSkeleton'

export const L10nGlossarySkeleton = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <PageHeader>{t('l10n.glossary.title')}</PageHeader>
      <L10nLayout
        sidebar={
          <Sidebar>
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
            <SidebarLinkSkeleton />
          </Sidebar>
        }
      >
        <L10nGlossaryLocaleSkeleton />
      </L10nLayout>
    </Page>
  )
}
