import { Button, Modal, ModalBaseProps, ModalContent, ModalFooter, ModalTitle } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

interface InstantPayoutModalProps extends ModalBaseProps {}

export const InstantPayoutModal = ({ ...rest }: InstantPayoutModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal {...rest} size="sm">
      <ModalContent>
        <ModalTitle subtitle={t('payouts.instant.modal.subtitle')}>{t('payouts.instant.modal.title')}</ModalTitle>
      </ModalContent>
      <ModalFooter>
        <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
