import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Plus } from '@dashboard/ui'
import { TableZeroState } from '@/ui'
import { GAME_WEBHOOKS_NEW_PATH, useGenerateCompanyPath } from '@/libs'
import { useCurrentUser } from '@/api/useCurrentUser'

export const WebhookTableZeroState = () => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { isDeveloper } = useCurrentUser()
  return (
    <TableZeroState
      title={t('webhook.zero-state.title')}
      message={t('webhook.zero-state.message')}
      buttons={
        <>
          {isDeveloper && (
            <Link to={generatePath(GAME_WEBHOOKS_NEW_PATH)}>
              <Button size="sm">
                <Plus size={16} />
                <span>{t('webhook.zero-state.add-button')}</span>
              </Button>
            </Link>
          )}
          <a href="https://docs.aghanim.com/webhooks/overview" target="_blank">
            <Button variant="tertiary" color="secondary" size="sm">
              {t('webhook.zero-state.learn-more-button')}
            </Button>
          </a>
        </>
      }
    />
  )
}
