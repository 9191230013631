import { ChangeEvent, forwardRef, useCallback, useMemo, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { AnimatePresence, motion } from 'framer-motion';
import { cn, getMotionProps, isHexColor } from '../../libs';
import { Pipette, Plus } from '../../icons';
import { Button } from '../Button';
import { Input, InputProps } from '../Input';
import { Popper } from '../Popper';
import { useEyeDropper } from './useEyeDropper';
import './style.css';

interface InputColorProps extends Omit<InputProps, 'extraLeft' | 'value' | 'onChange'> {
  value: string | undefined | null;
  onChange: (value: string) => void;
}

export const iconMotionProps = getMotionProps({
  initial: { y: 1, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -1, opacity: 0 },
});

export const buttonMotionProps = getMotionProps({
  initial: { y: -1, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 1, opacity: 0 },
});

/**
 * The InputColor component is a text input that allows the user to select a color.
 *
 * !!! Use in Controlled mode.
 */
export const InputColor = forwardRef<HTMLInputElement, InputColorProps>(({ value, onChange, ...rest }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { open, supported } = useEyeDropper();

  const isValidHexColor = useMemo(() => isHexColor(value || ''), [value]);

  const onChangeColorPicker = useCallback(
    (color: string) => {
      onChange(color);
    },
    [onChange],
  );

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const onClickEey = () => {
    open()
      .then(result => {
        onChange(result.sRGBHex);
      })
      .catch(err => {
        console.error(err);
        onChange('');
      });
  };

  return (
    <Popper
      popper={({ motionProps, ...props }) => (
        <div {...props}>
          <motion.div {...motionProps}>
            <div className={cn('menu-color-picker relative w-72', supported && 'has-eye-dropper')}>
              <div className="absolute bottom-3 left-3 z-10 flex items-center gap-1">
                {supported && (
                  <Button className="px-0 text-text-tertiary" variant="tertiary" color="secondary" onClick={onClickEey}>
                    <Pipette size={20} />
                  </Button>
                )}
                <div className="relative size-6 rounded-full bg-border-secondary">
                  <div
                    className="absolute inset-px rounded-full border-2 border-bg-secondary bg-bg-secondary"
                    style={value ? { backgroundColor: value } : {}}
                  />
                </div>
              </div>
              <HexColorPicker color={value || ''} onChange={onChangeColorPicker} />
            </div>
          </motion.div>
        </div>
      )}
      params={{ toggle: false, width: 'auto' }}
      focusManager={{ disabled: true }}
    >
      <Input
        {...rest}
        ref={ref}
        refDirect={inputRef}
        value={value || ''}
        placeholder={rest.placeholder}
        extraLeft={{
          icon: (
            <AnimatePresence>
              {value && isValidHexColor ? (
                <motion.div
                  {...iconMotionProps}
                  style={{ backgroundColor: value }}
                  className="size-5 rounded-md border border-[#00000033]"
                />
              ) : (
                <motion.button
                  {...buttonMotionProps}
                  className="flex size-5 items-center justify-center rounded-md border border-border-primary bg-fg-secondary"
                >
                  <Plus size={14} />
                </motion.button>
              )}
            </AnimatePresence>
          ),
        }}
        onChange={onChangeInput}
      />
    </Popper>
  );
});
