import { ReactNode, createElement, forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { AghanimIcon, CloseOutline } from '../../icons';
import { cn } from '../../libs';

interface CalloutProps {
  /**
   * Icon to the left of the message or label.
   */
  icon: AghanimIcon;
  /**
   * Message body.
   * You cannot pass a label in the message body, use `label`.
   */
  children?: ReactNode;
  /**
   * Message appearance.
   */
  variant?: 'default' | 'danger' | 'ai';
  /**
   * Message label, shown on the body of the message.
   */
  label?: ReactNode;
  /**
   * Action buttons, shown to the right of the message or label.
   */
  action?: ReactNode;
  /**
   * Callback function, triggers when the close button (X) is pressed.
   * The button will only display if the function is passed.
   */
  onClose?: () => void;
}

const variants = cva('rounded-xl border p-3 shadow-xs', {
  variants: {
    variant: {
      default: [
        'border-border-primary',
        'bg-fg-primary',
        '[--label-color:theme(colors.text-gray-primary)]',
        '[--text-color:theme(colors.text-secondary)]',
      ],
      danger: [
        'border-border-error',
        'bg-fg-primary',
        '[--label-color:theme(colors.text-error-solid)]',
        '[--text-color:theme(colors.text-error-solid)]',
      ],
      ai: [
        'border-border-img',
        'bg-gradient-to-r from-fg-pink-tertiary to-fg-brand-tertiary',
        '[--label-color:theme(colors.text-pink-tertiary)]',
        '[--text-color:theme(colors.text-secondary)]',
      ],
    },
    defaultVariants: {
      variant: 'default',
    },
  },
});

export const Callout = forwardRef<HTMLDivElement, CalloutProps>(
  ({ icon, variant = 'default', children, label, action, onClose }, ref) => (
    <div ref={ref} className={cn(variants({ variant }))}>
      <div className="flex items-start gap-3">
        <div className="flex h-5 shrink-0 items-center text-[--label-color]">{createElement(icon, { size: 16 })}</div>
        <div className="w-full">
          {label && <div className="text-caption-lg text-[--label-color]">{label}</div>}
          {children && <div className={cn('text-paragraph-sm text-[--text-color]', label && 'mt-1.5')}>{children}</div>}
        </div>
        <div className="flex shrink-0 gap-3">
          {action && (
            <>
              <div className="text-caption-md text-text-secondary hover:text-text-primary">{action}</div>
              <div className="h-[18px] w-px bg-border-secondary" />
            </>
          )}
          {onClose && (
            <button
              className="flex h-[18px] shrink-0 items-center text-[--label-color] hover:text-text-primary"
              data-testid="callout/button-close"
              type="button"
              onClick={onClose}
            >
              <CloseOutline size={16} />
            </button>
          )}
        </div>
      </div>
    </div>
  ),
);
