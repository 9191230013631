import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { WSEventType } from '@/types'
import { JobRead, JobStatus } from '@/api/dashboard'
import { JobProgressMessage, useSocketListener } from '@/providers'
import { useJobsQuery } from '@/providers/api/useJobsQuery'
import { updateJob } from '@/providers/api/updateJob'
import { useQueryClient } from '@tanstack/react-query'

export const useJobListener = (function_names: string[], onJobCompleted?: (job: JobRead) => void) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const queryClient = useQueryClient()
  const { data: jobs = [], refetch, isLoading } = useJobsQuery(companyId, gameId)

  const onMessage = useCallback(
    (message: JobProgressMessage) => {
      if (message.type != WSEventType.job_progress) {
        return
      }

      const job = jobs?.find(job => job.id === message.payload.job_id)
      if (job) {
        updateJob(queryClient, gameId, message.payload)
      }
      if (
        message.payload.is_cancelled ||
        message.payload.done === message.payload.total ||
        message.payload.error_text
      ) {
        refetch()
      }

      if (
        message.payload.total &&
        job &&
        onJobCompleted &&
        !message.payload.is_cancelled &&
        message.payload.done === message.payload.total
      ) {
        onJobCompleted(job)
      }
    },
    [function_names, jobs],
  )

  useSocketListener<JobProgressMessage>(onMessage)

  return {
    refetch,
    isLoading,
    progressJobs:
      jobs?.filter(
        job =>
          (job.status == JobStatus.InProgress || job.status == JobStatus.Pending) &&
          function_names.includes(job.function_name),
      ) || [],
  }
}
