import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, useModal } from '@dashboard/ui'
import { JobFunctionName } from '@/api/dashboard'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Page, PageHeader } from '@/ui'
import { AiTranslateOutline } from '@/icons'
import { LOCALIZATION_TRANSLATIONS_LOCALE_PATH, useGenerateCompanyPath } from '@/libs'
import { useJobListener } from '@/providers'
import { localeQueryOptions, localesQueryOptions } from '../api'
import { L10nLayout, L10nTranslationsSkeleton, L10nZeroState } from '../components'
import { useNavigateToFirstLocale } from '../hooks'
import { L10nLocaleModalConfirmTranslateAll, L10nLocaleModalPublish, L10nSidebar } from '../widgets'

export const L10nOutlet = () => {
  const { t } = useTranslation()
  const { companyId, gameId, localeId } = useGenerateCompanyPath<{ localeId: string }>()

  const { canEdit } = useCurrentUser()
  const { data: locales = [], isLoading: isLoadingLocales } = useQuery(localesQueryOptions(companyId, gameId))
  const { data: locale } = useQuery({
    ...localeQueryOptions(companyId, gameId, localeId),
    enabled: !!localeId,
  })

  const { progressJobs } = useJobListener([JobFunctionName.TranslateTranslateAll, JobFunctionName.TranslateSyncAll])

  useNavigateToFirstLocale(LOCALIZATION_TRANSLATIONS_LOCALE_PATH)

  const openModalConfirmTranslateAll = useModal(
    props => locale && <L10nLocaleModalConfirmTranslateAll {...props} locale={locale} />,
  )

  const openModalPublish = useModal(props => locale && <L10nLocaleModalPublish {...props} locale={locale} />)

  const isDisabledLocaleButtons = !locale || !!progressJobs.find(it => it.function_args.locale == locale.locale)

  const isPublishing =
    locale &&
    !!progressJobs.find(
      it => it.function_name == JobFunctionName.TranslateSyncAll && it.function_args.locale == locale.locale,
    )

  if (isLoadingLocales) {
    return <L10nTranslationsSkeleton />
  }

  if (locales.length === 0) {
    return (
      <Page>
        <PageHeader>{t('localization.title')}</PageHeader>
        <L10nZeroState type="translations" />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          canEdit && (
            <>
              <Button size="sm" disabled={isDisabledLocaleButtons} onClick={() => openModalConfirmTranslateAll()}>
                <AiTranslateOutline size={16} />
                <span>{t('localization.locale.translate-all')}</span>
              </Button>
              <Button
                variant="outline"
                size="sm"
                loading={isPublishing}
                disabled={isDisabledLocaleButtons}
                onClick={() => openModalPublish()}
              >
                {t('localization.locale.publish')}
              </Button>
            </>
          )
        }
      >
        {t('localization.title')}
      </PageHeader>

      <L10nLayout sidebar={<L10nSidebar type="translations" />}>
        <Outlet />
      </L10nLayout>
    </Page>
  )
}
